import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CourseComponent } from './components/course/course.component';
import { CourseDetailsComponent } from './components/course/details/details.component';
import { StudentComponent } from './components/student/student.component';
import { ExamComponent } from './components/exam/exam.component';
import { StationaryComponent } from './components/stationary/stationary.component';
import { PlacementComponent } from './components/placement/placement.component';
import { TakeExamComponent } from './components/exam/take-exam/take-exam.component';
import { ResultComponent } from './components/result/result.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { ExamResultComponent } from './components/result/exam-result/exam-result.component';
import { TaskComponent } from './components/task/task.component';
import { ThreadComponent } from './components/thread/thread.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentComponent } from './components/course/payment/payment.component';
import { MasterComponent } from './components/shared/master/master.component';
import { LoginComponent } from './components/account/login/login.component';
import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { AuthGuard } from './auth.guard';
import { AccessdeniedComponent } from './components/shared/accessdenied/accessdenied.component';
import { PaymenthistoryComponent } from './components/paymenthistory/paymenthistory.component';
import { AskQuestionComponent } from './components/discussion/ask-question/ask-question.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CertificateComponent } from './components/certificate/certificate.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SampleComponent } from './components/sample/sample.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { NgxPaginationModule } from 'ngx-pagination'; //ajmera
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { CookieService } from 'ngx-cookie-service';
import { SafePipe } from './safe.pipe';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatVideoModule } from 'mat-video';
// import{ vimeoPlayer } from '@vimeo/player'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CourseComponent,
    CourseDetailsComponent,
    StudentComponent,
    ExamComponent,
    TakeExamComponent,
    StationaryComponent,
    PlacementComponent,
    ResultComponent,
    DiscussionComponent,
    ExamResultComponent,
    TaskComponent,
    ThreadComponent,
    PaymentComponent,
    MasterComponent,
    LoginComponent,
    AccessdeniedComponent,
    PagenotfoundComponent,
    PaymenthistoryComponent,
    AskQuestionComponent,
    CertificateComponent,
    NotificationComponent,
    SampleComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    // ,PdfViewerModule,
    SweetAlert2Module.forRoot(),
    NgxPaginationModule, //ajmera
    VgCoreModule,
    // ,vimeoPlayer
    // ,BrowserAnimationsModule,
    // MatVideoModule
    // ,BrowserAnimationsModule,
    // MatVideoModule
  ],
  providers: [AuthGuard, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
