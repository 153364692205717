export class Course {
    Attachment?: string;
    AttachmentId?: number;
    CourseDetails?: string;
    CourseImagePath?: string;
    CourseName?: string;
    Fees?: number;
    Id?: number;
    IsActive?: boolean;
    IsApproved?: boolean;
    IsCompleted?: boolean;
    IsFree?: boolean;
    IsProcess?: boolean;
    IsTaken?: boolean;
    Modules?: any;
    PercentageComplete?: number;
    ModuleComplete?: number;
    TotalModule?: number;
    TaskAttachmentFiles?: any;
    listModules?: any;
}
