export class Student {

    constructor(
      public Id?: number,
      public StudentId?: string,
      public UserId?: number,
        public Firstname?: string,
        public Lastname?: string,
        public Address?: string,
        public Email?: string,
        public MobileNo?: string,
        public Password?: string,
        public AttachmentPath?: string,
        public Attachment?: File
      ) {  }

}
