import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeStamp } from 'console';
import { CookieService } from 'ngx-cookie-service';

declare var $: any 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  forgotPasswordSubmitted = false;
  returnUrl: string;
  error = '';
  forgotPassworderror = '';
  msg = '';
  response: ApiResponse = null;
  user = new User();
  loaderPlay = false;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  fileToUpload: File;


  httpOptions = {
    headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*').set('Content-Type', 'application/json;charset=utf-8').set('Accept', 'application/json')
  };


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, private context: DataService, private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  get fo() { return this.forgotPasswordForm.controls; }

  onForgotPasswordSubmit() {
    this.forgotPasswordSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      this.forgotPassworderror = "Email is Required.";
      return;
    }

    this.msg = null;
    this.forgotPassworderror = null;

    this.context.GET('ForgotPassword?Email=' + this.forgotPasswordForm.get('email').value).subscribe(data => {

      if (data != null) {
        this.response = data;
        if (this.response.status_code == 1) {
          this.msg = "Password send to given mail successfull!...";
        }
        else {
          this.forgotPassworderror = this.response.message;
        }
      }
      else {
        this.error = "Opps!... Something went wrong.";
        this.currentUserSubject.next(null);
        this.loading = false;
      }
    });

  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.loaderPlay = true;
    var cookieValue = this.cookieService.get(this.f.username.value + 'IsAgree');
  
    if (cookieValue == 'true') {
      this.ApiLoginCall();
    }
    else {
      $('#modelTearms').modal('show');
    }



    //   this.context.GET(environment.API_URL + 'Login?Username='+ this.f.username.value+'&Password='+ this.f.password.value).subscribe(data => {
    //     this.response=data;

    //     if(this.response!=null){
    //         if(this.response.status_code == 1){
    //             this.user = this.response.data1;
    //             localStorage.setItem('currentUser', JSON.stringify(data));
    //             this.currentUserSubject.next(this.user);

    //             this.router.navigate([this.returnUrl]);
    //         }
    //         else{
    //                 this.currentUserSubject.next(null);
    //                 this.error = this.response.message;
    //                 this.loading = false;
    //         }
    //     }
    //     else{
    //         this.error = "Opps!... Something went wrong.";
    //         this.currentUserSubject.next(null);
    //         this.loading = false;
    //     }
    //   });


  }

  AgreeTerms() {
    this.loaderPlay = false;
    this.cookieService.set(this.f.username.value + 'IsAgree', 'true');
    this.ApiLoginCall();
  }


  postMethod(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
    };

    this.http.post("http://localhost:43210/api/Flagship/UploadFiles", formData, httpOptions).subscribe((val) => {
    });
    return false;
  }


  ApiLoginCall() {
    this.context.GET('Login?Username=' + this.f.username.value + '&Password=' + this.f.password.value).subscribe(data => {
      this.loaderPlay = false;
      if (data != null) {
        this.response = data;
        if (this.response.status_code == 1) {
          //this.user = JSON.parse(this.response.data1);
          this.user = this.response.data1;
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          localStorage.setItem('currentLoginUser', JSON.stringify(this.response.data2));

          this.currentUserSubject.next(this.user);

          //this.router.navigate(['/'+this.returnUrl]);
          //this.router.navigate([this.returnUrl]);
          //this.router.navigate(['/']);
          window.location.href = this.returnUrl;
        }
        else {
          this.currentUserSubject.next(null);
          this.error = this.response.message;
          // alert(this.error)
          this.loading = false;
          $('#modelTearms').modal('hide');
        }
      }
      else {
        this.error = "Opps!... Something went wrong.";
        this.currentUserSubject.next(null);
        this.loading = false;
        $('#modelTearms').modal('hide');
      }
    });
  }

  OnModalClose(){
    this.loading = false;
    this.loaderPlay = false;
  }

}



// if(this.response!=null){
//     if(this.response.status_code == 1){
//       this.user = this.response.data1;
//       localStorage.setItem('currentUser', JSON.stringify(data));
//       this.currentUserSubject.next(this.user);

//               this.router.navigate([this.returnUrl]);
//             }
//             else{
//               this.currentUserSubject.next(null);
//               this.error = this.response.message;
//               this.loading = false;
//               }
//         }
//     else{
//       this.error = "Opps!... Something went wrong.";
//       this.currentUserSubject.next(null);
//       this.loading = false;
//     }