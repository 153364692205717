export class Exam {
    Id: number;
    StudentUserId: number;
    ExamId: number;
    ModuleId: number;
    CourseId: number;
    Name?: string;
    ModuleName?: string;
    StartDateTime?: string;
    EndDateTime?: string;
    CompleteDateTime?: string;
    TotalMarks?: number;
    TotalQuestions?: number;
    AttamptQuestions?: number;
    ContainMarks?: number;
    Attachments: File | null;
    

    Questions?: any;

    IsCompleted: boolean;
    IsProcess: boolean;
    IsApproved: boolean;
    IsPass: boolean;
}