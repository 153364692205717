<div class="mdk-drawer-layout__content page">
  <div class="row m-0">
    <div class="container-fluid page__container">
      <!-- <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active">Dashboard</li>
    </ol> -->

      <h1 class="h2">My Learning</h1>

      <hr />

      <!-- <div id="myModal" class="modal">
                <div class="modal-content">
                    <span class="close text-right">&times;</span>
                    <h2 class="card-title divThougth">Thought of the day</h2>
                    <hr />
                    <ng-container *ngIf="(thoughOfDay.AttachmentPath != null && thoughOfDay.AttachmentPath != ''); then Available else NotAvailable"></ng-container>
                    <ng-template #Available>
                        <div class="card">
                            <img src="{{thoughOfDay.AttachmentPath}}" alt="Today Thought" style="width:100%;">
                        </div>
                    </ng-template>
                    <ng-template #NotAvailable>
                        <h4 class="card-title">
                            <strong>{{thoughOfDay.Message}}</strong>
                        </h4>
                    </ng-template>
                    <br />
                </div>
            </div> -->

      <!-- <div class="row">
                <div class="col-lg-12" style="padding-left:0px;padding-right: 0px; ">
                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <div class="flex">
                                <h4 class="card-title">Welcome
                                    <strong>Flagship Design Academy</strong>
                                </h4>
                                <p class="card-subtitle"></p>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Flagship Design Academy is one of my dream which now converted in reality. To give education
                                with my 30 plus professional field experience will one of the best platforms for new generation.
                                I am happy to share my knowledge to make student to their career as skilled Interior designer.</p>
                            
                        </div>
                    </div>
                </div>
            </div> -->

      <h1 class="h2">My Courses</h1>

      <div class="row">
        <div class="col-lg-8 align-items-center">
          <div class="clearfix"></div>

          <div class="row">
            <ng-container
              *ngIf="isCourseBuy(courseList); then Taken; else NotTaken"
            ></ng-container>
            <ng-template #NotTaken>
              <div
                class="card border-left-3 border-left-primary card-2by1 col-md-12"
              >
                <div class="card-body">
                  <div class="media flex-wrap align-items-center">
                    <div class="media-body" style="min-width: 180px">
                      You have not purchased any course or approval is pending.
                      Please contact the administrator.
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #Taken>
              <div
                class="col-lg-6 d-flex pb-3"
                *ngFor="let course of courseList; let i = index"
              >
                <div class="card card-block card-course border-1 w-100">
                  <img
                    src="{{ course.CourseImagePath }}"
                    alt="image"
                    class="card-img-top"
                  />

                  <div class="card-header">
                    <h3 class="card-title">
                      <strong>
                        <!-- <a [routerLink]="['/course/details/',course.Id]" class="card-title text-dark" routerLinkActive="active" title="{{course.CourseName}}">
                                                        {{course.CourseName}}
                                                        </a> -->
                        <a
                          href="javascript: void(0);"
                          class="card-title text-dark"
                          routerLinkActive="active"
                          title="{{ course.CourseName }}"
                        >
                          {{ course.CourseName }}
                        </a>
                      </strong>
                    </h3>
                  </div>
                  <div class="card-footer">
                    <div class="d-flex align-items-end my-2">
                      <div class="d-flex flex flex-column mr-3">
                        <div class="d-flex align-items-center mb-2">
                          <i class="material-icons mr-1">assignment</i>
                          <div class="flex">
                            Module: {{ course.TotalModule }}
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <!-- <div *ngIf="(course.IsTaken==true); then Taken else NotTaken"></div>
                                                    <ng-template #Taken>
                                                        <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-primary">Continue &nbsp;
                                                            <i class="material-icons btn__icon--right">play_circle_outline</i></a>
                                                    </ng-template>
                                                    <ng-template #NotTaken>
                                                        <a [routerLink]="['/course/payment/', course.Id]" class="btn btn-sm btn-primary">Buy</a>
                                                    </ng-template> -->

                        <a
                          [routerLink]="['/course/details/', course.Id]"
                          class="btn btn-sm btn-success"
                          *ngIf="course.IsCompleted == true"
                          >Completed &nbsp;
                          <i class="material-icons btn__icon--right"
                            >play_circle_outline</i
                          ></a
                        >
                        <a
                          [routerLink]="['/course/details/', course.Id]"
                          class="btn btn-sm btn-primary"
                          *ngIf="
                            course.IsCompleted == false &&
                            course.IsTaken == true &&
                            course.IsApproved == true
                          "
                          >Continue &nbsp;
                          <i class="material-icons btn__icon--right"
                            >play_circle_outline</i
                          ></a
                        >
                        <a
                          href="javascript: void(0);"
                          class="btn btn-sm btn-warning"
                          *ngIf="
                            course.IsTaken == true && course.IsApproved == false
                          "
                          >Pending for approval &nbsp;
                          <i class="material-icons btn__icon--right"
                            >play_circle_outline</i
                          ></a
                        >
                        <a
                          [routerLink]="['/course/payment/', course.Id]"
                          class="btn btn-sm btn-primary"
                          *ngIf="
                            course.IsTaken == false &&
                            course.IsApproved == false
                          "
                          >Buy</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <div class="media align-items-center">
                <div class="media-body">
                  <h4 class="card-title">Courses</h4>
                  <p class="card-subtitle">Your recent courses</p>
                </div>
                <div class="media-right">
                  <small>Module</small>
                  <!-- <a class="btn btn-sm btn-primary" href="fixed-student-my-courses.html">My courses</a> -->
                </div>
              </div>
            </div>

            <ul class="list-group list-group-fit mb-0" style="z-index: initial">
              <li
                class="list-group-item"
                style="z-index: initial"
                *ngFor="let course of courseList"
              >
                <div class="d-flex align-items-center">
                  <a
                    href="javascript: void(0);"
                    class="avatar avatar-4by3 avatar-sm mr-3"
                  >
                    <img
                      src="{{ course.CourseImagePath }}"
                      alt="course"
                      class="avatar-img rounded"
                    />
                  </a>
                  <div class="flex">
                    <a href="javascript: void(0);" class="text-body"
                      ><strong>{{ course.CourseName }}</strong></a
                    >
                    <div class="d-flex align-items-center">
                      <div class="progress" style="width: 100px">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style=""
                          aria-valuenow="{{ course.PercentageComplete }}"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <small class="text-muted ml-2"
                        >{{ course.PercentageComplete }} %</small
                      >
                    </div>
                  </div>
                  <div class="dropdown ml-3">
                    <span class="text-muted"
                      >{{ course.ModuleComplete }} / {{ course.TotalModule }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .divThougth {
    font-weight: 200;
    font-style: italic;
  }

  .bg-light-warning {
    background: #fff3e1;
  }
</style>

<!-- <ng-container *ngIf="listExam == null; then Error; else NotError"></ng-container>
                                        <ng-template #Error>
                                            
                                        </ng-template>
                                        <ng-template #NotError>
                                        </ng-template> -->
