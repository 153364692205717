import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/models/api-response';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.css']
})
export class DiscussionComponent implements OnInit {

  loaderPlay = false;
  response : ApiResponse = null;
  discussionPublicList = [];
  discussionPrivateList = [];
  discussionGroupList = [];
  
  currentUser = new User();
  
  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;
  
  constructor(private context: DataService, private spinner: NgxSpinnerService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);
    
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    
    this.authService.ScrollOverflow();
    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "/home" } });
    }
    
    this.GetAllDiscussion();
  }

  GetAllDiscussion(){
    this.authService.ScrollOverflow();
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    this.context.GET("GetAllDiscussionDetails?StudentUserId="+this.currentUser.id).subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
        this.discussionPublicList = this.response.data1;
        this.discussionPrivateList = this.response.data2;
        this.discussionGroupList = this.response.data3;

        //this.loaderPlay = false;
      }
    });
    //this.loaderPlay = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  RemoveDiscussion(discussionForumId: number){
    this.authService.ScrollOverflow();
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    this.context.GET("RemoveDiscussion?StudentUserId="+this.currentUser.id + "&DiscussionForumId=" + discussionForumId).subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
        this.GetAllDiscussion();
      }
      //this.loaderPlay = false;
    });
    //this.loaderPlay = false;
  }

  CloseDiscussion(discussionForumId: number){
    this.authService.ScrollOverflow();
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    this.context.GET("CloseDiscussion?StudentUserId="+this.currentUser.id + "&DiscussionForumId=" + discussionForumId).subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
        this.GetAllDiscussion();
      }
      //this.loaderPlay = false;
    });
    //this.loaderPlay = false;
  }

  ShowUserAttchment(discussionuser){
    if(discussionuser != null){
      return discussionuser.AttachmentPath;
    }
    else{
      return this.authService.FillEmptyImage(true);
    }
  } 


  SetUserName(discussionuser){
    if(discussionuser != null){
      return discussionuser.Fullname;
    }
    else{
      return "User";
    }
  }

}
