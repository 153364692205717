<div class="mdk-drawer-layout__content page ">

    <div class="container-fluid page__container">
        
        <div class="media align-items-center mb-headings">
            <div class="media-body">
                <h1 class="h2">Placement</h1>
            </div>
            <div class="media-right d-flex align-items-center">
                
            </div>
        </div>

        <div class="card">
            <!-- <div class="card-header">
                <div class="media align-items-center">
                    <div class="media-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-subtitle">All non-course topics.</p>
                    </div>
                    <div class="media-right">
                        <a href="#" class="btn btn-white btn-sm"><i class="material-icons">add</i></a>
                    </div>
                </div>
            </div> -->



            <ul class="list-group list-group-fit">


                <li class="list-group-item forum-thread">
                    <div class="row" *ngFor="let placement of placementList">
                        <div class="col-12">
                            <h2>Job Name : {{placement.Name}}</h2>
                            <div class="row">
                                    <div class="col-2">
                                            Job Details
                                    </div>
                                    <div class="col-10">
                                              : {{placement.Details}}
                                    </div>
                            </div>
                            <div class="row">
                                    <div class="col-2">
                                            Job Type
                                    </div>
                                    <div class="col-10">
                                             : {{placement.JobType}}
                                    </div>
                            </div>
                            <div class="row">
                                    <div class="col-2">
                                            Location
                                    </div>
                                    <div class="col-10">
                                             : {{placement.Location}}
                                    </div>
                            </div>
                            <!-- <div class="text-black-50">
                                {{placement.Location}}
                            </div> -->
                            <hr>
                        </div>
                        <hr>
                        <!-- <div class="col-6">
                            <p class="text-black-70 m-0"><strong>Prepared by</strong></p>
                            <h2>LearnPlus Inc.</h2>
                            <div class="text-black-50">
                                32 Noah Cliffs Suite 626, Romania <br>
                                Tax ID RO18880609
                            </div>
                        </div> -->
                    </div>
                </li>
            </ul>
        </div>


    </div>

</div>