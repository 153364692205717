import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ApiResponse } from '../models/api-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
import * as $ from "jquery";
import { Modal } from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  response : ApiResponse = null;
  user= new User();
  modal= new Modal();
  API_URL: string = environment.API_URL;
  API_HOST : string = environment.API_Host;
  isThoughtOpen = false;

  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private isLoaderShow = new BehaviorSubject(true);
    currentMessage = this.isLoaderShow.asObservable();

    private modalBS = new BehaviorSubject(this.modal);
    modalViewObj = this.modalBS.asObservable();

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public CurrentUser(): User {
       this.user = JSON.parse(localStorage.getItem('currentUser'));
        return this.user;
    }

    public CurrentLoginUser(){
        return JSON.parse(localStorage.getItem('currentLoginUser'));
        
    }

    IsShowLoader(IsLoaderShow: boolean) {
      this.isLoaderShow.next(IsLoaderShow)
    }
    
    IsShowModal(obj: Modal) {
      this.modalBS.next(obj)
    }
    
    Login(username: string, password: string) {
        return this.http.get<ApiResponse>((this.API_URL + 'Login?Username='+ username+'&Password='+ password))
            .pipe(map(data => {
              this.response=data;
              if(this.response.status_code == 1){
                this.user = JSON.parse(this.response.data1);
                localStorage.setItem('currentUser', JSON.stringify(this.user));
                this.currentUserSubject.next(this.user);
              }
            }));
    }

    Logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    ScrollOverflow(){
        (function ($) {
          // $(document).ready(function(){
            $('#mainBody').css('overflow','scroll');
          // });
        })(jQuery);
      }


      FillEmptyImage(isUser){
        if(isUser){
          return this.API_HOST + "/Uploads/DefaultUser.png";
        }
        else{
          return this.API_HOST + "/Uploads/No-image-found.jpg";
        }
      }

      doesFileExist(urlToFile: string, isUser: boolean) {
        
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', urlToFile, false);
        xhr.send();

        if (xhr.status == 404) {
          if(isUser){
            return this.API_HOST + "/Uploads/DefaultUser.png";
          }
          else{
            return this.API_HOST + "/Uploads/No-image-found.jpg";
          }
        } else {
            return urlToFile;
        }
    }

    setThoughtOpen(){
      this.isThoughtOpen = true;
    }

    getThoughtOpen(){
      return this.isThoughtOpen;
    }

    notificationTune(){
      let audio = new Audio();
     // audio.src = "/src/assets/notification.mp3";
      audio.load();
      audio.play();
    }

}


