<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>

<button id="btnExamNotify" type="button" style="display: none;" (click)="GetExamNotify()">NotifyExam</button>

<div class="mdk-drawer-layout__content page ">

  <div class="container-fluid page__container">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-1">
            <h4 class="card-title">Result</h4>
          </div>
          <div class="col-md-9">
            <h3 class="card-title text-success"
              *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == true)">
              Congratulation you are successfully pass this exam.
            </h3>
            <h3 class="card-title text-danger"
              *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == false)">
              Sorry you could not pass exam , please re-appear in exam and click on Re-Attempt button.
            </h3>
          </div>
          <div class="col-md-2 text-right">
            <a class="btn btn-primary" routerLink="/exam">Back</a>
          </div>
        </div>
      </div>
      <div class="card-body media align-items-center">


        <ng-container *ngIf="examModel.IsApproved == true;then Completed else NotCompleted"></ng-container>
        <ng-template #Completed>

          <div class="card w-100">
            <div class="card-header">
              <div class="media align-items-center row">
                <div class="media-body">
                  <h4 class="card-title">Exam: {{examModel.Name}}</h4>
                  <p class="card-subtitle text-wrap">
                    <span class="text-muted text-ellipsis text-black-75 text-wrap">Module:
                      {{examModel.ModuleName}}</span>
                  </p>
                </div>
                <div class="media-right">
                  <a class="btn btn-sm btn-warning"
                    *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == false)"
                    [routerLink]="['/exam/take-exam/',examModel.ExamId,examModel.ModuleId,examModel.CourseId]">Re-Attempt</a>
                </div>
              </div>
            </div>
            <div class="card-body table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th style="min-width:120px">Date</th>
                    <th>Total Marks</th>
                    <th>Obtained Marks</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr *ngFor="let result of listExamResult;">
                    <td class="name">{{result.CompleteDateTime}}</td>
                    <td>{{result.TotalMarks}}</td>
                    <td>{{result.ContainMarks}}</td>
                    <td>
                      <h4 class="mb-0 text-success"
                        *ngIf="(result.IsCompleted == true && result.IsApproved == true && result.IsPass == true)">Pass
                      </h4>
                      <h4 class="mb-0 text-danger"
                        *ngIf="(result.IsCompleted == true && result.IsApproved == true && result.IsPass == false)">Fail
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <ul class="list-group list-group-fit mb-0">

                              <li class="list-group-item">
                                <div class="media align-items-center">
                                    <div class="media-body">
                                        <p class="text-body"><strong>Date</strong></p>
                                    </div>
                                    <div class="media-right text-center d-flex align-items-center">
                                      <p class="text-body"><strong>Total Marks</strong></p>
                                      <p class="text-body"><strong>Obtained Marks</strong></p>
                                      <p class="text-body"><strong>Result</strong></p>
                                    </div>
                                </div>
                            </li>

                                <li class="list-group-item" *ngFor="let result of listExamResult;">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                          <p class="text-body">{{result.CompleteDateTime}}</p>
                                        </div>
                                        <div class="media-right text-center d-flex align-items-center">
                                          <span class="text-black-50 mr-3">{{result.TotalMarks}}</span>
                                          <span class="text-black-50 mr-3">{{result.ContainMarks}}</span>
                                          <h4 class="mb-0 text-success" *ngIf="(result.IsCompleted == true && result.IsApproved == true && result.IsPass == true)">Pass</h4>
                                          <h4 class="mb-0 text-danger" *ngIf="(result.IsCompleted == true && result.IsApproved == true && result.IsPass == false)">Fail</h4>
                                        </div>
                                    </div>
                                </li>

                            </ul> -->
          </div>
          <!-- <div class="media-body">
                                <h3 class="text-body mb-0">Exam: {{examModel.Name}}</h3>
                                        <span class="text-muted text-ellipsis">Module: {{examModel.ModuleName}}</span> <br />
                                        <span class="text-muted text-ellipsis">Complete: {{examModel.CompleteDateTime}}</span>
                                <br />
                                <hr />
                                <br />
                                <h6 class="mb-1">Total Marks: {{examModel.TotalMarks}}</h6>
                                <br />
                                <h5 class="mb-0">Obtained Marks: {{examModel.ContainMarks}}</h5>
                                <br />
                                <h4 *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == true)" class="mb-0">Result: <span class="text-success">Pass</span></h4>
                                <h4 *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == false)" class="mb-0">Result: <span class="text-danger">Fail</span></h4>
                                <br />

                            </div> -->
        </ng-template>
        <ng-template #NotCompleted>
          <div class="media-body">
            <h4 class="mb-0">Exam: {{examModel.Name}}</h4>
            <span class="text-muted-light">Module: {{examModel.ModuleName}}</span>
            <br />
            <hr />
            <br />
            <h4 class="mb-0">Result Pending</h4>
          </div>
        </ng-template>
      </div>

    </div>
  </div>

</div>