// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  API_URL : 'https://admin.paintastica.com/api/FlagshipApi/',
 API_Host : "https://admin.paintastica.com",
 API_SignalR_Hub : 'flagshipnotificationhub'

  // API_URL : 'http://localhost:43210/api/FlagshipApi/',
  // API_Host : "http://localhost:43210",

  //  API_URL : 'https://betatestadminflagship.arkinfosoft.in/api/FlagshipApi/',
  // API_Host : "https://betatestadminflagship.arkinfosoft.in",

  // API_URL : 'http://fda1.arkinfosoft.in/api/FlagshipApi/',
  // API_Host : "http://fda1.arkinfosoft.in",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
