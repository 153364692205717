<div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px">
  <div class="text-center mt-5 mb-5">
    <!-- <div class="avatar avatar-lg">
      <a href="http://flagshipdesignacademy.in/">
        <img
          src="assets/images/logo/fda.png"
          class="logo-width"
          alt="Paintastica"
        />
      </a>
    </div> -->
    <a href="http://flagshipdesignacademy.in/">
      <img src="assets/images/logo/fda.png" class="" alt="Paintastica" />
    </a>
  </div>
  <!-- <div class="d-flex justify-content-center mb-5 navbar-light">
    <a href="#" class="navbar-brand m-0">Flagship Design Academy</a>
  </div> -->
  <div class="card navbar-shadow">
    <div class="card-header text-center">
      <h4 class="card-title">Login</h4>
      <p class="card-subtitle">Access your account</p>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
    </div>
    <div class="card-body">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        novalidate
        method="get"
      >
        <div class="form-group">
          <label class="form-label">Your studentId:</label>
          <div class="input-group input-group-merge">
            <input
              type="text"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            />
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Your password:</label>
          <div class="input-group input-group-merge">
            <input
              type="password"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
        </div>
        <div class="media align-items-center mt-4">
          <div class="media-body">
            <div class="form-group mb-0 text-center">
              <!-- <a [routerLink]="['/home']" class="btn btn-primary btn-block" style="text-decoration: none;">Login</a> -->
              <button [disabled]="loading" class="btn btn-primary button-1 button-2" (click)="AgreeTerms()">
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Login
              </button>
            </div>
          </div>
          
        </div>
        <!-- <div class="text-center">
                    <a [routerLink]="['/login']" class="text-black-70" style="text-decoration: underline;">Forgot Password?</a>
                </div> -->
      </form>

      <!-- <form>
                <input id="myForm" type="file" name="file" (change)="postMethod($event.target.files)">
            </form> --> 
    </div>
    <div class="card-footer text-center text-black-50">
      Forgot Password?
      <a href="javascript: void(0);" data-toggle="modal" data-target="#myModal"
        >Click here</a
      >
    </div>
  </div>
</div>

<div
  class="modal bd-highlight"
  id="myModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form
        [formGroup]="forgotPasswordForm"
        (ngSubmit)="onForgotPasswordSubmit()"
      >
        <div class="modal-header d-block">
          <button
            type="button"
            class="close float-right"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title text-center" id="myModalLabel">
            Forgot Password
          </h5>
        </div>
        <div class="modal-body">
          <div *ngIf="forgotPassworderror" class="alert alert-danger mt-3 mb-3">
            {{ forgotPassworderror }}
          </div>
          <div *ngIf="msg" class="alert alert-success mt-3 mb-3">{{ msg }}</div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label"
              >Email</label
            >
            <div class="col-sm-10">
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [ngClass]="{
                  'is-invalid': forgotPasswordSubmitted && fo.email.errors
                }"
              />
              <div
                *ngIf="forgotPasswordSubmitted && fo.email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="fo.email.errors.required">Email is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div
  id="modelTearms"
  class="modal fade autoHeight"
  style="display: none !important"
  data-toggle="modal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-xl p-0">
    <div class="modal-content p-2 mt-0">
      <div class="modal-header p-1 d-block">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="OnModalClose()"
        >
          &times;
        </button>
        <h4 class="modal-title">Terms & Conditions.</h4>
      </div>
      <div class="modal-body" style="height: 450px; overflow-y: auto">
        <div>
          <p>
            This page states the Terms and Conditions (the “Terms” or the
            “Agreement”) of use. Www.flagshipdesignacademy.in provides online
            platform provide outstanding education in the field of Interior
            Design in affiliation with the GTU. FDA’s aim is to create quality
            education that enhance the student’s academic and personal
            development in the field of architecture and interior designing.
            FDA’s “Professional Certificate of design” course will lead to add
            more advantage in student’s career growth. This is a proprietary
            firm under the trade name Flagship Design Academy having its
            registered place of operation at A-208, Himalaya Arcade, Opp.
            Vastrapur lake, Besides Petrol Pump, Vastrapur, Ahmedabad- 380015,
            Gujarat, India.
          </p>
          <p>
            By Accessing or using this Www.flagshipdesignacademy. in website it
            is confirmed that you have subscribe to our course on “Professional
            Certificate of design” and you will have access to our online course
            content, teacher lectures, third party content, Exams, eBooks,
            Presentations, Notes, modules, syllabus, Literature including texts,
            graphics, images, audio, video (in any form or format), or any kind
            of content pack or subscribing to any of our portals, you agree to
            be bound, without limitation or qualification, to the Terms and
            Conditions mentioned herewith.
          </p>
          <p>
            The term "User" refers to an Enrolled Student. You are only
            authorized to use the Www.flagshipdesignacademy.in’s Services
            (regardless of whether your access or use is intended) if you agree
            to abide by all applicable laws and to this Agreement. Please read
            this Agreement carefully and save it. If you do not agree with it,
            you should leave the Www.flagshipdesignacademy.in Website and
            discontinue use of the Www.flagshipdesignacademy.in Services
            immediately. If you wish to become an Enrolled Student, communicate
            with other Enrolled Students, or make use of the
            Www.flagshipdesignacademy.in Services, you must read this Agreement
            and indicate your acceptance during the Registration process.
          </p>

          <p>
            Www.flagshipdesignacademy.in may, at its sole discretion, modify or
            revise the Terms and Conditions at any time by updating this
            posting. You agree to be bound by any changes; modification or
            revision to this Agreement when you use the
            Www.flagshipdesignacademy.in Services after any such modification is
            posted. It is therefore important that you review this Agreement
            regularly to ensure you are updated as to any changes.
          </p>

          <p>
            Www.flagshipdesignacademy.in reserves the right, in its sole
            discretion, to reject, refuse to post or remove any posting
            (including private messages) by you, or to restrict, suspend, or
            terminate your access to all or any part of the
            Www.flagshipdesignacademy.in Services at any time, for any or no
            reason, with or without prior notice, and without liability.
          </p>

          <p>
            THESE TERMS INCLUDE AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER
            THAT APPLY TO ALL CLAIMS BROUGHT AGAINST FDA. PLEASE READ THEM
            CAREFULLY; THEY AFFECT YOUR LEGAL RIGHTS.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Using FDA</p>
            </b>
            Who May Use our Services You may use our Services only if you can
            form a binding contract with FDA, and only in compliance with these
            Terms and all applicable laws. When you create your FDA account, and
            subsequently when you use certain features, you must provide us with
            accurate and complete information, and you agree to update your
            information to keep it accurate and complete.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Our License to You</p>
            </b>
            Subject to these Terms and our policies, we grant you a limited,
            personal, non-exclusive, non-transferable, and revocable license to
            use our Services. You may download content from our Services only
            for your personal, non-commercial use, unless you obtain our written
            permission to otherwise use the content. You also agree that you
            will create, access, and/or use only one user account, unless
            expressly permitted by FDA, and you will not share access to your
            account or access information for your account with any third party.
            Using our Services does not give you ownership of or any
            intellectual property rights in our Services or the content you
            access.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Content Offerings</p>
            </b>
            Changes to Content Offerings FDA offers courses and content
            ("Content Offerings") from universities and other providers
            ("Content Providers"). While we seek to provide world-class Content
            Offerings from our Content Providers, unexpected events do occur.
            FDA reserves the right to cancel, interrupt, reschedule, or modify
            any Content Offerings, or change the point value or weight of any
            assignment, quiz, or other assessment, either solely, or in
            accordance with Content Provider instructions. Content Offerings are
            subject to the Disclaimers and Limitation of Liability sections
            below.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">
                Disclaimer of Student-Content Provider Relationship
              </p>
            </b>
            Except as described in the Certification Programs section, nothing
            in these Terms or otherwise with respect to your participation in
            any Content Offerings by Content Providers: (a) establishes any
            relationship between you and any Content Provider (b) entitles you
            to use the resources of any Content Provider institution beyond
            participation in the Content Offering.
            <br />
            <br />
            The Services enable you to share your content, such as homework,
            quizzes, exams, projects, other assignments you submit, posts you
            make in the forums, and the like ("User Content"), with FDA,
            instructors, and/or other users. You retain all intellectual
            property rights in, and are responsible for, the User Content you
            create and share. User Content does not include course content or
            other materials made available on or placed on to the FDA platform
            by or on behalf of Content Providers or their instructors using the
            Services or Content Offerings. As between FDA and Content Providers,
            such Content Offerings are governed by the relevant agreements in
            place between FDA and Content Providers.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">
                How FDA and Others May Use User Content
              </p>
            </b>
            To the extent that you provide User Content, you grant FDA a
            fully-transferable, royalty-free, perpetual, sublicensable,
            non-exclusive, worldwide license to copy, distribute, modify, create
            derivative works based on, publicly perform, publicly display, and
            otherwise use the User Content. This license includes granting FDA
            the right to authorize Content Providers to use User Content with
            their registered students, on-campus learners, or other learners
            independent of the Services. Nothing in these Terms shall restrict
            other legal rights FDA may have to User Content, for example under
            other licenses. We reserve the right to remove or modify User
            Content for any reason, including User Content that we believe
            violates these Terms or other policies.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Security</p>
            </b>
            We care about the security of our users. While we work to protect
            the security of your account and related information, FDA cannot
            guarantee that unauthorized third parties will not be able to defeat
            our security measures. Any hacking or security breach done by third
            party will not bind FDA for any service provision and FDA cannot be
            held liable for non-performance of service. Please notify us
            immediately of any compromise or unauthorized use of your account by
            emailing flagship.design.academy@gmail.com
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Third Party Content</p>
            </b>
            Through the Services, you will have the ability to access and/or use
            content provided by instructors, other users, and/or other third
            parties and links to websites and services maintained by third
            parties. FDA cannot guarantee that such third party content, in the
            Services or elsewhere, will be free of material you may find
            objectionable or otherwise inappropriate or of malware or other
            contaminants that may harm your computer, mobile device, or any
            files therein. FDA disclaims any responsibility or liability related
            to your access or use of, or inability to access or use, such
            third-party content.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">
                Gujarat Technology University Right on content and curriculum
              </p>
            </b>
            We are affiliated with Gujarat Technology University for offering
            this course and therefore on the mandatory direction of the Gujarat
            Technology University we may change the content of the course in
            order to continue with the affiliation and same shall be binding to
            all the students during the tenure of their course.
            <br />
            <br />
            Along with that we will take necessary data as per the prevailing
            GTU Guidelines to ensure enrolment of student for this course and
            which may be updated from time to time as per their requirements.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Education Research</p>
            </b>
            FDA is committed to advancing the science of learning and teaching,
            and records of your participation in courses may be used for
            education research. In the interest of this research, you may be
            exposed to variations in the Content Offerings. Research findings
            will typically be reported at the aggregate level. Your personal
            identity will not be publicly disclosed in any research findings
            without your express consent.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">Paid Services from FDA</p>
            </b>
            FDA offers paid Service in the nature of course certificate for a
            fee. Unless otherwise stated, all fees are quoted in Indian Rupees.
            You are responsible for paying all fees charged by or for FDA and
            applicable taxes in a timely manner with a payment mechanism
            associated with the applicable paid Services. If your payment method
            fails or your account is past due, we may collect fees using other
            collection mechanisms. Fees may vary based on your location and
            other factors, and FDA reserves the right to change any fees at any
            time at its sole discretion. Any change, update, or modification
            will be effective immediately upon posting through the relevant
            Services. Refunds may be available for paid Services as described in
            our Refund Policy mentioned in this agreement. Unless full payment
            if fees are received from student, course will not be assigned to
            students. This may generally take 7 – 14 Days times depends upon the
            circumstances. However this period may not exceed 30 Days at any
            time.
            <br />
            <br />
            FDA will be sole authority to grant any payment terms at special
            request of the students, decision taken by the administrative
            authority shall be final and binding to all the students. Any
            dispute with respect to payment receipts shall be reported to
            administrative office numbers and emails designated and shall be
            reverted back in normal course of time
            <br />
            <br />
            Certification is issued by the Gujarat Technology University which
            will take time period of 30 days to 120 days after submission of the
            details by FDA to GTU. Certification issuance is responsibility of
            the FDA and for any delay in the final certification issue, student
            can reach out to administration team within due course of time and
            same shall be handled by the administration team on case to case
            basis.
          </p>

          <p>
            <b class="font-weight-bolder">
              <p class="font-weight-bolder">
                Modifying or Terminating our Services
              </p>
            </b>
            We are constantly changing and improving our Services. We may add or
            remove functions, features, or requirements, and we may suspend or
            stop part of our Services altogether. Accordingly, FDA may terminate
            your use of any Service for any reason. If your use of a paid
            Service is terminated, a refund may be available under our Refund
            Policy. We may not be able to deliver the Services to certain
            regions or countries for various reasons, including due to
            applicable export control requirements or internet access
            limitations and restrictions from governments. None of FDA, its
            Content Providers and instructors, its contributors, sponsors, and
            other business partners, and their employees, contractors, and other
            agents (the "FDA Parties") shall have any liability to you for any
            such action. You can stop using our Services at any time, although
            we'll be sorry to see you go.
            <br />
            <br />
            More details of our Service Offering and Terms associated with the
            same is mentioned herein below as a integral part of our agreement
          </p>

          <p>1. Use of Material</p>

          <ul>
            <li>
              The contents of Www.flagshipdesignacademy.in website, logos,
              trademarks, service marks, online and offline multimedia
              courses/content, teacher lectures, third party content, exams,
              eBooks, teacher manual, teacher syllabus or any kind of content
              pack CD, educational courses, products, services, links, marketing
              tools and Literature including texts, Notes, activities, eBook,
              graphics, images, audio, video (in any form or format), are herein
              after referred to as &ldquo;Material&rdquo;.
            </li>
            <li>
              The authority of the material posted to this site is the sole
              authority of that particular publisher, channel and are protected
              or controlled by Www.flagshipdesignacademy.in (like Authors,
              teacher, publisher and content provider companies registered under
              by or in The Company), or by third parties that have licensed
              their &ldquo;Material&rdquo; to the Company.
              Www.flagshipdesignacademy.in does not take the responsibility of
              the contents posted on the site, as it&rsquo;s an educational
              networking site, contents related to an educational topic can be
              posted from anywhere and everywhere and is at the sole discretion
              of the channel from which it&rsquo;s being posted.
            </li>
            <li>
              Www.flagshipdesignacademy.in may delete any communication or
              content that, in the sole judgment of
              Www.flagshipdesignacademy.in, (a) violates this Agreement (b) may
              be offensive or illegal or (c) harms, threatens the safety of, or
              violates the rights of any person. Www.flagshipdesignacademy.in
              assumes no responsibility for monitoring the
              Www.flagshipdesignacademy.in Services for inappropriate
              communication, content or conduct. If at any time
              Www.flagshipdesignacademy.in chooses, in its sole discretion, to
              monitor the Www.flagshipdesignacademy.in Services,
              Www.flagshipdesignacademy.in nonetheless assumes no responsibility
              for any communication or Content. Www.flagshipdesignacademy.in has
              no obligation to modify or remove any inappropriate Content, and
              no responsibility for the conduct of any User submitting such
              Content.
            </li>
            <li>
              If you violate any of these Terms or Conditions, your permission
              to use the &ldquo;Material&rdquo; automatically terminates and you
              must immediately destroy any copies you have made of the
              &ldquo;Material&rdquo;.
            </li>
            <li>
              &quot;Material&quot; may contain certain software or products and
              services, which require special or specific rules to be applied
              before use. Any such rules are listed as &quot;Legal Notices&quot;
              on the &quot;Material&quot; and are incorporated into this
              Agreement by reference.
            </li>
            <li>
              Copyright Policy You may not post, modify, distribute, or
              reproduce in any way any copyrighted material, trademarks, or
              other proprietary information belonging to others without
              obtaining the prior written consent of the owner of such
              proprietary rights. It is the policy of
              Www.flagshipdesignacademy.in to terminate Enrolled Studentship
              privileges of any Enrolled Student who repeatedly infringes the
              copyright rights of others upon receipt of proper notification to
              Www.flagshipdesignacademy.in by the copyright owner or the
              copyright owner&#39;s legal agent. Without limiting the foregoing,
              if you believe that your work has been copied and posted on the
              Www.flagshipdesignacademy.in Services in a way that constitutes
              copyright infringement, please provide our Copyright Agent with
              the following information:
              <ul style="list-style-type: circle">
                <li>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest;
                </li>
                <li>
                  A description of the copyrighted work that you claim has been
                  infringed;
                </li>
                <li>
                  A description of where the material that you claim is
                  infringing is located on the Www.flagshipdesignacademy.in
                  Services;
                </li>
                <li>Your address, telephone number, and e-mail address;</li>
                <li>
                  A written statement by you that you have, in good faith, a
                  belief that the disputed use is not authorized by the
                  copyright owner, its agent, or the law;
                </li>
                <li>
                  A statement by you, made under penalty of perjury, that the
                  above information in your notice is accurate and that you are
                  the copyright owner or authorized to act on the copyright
                  owner&#39;s behalf. Www.flagshipdesignacademy.in Copyright
                  Agent for notice of claims of copyright infringement can be
                  reached as follows:
                </li>
              </ul>
            </li>
          </ul>

          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Address :- Flagship Design
            Academy <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A-208, Himalaya
            Arcade, Opp. Vastrapur lake, Besides Petrol Pump, &nbsp;Vastrapur,
            Ahmedabad- 380015, Gujarat, India
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email Id :-
            flagship.design.academy@gmail.com
          </p>

          <p>2. Company&#39;s Liability</p>

          <p>
            &quot;Material&quot; and any software
            Facilities/Features/service/tools may contain inaccuracies or
            typographical errors. Www.flagshipdesignacademy.in makes no
            representations about the accuracy, reliability, completeness, or
            timeliness of the &quot;Material&quot; or about the results to be
            obtained from using the &quot;Material&quot;. Any use of the
            &quot;Material&quot; is at the user&#39;s own risk.
          </p>

          <ul>
            <li>
              Facilities/Features/service/tools currently available in the
              system can at any point of time we may add/change/modify/
              discontinue/upgraded/withdrawn/removed without any prior
              notification.
            </li>
            <li>
              Changes in the Terms: Company reserves the right to revise these
              Terms in its sole discretion at any time and without prior notice
              to you other than by posting the revised Terms on the Site.
            </li>
            <li>
              Changes are periodically made to &quot;Material&quot;.
              Www.flagshipdesignacademy.in is not bound to provide prior notice
              of any such changes.
            </li>
            <li>
              We may from time to time offer various features that require
              registration or the creation of an account on
              Www.flagshipdesignacademy.in website. If at any time you choose to
              register or create an account with us, these additional terms and
              conditions also will apply.
            </li>
            <li>
              If you are under the age of 18, you have obtained parental or
              legal guardian consent before using the Site.
            </li>
            <li>
              Www.flagshipdesignacademy.in shall not be held responsible for any
              &quot;Material&quot; provided by third parties or channel.
            </li>
            <li>
              Www.flagshipdesignacademy.in does not warrant that the
              &quot;Material&quot; will operate free of errors - or that its
              servers are free of computer viruses or other harmful goods. If
              your use of the &quot;Material&quot; results in the need for
              servicing or replacing equipment or data,
              Www.flagshipdesignacademy.in is not responsible for those costs.
            </li>
            <li>
              Any kind of Enrolled Studentship fees/subscription, fees/payment,
              only for Www.flagshipdesignacademy.in platform or services not for
              any &quot;Material&quot;.
            </li>
            <li>
              The &quot;Material&quot; is provided on an &#39;as is&#39; basis
              without any warranties of any kind. Www.flagshipdesignacademy.in
              and its suppliers, to the fullest extent permitted by law,
              disclaim all warranties, including the warranty of
              merchantability, non- infringement of proprietary or third-party
              rights and the warranty of fitness for particular purpose.
              Www.flagshipdesignacademy.in and its suppliers make no warranties
              about the accuracy, reliability, completeness, or timeliness of
              the &quot;Material&quot;.
            </li>
          </ul>

          <p>3. Disclaimer of Certain Damages</p>

          <p>
            In no event shall Www.flagshipdesignacademy.in, its suppliers, or
            any third parties mentioned in the &quot;Material&quot; be liable
            for any damages, whatsoever, (including, without limitation,
            incidental and consequential damages, lost profits, or damages
            resulting from lost data or business interruption) resulting from
            the use or inability to use the &quot;Material&quot;, whether based
            on warranty, contract, tort, or any other legal theory, and whether
            or not Www.flagshipdesignacademy.in is advised of the possibility of
            such damages.
          </p>

          <p>4. User Submissions</p>

          <p>
            Www.flagshipdesignacademy.in does not control the information
            delivered and upload to the
            &ldquo;Www.flagshipdesignacademy.in&rdquo;, chat rooms, customer
            forum or user&#39;s websites and Www.flagshipdesignacademy.in has no
            obligation to monitor any such information. As a User, you are
            responsible for your own communications and are responsible for the
            consequences of their posting. You must not do any of the following
            things while accessing or using the &quot;Material&quot;
          </p>

          <ul>
            <li>
              Post or transmit material that infringes any patent, trademark,
              copyright, trade secret or other proprietary rights of any party
              (the &quot;Rights&quot;), unless you are the owner of the Rights
              or have the permission of the owner to post or transmit such
              material;
            </li>
            <li>
              Post a material that infringes on any other intellectual property
              rights of others or on the privacy or publicity rights of others;
            </li>
            <li>
              Publish, post, upload, distribute or disseminate or offer to do
              the same to any material that is inappropriate, obscene,
              defamatory, infringing, threatening, harassing, abusive, hateful,
              embarrassing or otherwise objectionable to another User or any
              other person, community or entity;
            </li>
            <li>Post sexually - explicit language or images;</li>
            <li>Post advertisements or solicitations of business;</li>
            <li>
              Post or transmit any chain letters, pyramid schemes, surveys or
              contests;
            </li>
            <li>
              Impersonate another person or entity, or falsify or delete any
              author attributions, legal or other proper notices or proprietary
              designations or labels of the origin or source of software or
              other material contained in a file that is posted;
            </li>
            <li>
              Intentionally or unintentionally violate any applicable local,
              state, national or international law while using or accessing the
              &quot;Material&quot;;
            </li>
            <li>
              Restrict or inhibit any other user from using and enjoying the
              &quot;Material&quot;.
            </li>
            <li>
              Intentionally or unintentionally violate any applicable local,
              state, national or international law while using or accessing the
              &quot;Material&quot;;
            </li>
            <li>Harm minors in any way; or</li>
            <li>
              Collect or store information about others, including e-mail
              addresses, without their consent.
            </li>
            <li>
              You are responsible for maintaining the integrity of your personal
              account. The user account is non- transferable without the express
              written permission of The Company. If you suspect that
              unauthorized use of your account has occurred, you must
              immediately report such activity to Www.flagshipdesignacademy.in
              at info@Www.flagshipdesignacademy.in. You agree to indemnify and
              hold harmless Www.flagshipdesignacademy.in against any and all
              claims arising out of your use or alleged use of your user account
              and user online personal. You further agree to co-operate with
              Www.flagshipdesignacademy.in in the defence of claims related to
              your use or alleged use of your account or your online personal.
            </li>
            <li>
              Www.flagshipdesignacademy.in does not endorse, support, represent
              or guarantee the truthfulness, accuracy, or reliability of any
              communications posted by other users or endorses any opinions
              expressed by users. You acknowledge that any reliance or material
              posted by other users will be at your own risk.
            </li>
            <li>
              Www.flagshipdesignacademy.in does not screen user communications
              in advance and is not responsible for screening or monitoring
              material posted by users. If notified by a user of any such
              communications, which allegedly do not conform to any term of this
              Agreement, Www.flagshipdesignacademy.in may investigate the
              allegation and determine in good faith, in its sole discretion,
              whether to remove or request the removal of such communications.
              Www.flagshipdesignacademy.in has no liability or responsibility to
              Users for performance or non-performance of such activities.
              Www.flagshipdesignacademy.in reserves the right to expel users and
              prevent their further access to the &quot;Material&quot; for
              violating this Agreement or the law and has exclusive right to
              remove such communications which are abusive, illegal, or
              disruptive at any time without notice.
            </li>
            <li>
              You acknowledge that the postings on the &quot;Material&quot; are
              public and not private communications and therefore others may
              read your communications without your knowledge. Always use
              caution when giving out any personally identifying information
              about yourself or your children in the &quot;Material&quot;.
              Www.flagshipdesignacademy.in does not control or endorse the
              content, messages or information found in the &quot;Material&quot;
              and, therefore, Www.flagshipdesignacademy.in specifically
              disclaims any liability with regard to the &quot;Material&quot;
              and any actions resulting from your participation in the
              &quot;Material&quot;. Generally, any communication which you post
              on the &quot;Material&quot; (whether in chat rooms, discussion
              groups, user&#39;s websites or otherwise) is considered to be
              non-confidential. If particular web pages permit the submission of
              communications that will be treated by
              Www.flagshipdesignacademy.in as confidential, that fact will be
              stated on those pages.
            </li>
          </ul>

          <p>5. Accuracy of Account Information</p>

          <p>
            In consideration of your use of website/portal and Company Products,
            you agree to
          </p>

          <ul>
            <li>
              Provide true, accurate, current and complete information about
              yourself as prompted by Company&#39;s registration form (such
              information being &quot;Your Data&quot;),
            </li>
            <li>
              Maintain and promptly update Your Data to keep it true, accurate,
              current and complete
            </li>
            <li>
              Comply with these Terms. If you provide any information that is
              untrue, inaccurate, not current, incomplete or misleading, or if
              we believe that such information is untrue, inaccurate, not
              current incomplete or misleading, then we reserve the right to
              suspend or terminate your account and refuse or restrict any and
              all current or future use of the Company Products, without any
              liability to you.
            </li>
          </ul>

          <p>6. Non-commercial Use by Enrolled Students</p>

          <p>
            The Www.flagshipdesignacademy.in Services are for the personal use
            of Enrolled Students only and may not be used in connection with any
            commercial endeavors except those that are specifically endorsed or
            approved by Www.flagshipdesignacademy.in. Illegal and/or
            unauthorized use of the Www.flagshipdesignacademy.in Services,
            including collecting usernames and/or e-mail addresses of Enrolled
            Students by electronic or other means for the purpose of sending
            unsolicited e-mail or unauthorized framing of or linking to the
            Www.flagshipdesignacademy.in Website is prohibited. Commercial
            advertisements, affiliate links, and other forms of solicitation may
            be removed from Enrolled Student profiles without notice and may
            result in termination of Enrolled Studentship privileges.
            Appropriate legal action will be taken for any illegal or
            unauthorized use of the Www.flagshipdesignacademy.in Services.
          </p>

          <p>7. Disciplinary Action</p>

          <p>
            Violation of the rules of conduct may result in disciplinary action
            issued by a system administrator against that particular user.
            Violations are evaluated on a case-by-case basis depending on the
            severity of the offense and past history of violations. It will
            mainly covers Vulgar, offensive, defamatory, pornographic, or
            obscene material will not be tolerated and is subject to deletion.
            This includes discriminatory statements, including those with regard
            to race, religion, gender, sexual orientation, region, etc.
          </p>

          <p>
            A ban is the permanent deactivation of the violator&#39;s account
            with Www.flagshipdesignacademy.in. A system administrator applies a
            ban in response to very severe offenses and/or consistent display of
            unwillingness to follow the Terms and Conditions.
          </p>

          <p>8. Disclaimers</p>

          <p>
            Www.flagshipdesignacademy.in is not responsible for any incorrect or
            inaccurate Content posted on the Www.flagshipdesignacademy.in
            Website or in connection with the Www.flagshipdesignacademy.in
            Services, whether caused by Users of the
            Www.flagshipdesignacademy.in Services or by any of the equipment or
            programming associated with or utilized in the
            Www.flagshipdesignacademy.in Services. Www.flagshipdesignacademy.in
            does not guarantee the efficacy of its learning content &amp;
            material. Other provisions may be added to qualify the company&#39;s
            liability to the presentation of effective quality education and
            training material. Profiles created and posted by Enrolled Students
            on the Www.flagshipdesignacademy.in website may contain links to
            other websites.
          </p>

          <p>
            Www.flagshipdesignacademy.in is not responsible for the Content,
            accuracy or opinions expressed on such websites, and such websites
            are in no way investigated, monitored or checked for accuracy or
            completeness by Www.flagshipdesignacademy.in. The inclusion of any
            linked website on the Www.flagshipdesignacademy.in Services does not
            imply approval or endorsement of the linked website by
            Www.flagshipdesignacademy.in. When you access these third-party
            sites, you do so at your own risk. Www.flagshipdesignacademy.in
            takes no responsibility for third party advertisements which are
            posted on this Www.flagshipdesignacademy.in Website or through the
            Www.flagshipdesignacademy.in Services, nor does it take any
            responsibility for the goods or services provided by its
            advertisers. Www.flagshipdesignacademy.in is not responsible for the
            conduct, whether online or offline, of any User of the
            Www.flagshipdesignacademy.in Services.
          </p>

          <p>
            Www.flagshipdesignacademy.in assumes no responsibility for any
            error, omission, interruption, deletion, defect, delay in operation
            or transmission, communications line failure, theft or destruction
            or unauthorized access to, or alteration of, any User or Enrolled
            Student communication. Www.flagshipdesignacademy.in is not
            responsible for any problems or technical malfunction of any
            telephone network or lines, computer online systems, servers or
            providers, computer equipment, software, failure of any e-mail or
            players due to technical problems or traffic congestion on the
            Internet or on any of the Www.flagshipdesignacademy.in Services or
            combination thereof, including any injury or damage to Users or to
            any person&#39;s computer related to or resulting from participation
            or downloading materials in connection with the
            Www.flagshipdesignacademy.in Services.
          </p>

          <p>
            Under no circumstances shall Www.flagshipdesignacademy.in be
            responsible for any loss or damage, including personal injury or
            death, resulting from use of the Www.flagshipdesignacademy.in
            Services, attendance at a Www.flagshipdesignacademy.in event, from
            any Content posted on or through the Www.flagshipdesignacademy.in
            Services, or from the conduct of any Users of the
            Www.flagshipdesignacademy.in Services, whether online or offline.
            The Www.flagshipdesignacademy.in Services are provided
            &quot;AS-IS&quot; and as available and Www.flagshipdesignacademy.in
            expressly disclaims any warranty of fitness for a particular purpose
            or non-infringement. Www.flagshipdesignacademy.in cannot guarantee
            and does not promise any specific results from use of the
            Www.flagshipdesignacademy.in Services.
          </p>

          <p>9. Licenses</p>

          <p>
            All software that is made available for downloading from the
            &quot;Material&quot; is protected by copyright and may be protected
            by other rights. The use of such software is governed by the terms
            of the software license agreement or designated &quot;Legal
            Notice&quot; accompanying such software (&quot;License
            Agreement&quot;). The downloading and use of such software is
            conditioned on agreement to be bound by the terms of the
            &quot;License Agreement&quot;.
          </p>

          <p>
            Subject to the foregoing paragraph or as otherwise expressly
            provided, nothing within the &quot;Material&quot; shall be construed
            as conferring any license under any of the Company&#39;s or any
            third party&#39;s intellectual property rights, whether by
            implication, estoppels or otherwise.
          </p>

          <p>10. Limitation of Liability</p>

          <p>
            Your use of the &quot;Material&quot; is at your own risk. If you are
            dissatisfied with any of the contents of the &quot;Material&quot;,
            the terms and conditions, The Company&#39;s policies and procedures,
            your sole remedy is to discontinue use of the &quot;Material&quot;.
          </p>

          <p>
            Under no circumstances shall Www.flagshipdesignacademy.in be liable
            to any user on account of that user&#39;s use of the
            &quot;Material&quot;. Such limitation of liability shall apply to
            prevent recovery of any and all damages including, without
            limitation, direct, indirect, incidental, consequential, special and
            exemplary damages arising from any use of the &quot;Material&quot;,
            including any damages that may be incurred by third parties.
          </p>

          <p>
            In no event shall Www.flagshipdesignacademy.in be liable to you or
            any third party for any indirect, consequential, exemplary,
            incidental, special or punitive damages, including lost profit
            damages arising from your use of the services, even if
            Www.flagshipdesignacademy.in has been advised of the possibility of
            such damages. Notwithstanding anything to the contrary contained
            herein, Www.flagshipdesignacademy.in liability to you for any cause
            whatsoever and regardless of the form of the action, will at all
            times be limited to the amount paid, if any, by you to
            Www.flagshipdesignacademy.in for the Www.flagshipdesignacademy.in
            services during the term of Enrolled Studentship.
          </p>

          <p>
            Www.flagshipdesignacademy.in does not authorize any third party to
            accept payments on behalf of Www.flagshipdesignacademy.in for any
            Www.flagshipdesignacademy.in Service. Www.flagshipdesignacademy.in
            not liable for any payments made to a third party by an
            Www.flagshipdesignacademy.in Enrolled Student.
          </p>

          <p>
            Www.flagshipdesignacademy.in cannot provide a refund or exchange on
            the purchase of any Material on any digital storage device.
          </p>

          <p>
            The course mentioned on the site of Gujarat Technology University
            website is tentative and there might be change in the modules
            internally to provide course in the best possible interest of
            student only.&nbsp; FDA will not be held responsible for any such
            internal changes in the module done on periodical basis
          </p>

          <p>11. Indemnity</p>

          <p>
            You agree to defend, indemnify, and hold harmless The Company, its
            officers, directors, employees and agents, from and against any
            claims, actions or demands, including without limitation reasonable
            legal and accounting fees, alleging or resulting from your use of
            the &quot;Material&quot; or your breach of the terms of this
            Agreement. Shall provide notice to you promptly of any such claim,
            suit, or proceeding and shall assist you, at your expense, in
            defending any such claim, suit or proceeding.
          </p>

          <p>12. User Information</p>

          <p>
            Www.flagshipdesignacademy.in may use the information it obtains
            relating to you, including your IP address, name, mailing address,
            email address and use of the &quot;Material&quot; for its internal
            business and marketing purposes, and may disclose the information to
            third parties for such purposes.
          </p>

          <p>
            13. Information Collection and Use by Www.flagshipdesignacademy.in
          </p>

          <p>
            Www.flagshipdesignacademy.in collects user submitted information,
            such as name and e-mail address, to authenticate users and to send
            notifications to those users relating to the
            Www.flagshipdesignacademy.in service. Www.flagshipdesignacademy.in
            also collects other profile data including but not limited to:
            personal interests, gender, age, education, and occupation.
          </p>

          <p>
            Www.flagshipdesignacademy.in also logs non-personally-identifiable
            information including IP address, profile information, aggregate
            user data, and browser type, from users and visitors to the site.
            This data is used to manage the website, track usage and improve the
            Website services. This non-personally-identifiable information may
            be shared with third-parties to provide more relevant services and
            advertisements to Enrolled Students. User IP addresses are recorded
            for security and monitoring purposes. We may also use a user&#39;s
            e-mail address to send updates, newsletters or news regarding the
            Services.
          </p>

          <p>14. Use of Cookies</p>

          <p>
            Www.flagshipdesignacademy.in uses cookies to store visitors&#39;
            preferences and to record session information, for purposes
            including ensuring that visitors are not repeatedly offered the same
            advertisements and to customize newsletter, advertising, and Web
            page content based on browser type and user profile information. We
            do not link the information we store in cookies to any personally
            identifiable information you submit while on our site.
          </p>

          <p>
            You may be able to configure your browser to accept or reject all or
            some cookies, or to notify you when a cookie is set -- each browser
            is different, so check the &quot;Help&quot; menu of your browser to
            learn how to change your cookie preferences -- however, you must
            enable cookies from Www.flagshipdesignacademy.in in order to use
            most functions on the site. Please note that
            Www.flagshipdesignacademy.in allows 3rd party advertisers that are
            presenting advertisements on some of our pages to set and access
            their cookies on your computer. Advertisers&#39; use of cookies is
            subject to their own privacy policies, not the
            Www.flagshipdesignacademy.in Privacy Policy.
          </p>

          <p>
            15. Sharing and Disclosure of Information
            Www.flagshipdesignacademy.in Collects
          </p>

          <p>
            Except as otherwise described in this privacy statement,
            Www.flagshipdesignacademy.in will not disclose personal information
            to any third party unless we believe that disclosure is necessary:
          </p>

          <ul>
            <li>
              To conform to legal requirements or to respond to a subpoena,
              search warrant or other legal process received by
              Www.flagshipdesignacademy.in, whether or not a response is
              required by applicable law;
            </li>
            <li>
              To enforce the Www.flagshipdesignacademy.in Terms of Use Agreement
              or to protect our rights; or
            </li>
            <li>
              To protect the safety of the Enrolled Student of the public, and
              the user of the service.
            </li>
          </ul>

          <p>16. Termination of Services and Termination of Agreement</p>

          <p>
            We may terminate your use of the Company Products immediately
            without notice for any breach by you of these Terms or any of our
            applicable policies, as posted on the Site from time to time.
            Furthermore, we may terminate your rights to use the Company
            Products for any reason or no reason.
          </p>

          <p>
            Students will have right to terminate any service subscription taken
            from the FDA within 60 Days of payment to FDA, no claim for
            termination of service with refund shall be accepted by the FDA
            after 60 Days of payment.
          </p>

          <p>
            You are free to terminate your use of the Company Products at any
            time. You can simply choose to stop visiting or using any aspect of
            the Company Products. If you wish to terminate your account on the
            Site or with the Services, you may do so by contacting us via
            <a name="_Hlk76574449">flagship.design.academy@gmail.com</a>.
          </p>

          <p>19. General</p>

          <p>
            Www.flagshipdesignacademy.in makes no claims that the
            &quot;Materials&quot; are appropriate for any particular purpose or
            audience, or that they may be downloaded outside of the specified
            host country. Access to the Materials (including Software) may not
            be legal by certain persons or in certain countries. If you access
            the &quot;Material&quot; from outside of the specified country, you
            do so at your own risk and are responsible for compliance with the
            laws of your jurisdiction.
          </p>

          <p>
            Www.flagshipdesignacademy.in is based in India. The laws of India
            shall deal all legal issues arising from or related to the use of
            the &quot;Material&quot;. You hereby irrevocably waive, to the
            fullest extent permitted by law, any objection, which you may now or
            proceeding brought in such a court and any claim that any such
            proceeding brought in such a court has been brought in an
            inconvenient forum. In the event of a dispute, the exclusive
            jurisdiction is Ahmedabad
          </p>

          <p>
            If any provision of this Agreement is found to be invalid by any
            court having competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the remaining provisions
            of this Agreement, which shall remain in full force and effect. No
            waiver of any term of this Agreement shall be deemed a further or
            continuing waiver of such term or any other term. Except as
            expressly provided in a particular &quot;Legal Notice&quot; for the
            &quot;Material&quot;, this Agreement constitutes the entire
            Agreement between you and Www.flagshipdesignacademy.in with respect
            to the use of the &quot;Material&quot;. Any changes to this
            Agreement must be made in writing and signed by an authorized
            representative of the Company.
          </p>

          <p>
            The content provided by FDA shall be having limited hours binding in
            terms of completion of each module and all the students enrolled
            with the programme required to obey the terms and time line defined
            for the purpose of completing course in time. In case of death of
            student during the course tenure, final right of refund or transfer
            of course credentials or cancellation of registration shall remain
            with the administration head and decision taken by the management
            shall be binding to legal representative of the student.
          </p>

          <p>20. Acceptable Use Policy</p>

          <p>
            We want to make sure that all of our users and instructors feel safe
            and comfortable while using our Services. We have drafted this
            policy to ensure that people understand and follow the rules when
            participating in our online community and otherwise using our
            Services.
          </p>

          <p>
            We may remove or edit inappropriate content or activity identified
            by or reported to us by users. We may separately suspend, disable,
            or terminate a user&#39;s access to all or part of the Services.
          </p>

          <p>You are prohibited from using our Services by</p>

          <p>&nbsp;</p>

          <ul>
            <li>
              Sharing your password, let anyone besides yourself access your
              account, or do anything that might put your account at risk.
            </li>
            <li>Attempt to access any other user&#39;s account.</li>
            <li>
              Reproduce, transfer, sell, resell, or otherwise misuse any content
              from our Services, unless specifically authorized to do so.
            </li>
            <li>
              Access, tamper with, or use non-public areas of our systems,
              unless specifically authorized to do so.
            </li>
          </ul>

          <ul>
            <li>
              Break or circumvent our authentication or security measures or
              otherwise test the vulnerability of our systems or networks,
              unless specifically authorized to do so.
            </li>
            <li>
              Try to interfere with any user, host, or network, for example by
              sending a virus, overloading, spamming, or mail-bombing.
            </li>
            <li>Use our Services to distribute malware.</li>
            <li>
              Use our Services or any functionality of the platform for anything
              other than for completing online courses or for pedagogical
              purposes.
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity.
            </li>
            <li>Encourage or help anyone do any of the things on this list.</li>
          </ul>

          <p>
            All users participating in Content Offerings must agree to abide by
            the following conducts:
          </p>

          <ol>
            <li>
              &nbsp;You will register for only one account, unless expressly
              permitted to register for additional accounts by FDA.
            </li>
            <li>
              &nbsp;&nbsp;Your answers to homework, quizzes, exams, projects,
              and other assignments will be your own work (except for
              assignments that explicitly permit collaboration) as specified and
              notified under in the FDA&nbsp;Plagiarism Policy.
            </li>
            <li>
              &nbsp;&nbsp;You will not make solutions to homework, quizzes,
              exams, projects, and other assignments available to anyone else
              (except to the extent an assignment explicitly permits sharing
              solutions). This includes both solutions written as well as any
              solutions provided by the course staff or others.
            </li>
            <li>
              &nbsp;You will not engage in any other activities that will
              dishonestly improve my results or dishonestly improve or hurt the
              results of others.
            </li>
          </ol>

          <p>
            Any violation of this code may result in your access to all or part
            of the Services being suspended, disabled, or terminated
          </p>

          <p>21. Refund Policy</p>

          <p>
            For details on our refund deadlines and policies, please refer to
            the information below.. Please also note that we treat violations of
            our Terms of Use very seriously, and we have no obligation to offer
            refunds to users who violate these or other FDA policies, even if
            their requests are made within the designated refund period.
            Similarly, we have no obligation to offer late refunds to users who
            do not receive a passing mark or fails to complete required course
            in the given time frame as mentioned in Content Offering, or who are
            otherwise unsatisfied with their final grade.
          </p>

          <p>
            If you cancel your paid enrolment for a standalone course, FDA will
            not offer you any refunds All Refunds and cancellations will also be
            governed by the terms of that contract between you and FDA.
          </p>

          <p>
            In case the student decide to cancel its registration to any course
            within 60 Days of payment than same shall be refunded back once the
            management approved the same on special request of the student.
          </p>

          <p>22. Disclaimers</p>

          <p>
            THE SERVICES AND ALL INCLUDED CONTENT ARE PROVIDED ON AN &quot;AS
            IS&quot; BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED. THE FDA PARTIES SPECIFICALLY DISCLAIM ANY AND ALL
            WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
            OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE FDA PARTIES FURTHER
            DISCLAIM ANY AND ALL LIABILITY RELATED TO YOUR ACCESS OR USE OF THE
            SERVICES OR ANY RELATED CONTENT. YOU ACKNOWLEDGE AND AGREE THAT ANY
            ACCESS TO OR USE OF THE SERVICES OR SUCH CONTENT IS AT YOUR OWN
            RISK.
          </p>

          <p>23. Limitation of Liability</p>

          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE FDA PARTIES SHALL NOT BE
            LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
            PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
            INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL,
            OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR
            USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONDUCT
            OR CONTENT OF ANY PARTY OTHER THAN THE APPLICABLE FDA PARTY,
            INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL
            CONDUCT; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR
            CONTENT OR INFORMATION. IN NO EVENT SHALL FDA&#39;S AGGREGATE
            LIABILITY FOR ALL CLAIMS RELATED TO THE SERVICES EXCEED FEES
            COLLECTED BY THE FDS FROM ENROLLED STUDENT OR THE TOTAL AMOUNT OF
            FEES RECEIVED BY FDA FROM YOU FOR THE USE OF PAID SERVICES DURING
            THE PAST SIX MONTHS, WHICHEVER IS HIGHER.
          </p>

          <p>
            YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE LIMITATIONS
            OF LIABILITY SET FORTH IN THIS TERMS OF USE REFLECT A REASONABLE AND
            FAIR ALLOCATION OF RISK BETWEEN YOU AND THE FDA PARTIES, AND THAT
            THESE LIMITATIONS ARE AN ESSENTIAL BASIS TO FDA&#39;S ABILITY TO
            MAKE THE SERVICES AVAILABLE TO YOU ON AN ECONOMICALLY FEASIBLE
            BASIS.
          </p>

          <p>
            YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO THE SERVICES MUST
            COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
            OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
          </p>

          <p>24. Indemnification</p>

          <p>
            You agree to indemnify, defend, and hold harmless the FDA Parties
            from any and all claims, liabilities, expenses, and damages,
            including reasonable attorneys&#39; fees and costs, made by any
            third party related to: (a) your use or attempted use of the
            Services in violation of these Terms; (b) your violation of any law
            or rights of any third party; or (c) User Content, including without
            limitation any claim of infringement or misappropriation of
            intellectual property or other proprietary rights.
          </p>

          <p>25. Governing Law and Venue</p>

          <p>
            The Services are managed by FDA, which is located in Ahmedabad,
            India. You agree that any dispute related to these Terms will be
            governed by the laws of the India. In the event of any dispute
            related to these Terms that is not subject to binding arbitration,
            you and FDA will submit to the Ahmedabad jurisdiction as the legal
            forum for any such dispute.
          </p>

          <p>26.&nbsp; General Terms</p>

          <p>Revisions to the Terms</p>

          <p>
            We reserve the right to revise the Terms at our sole discretion at
            any time. Any revisions to the Terms will be effective immediately
            upon posting by us. For any material changes to the Terms, we will
            take reasonable steps to notify you of such changes, via a banner on
            the website, email notification, another method, or combination of
            methods. In all cases, your continued use of the Services after
            publication of such changes, with or without notification,
            constitutes binding acceptance of the revised Terms.
          </p>

          <p>Severability; Waiver</p>

          <p>
            If it turns out that a particular provision of these Terms is not
            enforceable, this will not affect any other terms. If you do not
            comply with these Terms, and we do not take immediate action, this
            does not indicate that we relinquish any rights that we may have
            (such as taking action in the future).
          </p>

          <p>
            If you have any questions regarding FDA Terms of Use,Please write to
            <a href="javascript:void(0)" name="_Hlk76574322"
              >flagship.design.academy@gmail.com</a
            >
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <p class="text-right">
          <button class="btn btn-success" (click)="AgreeTerms()">Agree</button>
        </p>
      </div>
    </div>
  </div>
</div> -->

<style>
  .logo-width {
    width: 100px !important;
  }
</style>
