<div class="mdk-drawer-layout__content page ">

    <button id="btnNotify" type="button" style="display: none;" (click)="GetNotification()">Notify</button>
    <div class="row m-0">
        <div class="container-fluid page__container">
            
            <div class="media align-items-center">
                <div class="media-body">
                    <h1 class="h2">Notification</h1>
                </div>
                <div class="media-right">
                    <a class="btn btn-primary" [routerLink]="['home']">Back</a>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 align-items-center">
                    <div class="clearfix"></div>
                    
                        <div class="row">
                            
                                <ng-container *ngIf="listNotification == null; then Have; else NotHave"></ng-container>
                                <ng-template #Have>
                                    <div class="card border-left-3 border-left-primary card-2by1 col-md-12 w-100">
                                        <div class="card-body">
                                            <div class="media flex-wrap align-items-center">
                                                <div class="media-body">
                                                    You don't have any notification.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #NotHave>
                                    <div class="card col-md-12 w-100">
                                        <div class="card-body">
                                            <ul class="list-group list-group-fit">

                                                <li class="list-group-item forum-thread" *ngFor="let notification of listNotification | paginate: { itemsPerPage: 10, currentPage: p }">
                                                    <div class="media align-items-center">
                                                        <div class="media-left">
                                                        </div>
                                                        <div class="media-body d-flex flex-column">
                                                            <div class="d-flex align-items-center">
                                                                <a href="javascript: void(0);" class="text-body"><strong>{{notification.CreatedUserName}}</strong></a>
                                                                <br>
                                                            </div>
                                                            <span class="text-muted">{{notification.Title}}</span>
                                                            <p class="mt-1 mb-0 text-black-70">{{notification.Message}}</p>
                                                        </div>
                                                    </div>
                                                </li>
                    
                                            </ul>

                                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                        </div>
                                    </div>
                                

                                </ng-template>
                                    
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
