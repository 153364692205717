import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ApiResponse } from 'src/app/models/api-response';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
//import * as $ from "jquery";
import { Subscription } from 'rxjs';
import { Modal } from 'src/app/models/modal';
import { DatePipe } from '@angular/common';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe]
})

export class HomeComponent implements OnInit {



  loaderPlay = false;
  currentUser: User = null;
  response : ApiResponse = null;
  thoughOfDay;
  courseList = [];

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;
  //this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
  //this.authService.IsShowLoader(true);

  constructor(private route: ActivatedRoute, private router: Router, private context: DataService, private authService: AuthService, private datepipe: DatePipe, private signalr: SignalrService) {
    //constructor(private route: ActivatedRoute, private router: Router, private context: DataService, private authService: AuthService) {

    // this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    // this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

   }

  ngOnInit(): void {

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);



    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    
    //debugger;
    //this.signalr.startConnection();

    // let isThoughtOpen = this.authService.getThoughtOpen();

    // if(!isThoughtOpen){
    //   $('#myModal').show();
    //   this.authService.setThoughtOpen();
    // }


    // (function ($) {
    //   // $(document).ready(function(){
    //   //   $('#myModal').show();
    //   // });

    //   $('.close').click(function(){
    //     $('#myModal').hide();
    //   });
    // })(jQuery);

    this.authService.ScrollOverflow();
    this.currentUser = this.authService.currentUserValue;
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
      }

    this.GetDashboard();

   
    
    this.modal = new Modal();

      this.modal.IsAlertModalShow = true;
      this.modal.Title = "Attachment";

        this.modal.IsPLANTEXT = true;

        this.modal.PlainText = "Please, complete previous task first.";

      this.authService.IsShowModal(this.modal);

  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  // IsShowLoader(isShowLoader: boolean) {
  //   this.authService.IsShowLoader(isShowLoader);
  // }

  GetDashboard(){
    //this.loaderPlay = true;    
    this.authService.IsShowLoader(true);

    this.context.GET("GetDashboardData?StudentUserId="+this.currentUser.id).subscribe(data => {
      this.response=data;

      if(this.response.status_code == 1){
          this.thoughOfDay = this.response.data1;
          this.courseList = this.response.data2;
          this.courseList = this.courseList.filter(a => a.IsTaken == true);

        if(this.courseList!= null){
          if(this.courseList.length == 0){
            this.courseList = null;
          }
        }

          let CurrentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

          let OldTODDate = localStorage.getItem('TOD');

          if(OldTODDate == null){
            localStorage.setItem('TOD',CurrentDate);

            this.ShowTODModal();
          }

          if (CurrentDate != OldTODDate) {
            localStorage.setItem('TOD',CurrentDate);
            this.ShowTODModal();
          }

          this.authService.IsShowLoader(false);

          //this.ShowTODModal();
      }

      //this.loaderPlay = false;

    });
  }


  StudentBuyCourse(courseId: number){
      // this.context.StudentBuyCourse(courseId).subscribe(data => {
      //   this.response=data;
      //   if(this.response.status_code == 1){

      //   }
      // });

      // this.router.navigate(['/course/payment/', { courseId: courseId, StudentUserId: this.currentUser.id }]);
      this.router.navigate(['/course/payment/',courseId]);
      //window.location.href = "/course/payment/"+courseId;
  }

  isCourseBuy(mycourse){
    if(mycourse == null){
      return false;
    }
    else if(mycourse.length == 0){
      return false;
    }
    else{
      return true;
    }
  }

  ThreadImageAvailable(ThreadAttachment:string){
      if(ThreadAttachment != null && ThreadAttachment != ""){
          return true;
      }
      else{
        return false;
      }
  }

  ShowTODModal(){
          this.modal = new Modal();

          this.modal.IsModalShow = true;
          this.modal.Title = "Thought of the day";
          
          //this.thoughOfDay.AttachmentPath = "http://admin.flagshipdesignacademy.in//Uploads/Course/Course_Vue_637459315406357325.png";

          if(this.thoughOfDay != null && this.thoughOfDay.AttachmentPath != null && this.thoughOfDay.AttachmentPath != ''){
            this.modal.IsInnerHTML = true;
              this.modal.Body = this.thoughOfDay.AttachmentPath;

          }

          if(this.thoughOfDay != null && this.thoughOfDay.Message != null) {
            this.modal.IsPLANTEXT = true;
            this.modal.PlainText = this.thoughOfDay.Message;
          }

          this.authService.IsShowModal(this.modal);
  }
  }
