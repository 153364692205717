import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { Discussion } from 'src/app/models/discussion';
import { FileToUpload } from 'src/app/models/file-to-upload';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.css']
})

export class AskQuestionComponent implements OnInit {

  loaderPlay = false;
  response : ApiResponse = null;
  listStudent = [];
  listTeacher = [];
  listUser = [];
  discussionModel = new Discussion();
  currentUser = new User();
  fileList: [];
  discussionForm: FormGroup;
  fileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();

  IsShowUsers: boolean = false;
  
  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private context: DataService, private authService: AuthService,private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    
    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "/home" } });
    }

    this.GetData();

    this.discussionForm = this.formBuilder.group({
      threadName: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      threadDetails: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      threadOption: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      attachments: ['']
    });

  }

  GetData(){
    this.authService.IsShowLoader(true);
    this.context.GET("GetAllStudentandTeacher").subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
        this.listStudent = this.response.data1;
        this.listTeacher = this.response.data2;

        //this.loaderPlay = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  onFileChange(event) {

    this.authService.IsShowLoader(true);
    //this.fileUploads = [];
    let count = 0;

    if(this.fileUploads != null){
      count = this.fileUploads.length;
    }

      if (event.target.files && event.target.files.length > 0) {
        this.fileList = event.target.files;
          for  (var i =  0; i < event.target.files.length; i++)  {
            // Don't allow file sizes over 1MB
            //if (event.target.files[i].size < MAX_SIZE) {
              // Set theFile property

              count++;
              this.fileUpload = new FileToUpload();
              this.fileUpload.fileId = String(count);
              this.fileUpload.fileSize = event.target.files[i].size;
              this.fileUpload.fileName = event.target.files[i].name;
              this.fileUpload.file = event.target.files[i];

              this.fileUploads.push(this.fileUpload);
            // }
            // else {
            //     // Display error message
            //     //this.messages.push("File: " + event.target.files[i].name + " is too large to upload.");
            //     alert("File: " + event.target.files[i].name + " is too large to upload.(2MB)");
            //     this.fileList = null;
            //     return false;
            // }
          }
      }

      this.authService.IsShowLoader(false);
  }

  removeFile(fileId: string){
    for  (var i =  0; i < this.fileUploads.length; i++)  {
      if (this.fileUploads[i].fileId === fileId)
        this.fileUploads.splice(i, 1);
    }
  }





onOptionsSelected(event){
    const value = event.target.value;

    if(value == "GroupWise"){
      this.IsShowUsers = true;
    }
    else{
      this.IsShowUsers = false;
    }
}

onStudentSelected(event){
  const value = event.target.value;
}

onTeacherSelected(event){
  const value = event.target.value;
}

  onSubmit() {
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);

    const formData =  new  FormData();

    for  (var i =  0; i < this.fileUploads.length; i++)  {
        formData.append("file"+i,  this.fileUploads[i].file);
    }

    this.discussionModel.ThreadName = this.discussionForm.get('threadName').value;
    this.discussionModel.ThreadDetails = this.discussionForm.get('threadDetails').value;
    this.discussionModel.UserId = this.currentUser.id;

    if(this.discussionForm.get('threadOption').value == "Public"){
      this.discussionModel.IsPublic = true;
      this.discussionModel.IsPrivate = false;
      this.discussionModel.IsGroupWise = false;
    }
    else if(this.discussionForm.get('threadOption').value == "Private"){
      this.discussionModel.IsPublic = false;
      this.discussionModel.IsPrivate = true;
      this.discussionModel.IsGroupWise = false;
    }
    else{
      this.discussionModel.IsPublic = true;
      this.discussionModel.IsPrivate = false;
      this.discussionModel.IsGroupWise = false;
    }


    formData.append('objViewSTR', JSON.stringify(this.discussionModel));

    if(this.discussionModel.ThreadName != null && this.discussionModel.ThreadName.length > 0)
    { 
    this.context.POSTWITHATTACHMENTS("InsertDiscussionDetails", formData).subscribe(data => {
      this.response=data;

      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){

        //this.router.navigate(['/thread/'], { queryParams: { DiscussionId: this.response.data1 } });
        //this.loaderPlay = false;
        window.location.href = "/thread/"+this.response.data1;
      }
    });
  }
  else{
    return false;
  }
  }

}
