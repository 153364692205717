export class Notification {

    Title : string;
            Message : string;
            Type : string;
            IsSend : boolean;
            StudentIds : string;
            CreatedUserName : string;
            IsActive : boolean;
            CreatedBy : number;
            CreatedDate : any;
            UpdatedBy : number;
            UpdatedDate : any;
            ModuleId? : number;
            CourseId? : number;
            CreatedDateTimeTicks? : number;
}
