export class Modal {
  IsModalShow: boolean = false;
  IsAlertModalShow: boolean = false;
          Title: string = "";
          Body: string = "";
          AttachmentPath: string = "";
          IsInnerHTML: boolean = false;
          IsPLANTEXT: boolean = false;
          PlainText: string = "";
}
