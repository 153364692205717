<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>



<button id="btnTaskCompleteNotify" type="button" style="display: none;" (click)="GetCourseDetails(currentUser.id, courseId)">NotifyTaskComplete</button>
<div class="mdk-drawer-layout__content page " style="padding-top: 10px !important;">
  <div class="container">
    <div>
      <h2>Course Details</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-2">
            <div class="embed-responsive embed-responsive-16by9">
              <img src="{{course.CourseImagePath}}" class="embed-responsive-item" alt="Not found">
            </div>
          </div>
          <div class="col-md-10">
            <h4 class="card-title">{{course.CourseName}}</h4>
          </div>
        </div>
        <hr/>

        <div class="row">
          <div class="col-md-12 pr-0">
            <h1 class="h4">Modules  &nbsp; <small><span class="text-dark">{{course.ModuleComplete}} / {{course.TotalModule}} </span></small></h1>
            <div class="card">
              <div class="card-body p-0">
                <div class="">
                  <!-- Accordion -->
                  <div id="accordionExample" class="accordion">

                    <!-- Accordion item 1 -->
                    <div class="card my-0" *ngFor="let modules of course.Modules;let i = index">
                      <div id="headingOne" class="card-header bg-white shadow-sm border-0">

                        <h6 class="mb-0 font-weight-bold"><a href="#" data-toggle="collapse"
                            [attr.data-target]="'#collapse' +i" [attr.aria-controls]="'#collapse' +i" [attr.aria-expanded]="moduleIdExpanded === modules.Id ? true : false"
                            class="d-block position-relative text-dark text-uppercase collapsible-link py-2" style="text-decoration: none;padding-right: 15%;"> {{modules.ModuleName}} &nbsp;
                            <span class="badge badge-primary">{{modules.PercentageComplete}}%</span>
                            <!-- <div class="media">
                              <div class="media-left">
                                  <div class="text-muted">{{modules.ModuleName}} &nbsp;</div>
                              </div>
                              <div class="media-body">
                                <div class="media">
                                  <div class="media-left">
                                    <div class="progress rounded-0">
                                      <div class="progress-bar progress-bar-striped bg-primary" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                              </div>
                              </div>
                          </div> -->

                            <!-- <div class="media-left">
                              {{modules.ModuleName}} &nbsp;
                            </div> -->
                            <!-- <div class="media-body">
                              <div class="progress rounded-0">
                                <div class="progress-bar progress-bar-striped bg-primary" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div> -->
                          </a>
                        </h6>
                      </div>
                      <div [attr.id]="'collapse' +i" aria-labelledby="headingOne" data-parent="#accordionExample"
                        [ngClass]="moduleIdExpanded === modules.Id ? 'collapse show' : 'collapse'">
                        <div class="card-body p-4">
                          <p class="font-weight-light m-0" [innerHTML]="modules.Details" ></p>
                          <br />
                          <!-- <p><b>Tasks</b></p> -->
                          <p><b>Chapters</b></p>
                          <ul class="list-group list-group-fit mb-0" *ngFor="let task of modules.Tasks">
                            <a href="javascript: void(0);" (click)="TaskClickEvent(task.Id, modules.Id, course.Id)">
                            <li *ngIf="task.InProccess === true" class="list-group-item mt-2 accordion bg-white shadow-sm border-1" (click)="TaskClickEvent(task.Id, modules.Id, course.Id)">
                                  <div class="media align-items-center row">
                                    <div class="media-left col-1">
                                        <div class="text-center">
                                          <i class="fas fa-clock" style="color: orange;"></i>
                                        </div>
                                    </div>
                                    <div class="media-body col-11 col-lg-9">
                                      <a [routerLink]="['/task',task.Id, modules.Id, course.Id]" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                    </div>
                                    <div class="media-right col-12 col-lg-2">
                                        <div class="text-left ">
                                            <span class="badge badge-pill badge-success">Start</span>
                                        </div>
                                    </div>
                                </div>
                              </li>
                            </a>
                            <a href="javascript: void(0);" (click)="TaskClickEvent(task.Id, modules.Id, course.Id)">
                            <li *ngIf="task.IsCompleted === true" class="list-group-item mt-2 accordion bg-white shadow-sm border-1" (click)="TaskClickEvent(task.Id, modules.Id, course.Id)">
                                <div class="media align-items-center row">
                                    <div class="media-left col-1">
                                      <div class="text-center">
                                        <i class="fas fa-check-circle" style="color: green;"></i>
                                      </div>
                                    </div>
                                    <div class="media-body col-11 col-lg-9">
                                      <a href="javascript:void(0);" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                    </div>
                                    <div class="media-right col-12 col-lg-2">
                                        <div class="text-left">
                                            <span class="badge badge-pill badge-default">Obtained Marks: {{task.Mark}}</span>
                                        </div>
                                    </div>
                                </div>
                              </li>
                            </a>
                            <!-- <li *ngIf="task.IsHidden === true" class="list-group-item mt-2 accordion bg-white shadow-sm border-1" onclick="alert('Please, complete previous task first.');"> -->
                              <li *ngIf="task.IsHidden === true" class="list-group-item mt-2 accordion bg-white shadow-sm border-1" [swal]="['Oops!', 'Please, complete previous task first.', 'error']">
                                <div class="media align-items-center row">
                                    <div class="media-left col-1">
                                      <div class="text-center">
                                        <i class="fas fa-exclamation-circle" style="color: blue;"></i>
                                      </div>
                                    </div>
                                    <div class="media-body col-11 col-lg-9">
                                      <a href="javascript: void(0);" (click)="ShowAlertModal()" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                    </div>

                                </div>
                              </li>
                          </ul>
                          <br />
                          <ul class="list-group list-group-fit mb-0" *ngIf="modules.Exams != null">
                            <li class="list-group-item mt-2 accordion bg-white shadow-sm border-1" *ngFor="let exam of modules.Exams; let last = last;">
                              <div class="media align-items-center row">
                                  <div class="media-left col-1">
                                    <div class="text-center">
                                      <i *ngIf="(exam.IsCompleted === false && exam.IsApproved === false && exam.IsPass === false)" class="fas fa-exclamation-circle" style="color: blue;"></i>
                                      <i *ngIf="(exam.IsCompleted === true && exam.IsApproved === true && exam.IsPass === false)" class="fas fa-exclamation-circle" style="color: #ffc107;"></i>
                                      <i *ngIf="(exam.IsCompleted === true && exam.IsApproved === true && exam.IsPass === true)" class="fas fa-check-circle" style="color: green;"></i>
                                    </div>
                                  </div>
                                  <div class="media-body col-11 col-lg-9">
                                    <a *ngIf="exam.IsPass === false && !last" [routerLink]="['/result/exam-result/', exam.ExamId,modules.Id,courseId]" class="text-body"><strong>{{exam.Name}} &nbsp; </strong> </a>

                                    <a *ngIf="exam.IsPass === false && last" [routerLink]="['/exam/take-exam/', exam.ExamId,modules.Id,courseId]" class="text-body"><strong>{{exam.Name}} &nbsp; </strong>
                                      <span *ngIf="(exam.IsCompleted === true && exam.IsApproved === true && exam.IsPass === false && last)" class="badge badge-pill badge-warning">Re-Appear </span></a>
                                  <a *ngIf="exam.IsPass === true" [routerLink]="['/result/exam-result/', exam.ExamId,modules.Id,courseId]" class="text-body"><strong>{{exam.Name}}</strong></a>
                                  </div>
                                  <div class="media-right col-12 col-lg-2" *ngIf="(exam.IsCompleted === true && exam.IsApproved === true)">
                                      <div class="text-left">
                                          <span class="badge badge-pill badge-default">Obtained Marks: {{exam.ContainMarks}}</span>
                                      </div>
                                  </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<style>
  .text-wrap {
    word-break: break-word !important;
  }

  .border-bottom {
    border-bottom: 1px solid !important;
  }

  .pr-0{
    padding-right:0px !important;
  }

  /* .list-group-item:hover{
    background: #2196f3!important;
  } */
</style>
