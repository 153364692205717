import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/account/login/login.component';
import { CourseComponent } from './components/course/course.component';
import { CourseDetailsComponent } from './components/course/details/details.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { ExamComponent } from './components/exam/exam.component';
import { TakeExamComponent } from './components/exam/take-exam/take-exam.component';
import { HomeComponent } from './components/home/home.component';
import { PlacementComponent } from './components/placement/placement.component';
import { ExamResultComponent } from './components/result/exam-result/exam-result.component';
import { ResultComponent } from './components/result/result.component';
import { MasterComponent } from './components/shared/master/master.component';
import { StationaryComponent } from './components/stationary/stationary.component';
import { StudentComponent } from './components/student/student.component';
import { TaskComponent } from './components/task/task.component';
import { ThreadComponent } from './components/thread/thread.component';
import { RoutesLayout } from './routes-layout';
import { AuthGuard } from './auth.guard';
import { CertificateComponent } from './components/certificate/certificate.component';
import { SampleComponent } from './components/sample/sample.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'sample', component: SampleComponent },
  { path: '', component: MasterComponent, data: { title: 'full Views' }, children: RoutesLayout, canActivate : [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
