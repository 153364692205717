export class Discussion {
    Id: number;
    UserId: number;
    ThreadName?: string;
    ThreadDetails?: string;
    ThreadOption?: string;
    IsPublic: boolean;
    IsGroupWise: boolean;
    IsPrivate: boolean;
    
    strStudentIds?: string;
    strTeacherIds?: string;
}
