<div class="mdk-drawer-layout__content page ">

    <div class="row m-0">
        <div class="col-lg container-fluid page__container">

            <div class="d-flex flex-column flex-sm-row flex-wrap mb-headings align-items-start align-items-sm-center">
                <div class="flex mb-2 mb-sm-0">
                    <h1 class="h2">My Courses</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 align-items-center">
                    <div class="clearfix"></div>

                    <ng-container *ngIf="isCourseBuy(listCourseTaken); then Taken else NotTaken"></ng-container>
                    <ng-template #NotTaken>
                        <div class="card border-left-3 border-left-primary card-2by1 col-md-12">
                            <div class="card-body">
                                <div class="media flex-wrap align-items-center">
                                    <div class="media-body">
                                        You have not purchased any course or approval is pending. Please contact the administrator.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #Taken>
                        <div class="row">
                            <div class="col-lg-4 d-flex pb-3" *ngFor="let course of listCourseTaken;let i = index">
                                <div class="card card-block card-course border-1 w-100">

                                    <img src="{{course.CourseImagePath}}" alt="image" class="card-img-top">

                                    <div class="card-header">
                                        <h3 class="card-title">
                                            <strong>
                                                <!-- <a [routerLink]="['/course/details/',course.Id]" class="text-dark" title="{{course.CourseName}}">{{course.CourseName}}</a> -->
                                                <a href="javascript: void(0);" class="text-dark" title="{{course.CourseName}}">{{course.CourseName}}</a>
                                            </strong>
                                        </h3>
                                        <p class="card-title">
                                            <p5>
                                                Fee : {{course.Fees}}
                                            </p5>
                                        </p>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-flex align-items-end my-2">
                                            <div class="d-flex flex flex-column mr-3">
                                                <div class="d-flex align-items-center mb-2">
                                                    <i class="material-icons mr-1">assignment</i>
                                                    <div class="flex">Module: {{course.TotalModule}}</div>
                                                </div>
                                            </div>
                                            <div class="text-center">

                                                <!-- <div *ngIf="(course.IsTaken==true); then Taken else NotTaken"></div>
                                                <ng-template #Taken>
                                                    <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-primary">Continue &nbsp;
                                                        <i class="material-icons btn__icon--right">play_circle_outline</i></a>
                                                </ng-template>
                                                <ng-template #NotTaken>
                                                    <a [routerLink]="['/course/payment/', course.Id]" class="btn btn-sm btn-primary">Buy</a>
                                                </ng-template> -->

                                                <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-success" *ngIf="(course.IsCompleted==true)">Completed &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-primary" *ngIf="(course.IsTaken==true && course.IsApproved==true && course.IsCompleted!=true)">Continue &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a href="javascript: void(0);" class="btn btn-sm btn-warning" *ngIf="(course.IsTaken==true && course.IsApproved==false)">Pending for approval &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a [routerLink]="['/course/payment/', course.Id]" class="btn btn-sm btn-primary" *ngIf="(course.IsTaken==false && course.IsApproved==false)">Buy</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>



                </div>
            </div>

            <div class="d-flex flex-column flex-sm-row flex-wrap mb-headings align-items-start align-items-sm-center">
                <div class="flex mb-2 mb-sm-0">
                    <h1 class="h2">Popular Courses</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 align-items-center">
                    <div class="clearfix"></div>



                    <ng-container *ngIf="listCourseBuy != null && listCourseBuy.length > 0; then CourseBuy else NotCourseBuy"></ng-container>
                    <ng-template #NotCourseBuy>
                        <div class="card border-left-3 border-left-primary card-2by1 col-md-12">
                            <div class="card-body">
                                <div class="media flex-wrap align-items-center">
                                    <div class="media-body">
                                        Do not have any course available now.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #CourseBuy>
                        <div class="row">
                            <div class="col-lg-4 d-flex pb-3" *ngFor="let course of listCourseBuy;">
                                <div class="card card-block card-course border-1 w-100">

                                    <img src="{{course.CourseImagePath}}" alt="image" class="card-img-top p-1">

                                    <div class="card-header">
                                        <h3 class="card-title">
                                            <strong>
                                                <!-- <a [routerLink]="['/course/details/',course.Id]" class="text-dark" title="{{course.CourseName}}">{{course.CourseName}}</a> -->
                                                <a href="javascript: void(0);" class="text-dark" title="{{course.CourseName}}">{{course.CourseName}}</a>
                                            </strong>
                                        </h3>
                                        <p class="card-title">
                                            <p5>
                                                Fee : {{course.Fees}}
                                            </p5>
                                        </p>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-flex align-items-end my-2">
                                            <div class="d-flex flex flex-column mr-3">
                                                <div class="d-flex align-items-center mb-2">
                                                    <i class="material-icons mr-1">assignment</i>
                                                    <div class="flex">Module: {{course.TotalModule}}</div>
                                                </div>
                                            </div>
                                            <div class="text-center">

                                                <!-- <div *ngIf="(course.IsTaken==true); then Taken else NotTaken"></div>
                                            <ng-template #Taken>
                                                <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-primary">Continue &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i></a>
                                            </ng-template>
                                            <ng-template #NotTaken>
                                                <a [routerLink]="['/course/payment/', course.Id]" class="btn btn-sm btn-primary">Buy</a>
                                            </ng-template> -->
                                                <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-success" *ngIf="(course.IsCompleted==true)">Completed &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a [routerLink]="['/course/details/',course.Id]" class="btn btn-sm btn-primary" *ngIf="(course.IsTaken==true && course.IsApproved==true)">Continue &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a href="javascript: void(0);" class="btn btn-sm btn-warning" *ngIf="(course.IsTaken==true && course.IsApproved==false)">Pending for approval &nbsp;
                                                    <i class="material-icons btn__icon--right">play_circle_outline</i>
                                                </a>
                                                <a [routerLink]="['/course/payment/', course.Id]" class="btn btn-sm btn-primary" *ngIf="(course.IsTaken==false && course.IsApproved==false)">Buy</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>
    </div>
</div>