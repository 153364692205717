import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/models/api-response';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Exam } from 'src/app/models/exam';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.css']
})

export class ExamResultComponent implements OnInit {

  response: ApiResponse;

  loaderPlay = false;
  examId: number;
  moduleId: number;
  courseId: number;

  public examModel = new Exam();
  listExamResult: Exam[] = [];
  currentUser: User = null;
  errorMsg: string;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,private context: DataService, private spinner: NgxSpinnerService, private authService: AuthService, private router: Router) {
    this.examId = this.activatedRoute.snapshot.params.ExamId;
    this.moduleId = this.activatedRoute.snapshot.params.ModuleId;
    this.courseId = this.activatedRoute.snapshot.params.CourseId;

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);
  }

  ngOnInit(): void {
    this.authService.ScrollOverflow();
    //this.loaderPlay = true;
    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }



    (function ($) {
      $(document).ready(function(){

      var connection = $.hubConnection(environment.API_Host, '', false, {xdomain: true});

      var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);

      hubProxy.on('receiveExamChecked', function(message) {
        $("#btnExamNotify").click();

        });

      connection.start().then(function () {
        }).catch(function (err) {
            return console.error(err);
        });


      connection.disconnected(function () {
          connection.start();
      });

    });
  })(jQuery);

  this.GetExamDetails();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }



  GetExamDetails(){

    this.authService.IsShowLoader(true);

    this.context.GET("GetExamResult?StudentUserId="+this.currentUser.id+"&ExamId="+this.examId+"&ModuleId="+this.moduleId+"&CourseId="+this.courseId).subscribe(data => {

      this.response=data;

      if(this.response.status_code == 1){
        if(this.response.data1 != null){
          this.listExamResult = this.response.data1;
          this.examModel = this.listExamResult[0];
        }
      }

      this.authService.IsShowLoader(false);
    });
  }

  GetExamNotify(){
    this.GetExamDetails();
  }
}
