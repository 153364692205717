<!-- <div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
    <img src="assets/images/imageLoading.gif" alt="Alt Image" style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;"
    />
</div> -->





<div class="container-fluid page__container">


    <div class="row">
        <ng-container *ngIf="(examModel.IsCompleted == true && examModel.IsApproved == true && examModel.IsPass == true);then Completed else NotAttend"></ng-container>
        <ng-template #Completed>

            <div class="card-header" style="width: 100%;">
                <div class="media">
                    <div class="media-left">
                        <h4 class="card-title">You are Completed this exam.</h4>
                        <p class="card-subtitle">Date: {{examModel.CompleteDateTime}}</p>
                    </div>
                    <div class="media-body"></div>
                    <div class="media-right">
                        <a class="btn btn-primary" [routerLink]="['exam']">Back</a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #NotAttend>

            <div class="col-12 col-lg-8">
                <div class="card">
                    <from name="formQuestion">
                        <div class="card-header">
                            <div class="media align-items-center">
                                <div class="media-left">
                                    <h4 class="mb-0">
                                        <strong>{{questionModel.QuestionNo}}.</strong>
                                    </h4>
                                </div>
                                <div class="media-body">
                                    <h4 class="card-title">
                                        {{questionModel.Title}}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <ng-container *ngIf="(questionModel.Type == 'MultipleChoice');then MultipleChoice else NotMultipleChoice"></ng-container>
                            <ng-template #MultipleChoice>
                                <div class="form-group" *ngFor="let option of questionModel.Options; let i = index" [attr.data-index]="i">
                                    <div class="custom-control custom-radio">
                                        <ng-container *ngIf="questionModel.Answer === option; then Selected; else NotSelected"></ng-container>
                                        <ng-template #Selected>
                                            <input id="radio-{{i}}" name="radio-option" [(ngModel)]="questionModel.Answer" value="{{option}}" type="radio" class="custom-control-input"
                                                checked>
                                        </ng-template>
                                        <ng-template #NotSelected>
                                            <input id="radio-{{i}}" name="radio-option" [(ngModel)]="questionModel.Answer" value="{{option}}" type="radio" class="custom-control-input">
                                        </ng-template>
                                        <label for="radio-{{i}}" class="custom-control-label">{{option}}</label>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #NotMultipleChoice>
                                <div class="list-group list-group-fit">
                                    <div class="list-group-item">
                                        <div role="group" aria-labelledby="label-question" class="m-0 form-group">
                                            <div class="form-row">
                                                <label id="label-question" for="questionModel.Answer" class="col-md-3 col-form-label form-label">Your Answer</label>
                                                <div class="col-md-9">
                                                    <textarea name="questionModel.Answer" [(ngModel)]="questionModel.Answer" placeholder="Describe your answer in detail ..."
                                                        rows="4" class="form-control" required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="form-group m-0" role="group" aria-labelledby="label-topic">
                                            <div class="form-row align-items-center">
                                                <label id="label-topic" for="questionModel.Attachments" class="col-md-3 col-form-label form-label">Attachments</label>
                                                <div class="col-md-9">
                                                    <span class="btn btn-success fileinput-button">
                                                        <span>Select Attachment</span>
                                                        <input type="file" multiple name="attachments" id="attachments" (change)="onFileChange(questionModel.QuestionId,$event)" accept="image/jpeg, image/png, image/gif,application/pdf">
                                                        <br />
                                                    </span>
                                                    <br />
                                                    <ul class="TaskFilesList">
                                                        <ng-container *ngIf="tempFileUploads != null; then Available; else NotAvailable"></ng-container>
                                                        <ng-template #Available>
                                                            <li *ngFor="let attachment of tempFileUploads">
                                                                <strong>{{attachment.fileName}}</strong> - {{attachment.fileSize}}
                                                                bytes. &nbsp; &nbsp;
                                                                <a href="javascript: void(0);" (click)="removeFile(questionModel.QuestionId, attachment.fileId)" class="removeFile">Remove</a>
                                                            </li>
                                                        </ng-template>
                                                        <ng-template #NotAvailable>
                                                        </ng-template>

                                                    </ul>

                                                    <output id="Filelist"></output>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- <span>Answer : {{questionModel.Answer}}</span> -->
                        </div>
                        <div class="card-footer">
                            <ng-container *ngIf="(questionModel.NextQuestionId > 0);then AvailablePrevious else NotAvailablePrevious"></ng-container>
                            <ng-template #AvailablePrevious>
                                <!-- <a href="javascript: void(0);" (click)="PreviousQuestion(questionModel.PreviousQuestionId)" class="btn btn-outline-dark">Previous</a> -->
                                <a href="javascript: void(0);" (click)="SaveAnswer(questionModel.QuestionId, questionModel.NextQuestionId)" class="btn btn-success">Next</a>
                            </ng-template>
                            <ng-template #NotAvailablePrevious>
                                <!-- <button type="submit" class="btn btn-primary float-right mb-3" (click)="onSubmitExam()">Submit Exam</button> -->
                                <button type="submit" class="btn btn-primary float-right mb-3"
                                [swal]="{ title: 'Are you sure to submit Exam?', showDenyButton: false, showCancelButton: true }"
                                        (confirm)="onSubmitExam()">Submit Exam</button>
                            </ng-template>



                            <!-- <ng-container *ngIf="(questionModel.NextQuestionId > 0);then AvailableNext else NotAvailableNext"></ng-container>
                        <ng-template #AvailableNext>
                            <a href="javascript: void(0);" (click)="SaveAnswer(questionModel.QuestionId, questionModel.NextQuestionId)" class="btn btn-success float-right">Save & Next</a>
                        </ng-template>
                        <ng-template #NotAvailableNext>
                            <button type="submit" class="btn btn-primary float-right">Submit</button>
                        </ng-template> -->
                        </div>
                    </from>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card-header">
                    <div class="media-body">
                        <h4 class="card-title">
                            Questions
                        </h4>
                    </div>
                    <ul class="list-group mb-0 list-group-fit" style="overflow-y: auto; max-height: 600px;">
                        <li class="list-group-item" *ngFor="let question of examModel.Questions">
                            <a href="javascript: void(0);" (click)="ChangeQuestion(question.QuestionNo)" style="outline: none; text-decoration: none;">
                                <div class="media">
                                    <div class="media-left">
                                        <div class="text-muted-light">{{question.QuestionNo}}</div>
                                    </div>
                                    <div class="media-body">{{question.Title}}</div>
                                    <div class="media-right">
                                        <strong class="text-primary">{{question.Marks}}</strong>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-template>

    </div>

</div>