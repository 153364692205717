import { FileToUpload } from "./file-to-upload";

export class Question {
    ExamId: number;
    QuestionNo:number;
    QuestionId:number;
    PreviousQuestionId: number;
    NextQuestionId: number;
    Title: string;
    Type: string;
    Option1: string;
    Option2: string;
    Option3: string;
    Option4: string;
    Options?: string[];
    Answer?: string;
    Marks?: string;
    AttchmentPathList?: [];
    SubmitAttachments?: FileList;
    Answered: boolean;
    Attachments: FileToUpload[] = [];
}
