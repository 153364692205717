import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response';
import{DataService} from "../../../services/data.service";
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Task } from 'src/app/models/task';
import { FileToUpload } from 'src/app/models/file-to-upload';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Course } from 'src/app/models/course';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Modal } from 'src/app/models/modal';
declare var jQuery: any;




// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class CourseDetailsComponent implements OnInit {

  loaderPlay = false;
  response : ApiResponse = null;
  courseId;
  ModuleList = [];
  course = new Course();
  attchmentPath;
  baseUrlPath = this.data.API_URL.split("/api")[0]+"/";
  isCourseAailable = false;

  currentUser = new User();

  taskId: number;
  moduleId: number;
  moduleIdExpanded: number;
  taskForm: FormGroup;
  public taskModel = new Task();

  modal = new Modal();

  fileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();

  imageUrl: string | ArrayBuffer;


  IsLoaderShow:boolean;
  subscription: Subscription;


  constructor(private activatedRoute: ActivatedRoute, private router: Router,private formBuilder: FormBuilder,private data:DataService, private authService: AuthService) {
    this.courseId = this.activatedRoute.snapshot.params.CourseId;

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);

    }

  ngOnInit(): void {
    this.authService.IsShowLoader(true);

    this.imageUrl = this.authService.FillEmptyImage(false);
    this.course.CourseImagePath = this.imageUrl;

    //this.loaderPlay = true;

    this.currentUser = this.authService.CurrentUser();
    this.authService.ScrollOverflow();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }

    this.authService.IsShowLoader(true);

    this.GetCourseDetails(this.currentUser.id,this.courseId);



    (function ($) {
      $(document).ready(function(){
        var connection = $.hubConnection(environment.API_Host, '', false, {xdomain: true});

        var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);

        hubProxy.on('receiveTaskComplete', function(message) {
          $('#notificationtune').get(0).play()
          $("#btnTaskCompleteNotify").click();

          });

        connection.start().then(function () {
          }).catch(function (err) {
            return console.error(err.toString());
        });

      });
    })(jQuery);

    // this.data.GetCourseDetails(this.currentUser.id,this.courseId).then(res => {

    //   this.course = res.data1;
    //   if(this.course != null){this.isCourseAailable = true}
    //   this.attchmentPath = res.data1.Attachment == null ? this.baseUrlPath+"Uploads/No-image-found.jpg" : this.baseUrlPath+res.data1.Attachment.FilePath;


    //   if(this.course.Modules != null && this.course.Modules.length > 0){
    //     this.moduleIdExpanded = 0;
    //     for  (var i = 0; i < this.course.Modules.length; i++)  {
    //           if(this.moduleIdExpanded == 0){
    //             if(this.course.Modules[i].IsCompleted == false){
    //               this.moduleIdExpanded = this.course.Modules[i].Id;
    //             }
    //           }
    //           else{
    //             break;
    //           }
    //       }
    //   }

    //   console.log(this.course);
    //   //this.loaderPlay = false;

    //   this.authService.IsShowLoader(false);
    // });


    this.taskForm = this.formBuilder.group({
      comment: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      attachments: ['']
    });

  }


  TaskClickEvent(taskId: number, moduleId: number, courseId: number)
  {
    //this.router.navigate(['/task', { TaskId: taskId, ModuleId: moduleId, CourseId: courseId }]);

    window.location.href = "/task/"+taskId+"/"+moduleId+"/"+courseId;
    // let obj = { StudentUserId: this.currentUser.id, TaskId: Number(taskId), CourseId: Number(courseId), ModuleId: Number(moduleId) }

    // this.GetTaskDetails(obj);
  }

 ChangeTask(taskId:number, moduleId:number, courseId:number){
  this.taskId = taskId;
  this.moduleId = moduleId;
  this.courseId = courseId;

  let obj = { StudentUserId: this.currentUser.id, TaskId: Number(this.taskId), CourseId: Number(this.courseId), ModuleId: Number(this.moduleId) }

  this.GetTaskDetails(obj);
 }

  GetTaskDetails(obj: any){
    //this.loaderPlay = true;
  this.authService.IsShowLoader(true);

    this.data.POST("GetTaskDetails/", obj).subscribe(data => {

      this.response=data;
      //this.loaderPlay = false;
      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){
          this.taskModel = this.response.data1;
          this.taskModel.ModuleId = this.moduleId;
          this.taskModel.CourseId = this.courseId;
          this.taskModel.StudentUserId = this.currentUser.id;

      if(this.taskModel.Attachments != null){
        for  (var i = 0; i < this.taskModel.Attachments.length; i++)  {
              // var name = this.taskModel.Attachments.Name.
          }
      }

      }
    });
    //this.loaderPlay = false;
  //this.authService.IsShowLoader(true);
  }

  onFileChange(event) {

  //this.fileUploads = [];
  let count = 0;

  if(this.fileUploads != null){
    count = this.fileUploads.length;
  }

    if (event.target.files && event.target.files.length > 0) {
        for  (var i =  0; i < event.target.files.length; i++)  {
          // Don't allow file sizes over 1MB
          //if (event.target.files[i].size < MAX_SIZE) {
            // Set theFile property
            count++;
            this.fileUpload = new FileToUpload();
            this.fileUpload.fileId = String(count);
            this.fileUpload.fileSize = event.target.files[i].size;
            this.fileUpload.fileName = event.target.files[i].name;
            this.fileUpload.file = event.target.files[i];

            this.fileUploads.push(this.fileUpload);
          // }
          // else {
          //     // Display error message
          //     //this.messages.push("File: " + event.target.files[i].name + " is too large to upload.");
          //     alert("File: " + event.target.files[i].name + " is too large to upload.(2MB)");
          //     return false;
          // }
        }
    }

}


removeFile(fileId: string){
  for  (var i =  0; i < this.fileUploads.length; i++)  {
    if (this.fileUploads[i].fileId === fileId)
      this.fileUploads.splice(i, 1);
  }
}


onSubmit() {
  //this.loaderPlay = true;

  this.authService.IsShowLoader(true);

  const formData =  new  FormData();

  for  (var i =  0; i < this.fileUploads.length; i++)  {
      formData.append("file"+i,  this.fileUploads[i].file);
  }

  this.taskModel.Comment = this.taskForm.get('comment').value;
  this.taskModel.TaskId = this.taskId;
  this.taskModel.StudentUserId = this.currentUser.id;

  formData.append('objViewSTR', JSON.stringify(this.taskModel));

  this.data.POSTWITHATTACHMENTS("InsertTaskDetails", formData).subscribe(data => {
    this.response=data;
    this.authService.IsShowLoader(false);
    if(this.response.status_code == 1){

      alert("Task data is submitted.");
      // console.log(this.response.data1);
        this.taskModel = this.response.data1;
        this.taskModel.ModuleId = this.moduleId;
        this.taskModel.CourseId = this.courseId;
        this.taskModel.StudentUserId = this.currentUser.id;
    }
  });
  //this.loaderPlay = false;
}


  StudentBuyCourse(courseId: number){
    this.router.navigate(['/course/payment/', { courseId: courseId }]);
  }


  GetCourseDetails(currentUserId, courseId){
      
    this.authService.IsShowLoader(true);

    this.data.GetCourseDetails(currentUserId, courseId).then(res => {
      this.course = res.data1;

      if(this.course != null){this.isCourseAailable = true}
      this.attchmentPath = res.data1.Attachment == null ? this.baseUrlPath+"Uploads/No-image-found.jpg" : this.baseUrlPath+res.data1.Attachment.FilePath;

      if(this.course.Modules != null && this.course.Modules.length > 0){
        this.moduleIdExpanded = 0;
        for  (var i = 0; i < this.course.Modules.length; i++)  {
              if(this.moduleIdExpanded == 0){
                if(this.course.Modules[i].IsCompleted == false){
                  this.moduleIdExpanded = this.course.Modules[i].Id;
                }
              }
              else{
                break;
              }
          }
      }

      this.authService.IsShowLoader(false);
    });

    }



ShowAlertModal(){

  this.modal.IsAlertModalShow = true;
  this.modal.Title = "Attachment";

    this.modal.IsPLANTEXT = true;

    this.modal.Body = "Please, complete previous task first.";

  this.authService.IsShowModal(this.modal);
}

}
