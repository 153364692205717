<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
    <img src="assets/images/imageLoading.gif" alt="Alt Image" style="position: fixed;top: 35%;left: 40%;transform: translate(-50%, -50%);width: 7%;"
    />
</div>



<div class="mdk-drawer-layout__content page ">

    <div class="container-fluid page__container">

        <div class="media align-items-center mb-headings">
            <div class="media-body">
                <h1 class="h2">Stationary</h1>
            </div>
            <div class="media-right d-flex align-items-center">
                <div class="dropdown">
                </div>
            </div>
        </div>

        <div class="row">
            <ng-container *ngIf="stationaryList == null; then Error; else NotError"></ng-container>
            <ng-template #Error>
                <div class="card border-left-3 border-left-primary card-2by1 col-md-12 w-100">
                    <div class="card-body">
                        <div class="media flex-wrap align-items-center">
                            <div class="media-body">
                                Don't have any stationary yet.
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #NotError>
            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-sx-12"> -->
            <div class="col-lg-4 col-md-4 col-sm-2 col-sx-1" *ngFor="let stationary of stationaryList">
                <div class="card">
                    <a href="javascript: void(0);">
                        <img src="{{stationary.AttachmentPath}}" alt="Card image cap" class="avatar-img rounded" style="width:100%;height: 180px !important;">
                    </a>
                    <div class="card-body border-top">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <h4 class="card-title mb-0">
                                    <a>{{stationary.Name}}</a>
                                </h4>
                            </div>
                            <div class="media-right">
                                <a href="{{stationary.PurchaseLink}}" target="_blank" class="btn btn-primary btn-sm float-right" style="color: white;">
                                     Buy </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </ng-template>
        </div>



        <!-- <div class="card-columns">

            <div class="card card-sm" *ngFor="let stationary of stationaryList">
                <div class="card-body media">
                    <div class="media-left">
                        <a href="instructor-review-quiz.html" class="avatar avatar-lg avatar-4by3">
                            <img src="{{stationary.AttachmentPath}}" alt="Card image cap" class="avatar-img rounded">
                        </a>
                    </div>
                    <div class="media-body">
                        <h4 class="card-title mb-0">
                            <a>{{stationary.Name}}</a>
                        </h4>
                    </div>
                </div>
                <div class="card-footer text-center">
                    <a href="{{stationary.PurchaseLink}}" class="btn btn-primary btn-sm float-right">
                        <i class="material-icons btn__icon--left">edit</i> Buy </a>
                    <div class="clearfix"></div>
                </div>
            </div>

        </div> -->

    </div>

</div>