<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
    <img src="assets/images/imageLoading.gif" alt="Alt Image" style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;"
    />
</div>



<div class="mdk-drawer-layout__content page ">

    <div class="row m-0">
        <div class="col-lg container-fluid page__container">

            <div class="row">
                <div class="card">
                    <div class="card-header media align-items-center">
                        <div class="media-body">
                            <h1 class="card-title h2">Payment</h1>
                            <div class="card-subtitle">Pay on the below given bank details or scan to pay. Send the payment screenshot to flagship.design.academy@gmail.com
                                , our team will contact you.</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 col-12">
                                <h2 class="mb-1">Flagship Design Academy</h2>
                                <span class="text-black-50 m-0"></span>
                                <div class="text-black-50">
                                    <strong style="font-size: 16px;">Company : The WoodPeckers</strong>
                                    <br> GST: 24AFVPP5116N1ZT
                                    <br> Address : A-208, Himalaya Arcade,
                                    <br> Opp. Vastrapur Lake,
                                    <br> Besides Petrol Pump,
                                    <br> Vastrapur,

                                    <br> Ahmedabad-380015
                                    <br> Email: flagship.design.academy@gmail.com
                                </div>
                            </div>
                            <div class="col-4 col-12">
                                <p class="text-black-70 m-0">
                                    <strong>&nbsp;</strong>
                                </p>
                                <h2>Bank details</h2>
                                <div class="text-black-50">
                                    Account No.: 50200058590151
                                    <br> IFSC code: HDFC0003964
                                    <br> Branch: Vastrapur
                                    <br> Bank: HDFC BANK
                                    <br>
                                </div>
                            </div>
                            <div class="col-4 col-12">
                                <p class="text-black-70 m-0">
                                    <strong>&nbsp;</strong>
                                </p>
                                <h2>QR Code</h2>
                                <div class="text-black-50">
                                    <div class="">
                                        <img src="../../../../assets/image-QRCode/UPIQRCode.jpeg" alt="Avatar" class="" style="width: 150px !important;height:150px !important;">
                                    </div>
                                </div>
                                <br />
                                <div class="text-black-75 pt-2">
                                    <h2>UPI : &nbsp;
                                        <strong>flagshipdesignacademy@upi</strong>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex align-items-center">
                        <div class="text-muted flex">
                            <p class="card-subtitle text-black-50" style="font-size: 12px">
                                PAY VIA ABOVE MENTIONED PAYMENT DETAILS & SEND YOUR PAYMENT DETAILS ON MENTIONED EMAIL THEN CLICK HERE <i class="fa fa-arrow-right"></i> #PROCEED
                            </p>

                        </div>
                        <a href="javascript: void(0);" (click)="StudentBuyCourse()" class="btn btn-success">Proceed</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>