import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response';
import{DataService} from "../../services/data.service";
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
declare var jQuery: any;

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})

export class CourseComponent implements OnInit {

  loaderPlay = false;
  response : ApiResponse = null;
  CourseList=[];
  listCourseTaken=[];
  listCourseBuy=[];
  baseUrlPath = this.data.API_URL.split("/api")[0]+"/";
  currentUser: User = null;

  IsLoaderShow:boolean;
  subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private data:DataService, private authService: AuthService) {
    
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    
   }

  ngOnInit(): void {

    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    
    this.currentUser = this.authService.CurrentUser();
    this.authService.ScrollOverflow();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }

    this.authService.IsShowLoader(true);

    this.data.GET("GetAllCourse?StudentUserId="+this.currentUser.id).subscribe(data => {
      this.response=data;
      if(this.response.status_code == 1){
        
        this.CourseList = this.response.data1;        
        this.listCourseTaken=[];
        this.listCourseBuy=[];
        this.listCourseTaken = this.CourseList.filter(x=>x.IsTaken == true);
        this.listCourseBuy = this.CourseList.filter(x=>x.IsTaken == false);

        //this.loaderPlay = false;;
        this.authService.IsShowLoader(false);
    
      }
    });

  }

  StudentBuyCourse(courseId: number){
    
    this.router.navigate(['/course/payment/', { courseId: courseId }]);

}

  ReturnShortString(details){
      if(details.length > 60){
          return details
      }
  }

  showCourseDetail(taskdetail){
    return taskdetail.slice(0, 50)+"...";
  }

  isCourseBuy(mycourse){
    if(mycourse == null || mycourse.length == 0){
      return false;
    }
    else{
      return true;
    }
  }

}
