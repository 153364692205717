import { FileToUpload } from "./file-to-upload";

export class Task {
    Id?: number;
    TaskId?: number;
    TaskNo?: number;
    ModuleId? : number = 0;
    StudentTaskId? : number = 0;
    CourseId? : number = 0;
    Comment? : string;
    StudentUserId?: number = 0;
    TaskAttachments: File | null;
    IsHidden: boolean;
    IsCompleted: boolean;
    AttachmentPathList?: string[];
    Attachments?: FileToUpload[] = [];
    TaskDetails: string= "";
    TaskTitle: string="";
    TaskType: string="";
    Answers?: [];
    attachmentType?: [];
    videoTaskMappingViewModels:any;
}
