<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>

<div class="mdk-drawer-layout__content page ">

    <div class="container page__container">
    
    <div class="row m-0">
        <div class="col-lg container-fluid page__container">
            
            <h1 class="h2">Ask Question</h1>

            <form [formGroup]="discussionForm" (ngSubmit)="onSubmit()">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group m-0" role="group" aria-labelledby="label-question">
                            <div class="form-row align-items-center">
                                <label id="label-question" for="discussionModel.ThreadName" class="col-md-3 col-form-label form-label">Question title</label>
                                <div class="col-md-9">
                                    <input name="threadName" formControlName="threadName"  type="text" placeholder="Your question ..." class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="list-group list-group-fit">
                        <div class="list-group-item">
                            <div role="group" aria-labelledby="label-question" class="m-0 form-group">
                                <div class="form-row">
                                    <label id="label-question" for="question" class="col-md-3 col-form-label form-label">Question details</label>
                                    <div class="col-md-9">
                                        <textarea name="threadDetails" formControlName="threadDetails" placeholder="Describe your question in detail ..."
                                                        rows="4" class="form-control" required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="form-group m-0" role="group" aria-labelledby="label-topic">
                                <div class="form-row align-items-center">
                                    <label id="label-topic" class="col-md-3 col-form-label form-label">Attachments</label>
                                    <div class="col-md-9">
                                        <span class="btn btn-success fileinput-button">
                                            <span>Select Attachment</span>
                                            <input type="file" multiple name="attachments" formControlName="attachments" (change)="onFileChange($event)" accept="image/jpeg, image/png, image/gif,application/pdf">
                                            <br />
                                        </span>
                                        <br />
                                        <ul class="TaskFilesList">
                                            <ng-container *ngIf="fileUploads != null; then Available; else NotAvailable"></ng-container>
                                            <ng-template #Available>
                                                <li *ngFor="let attachment of fileUploads">
                                                    <strong>{{attachment.fileName}}</strong> - {{attachment.fileSize}}
                                                    bytes. &nbsp; &nbsp;
                                                    <a href="javascript: void(0);" (click)="removeFile(attachment.fileId)" class="removeFile">Remove</a>
                                                </li>
                                            </ng-template>
                                            <ng-template #NotAvailable>
                                            </ng-template>

                                        </ul>

                                        <output id="Filelist"></output>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="form-group m-0" role="group" aria-labelledby="label-topic">
                                <div class="form-row align-items-center">
                                    <label id="label-topic" for="topic" class="col-md-3 col-form-label form-label">Topic</label>
                                    <div class="col-md-9">
                                        <select class="form-control custom-select w-auto" name="threadOption" formControlName="threadOption" (change)='onOptionsSelected($event)'>
                                            <option value="Private" selected="selected">Private</option>
                                            <option value="Public">Public</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="IsShowUsers; then Show; else NotShow"></ng-container>
                                            <ng-template #Show>
                                                <div class="list-group-item">
                                                    <div class="form-group m-0" role="group" aria-labelledby="label-topic">
                                                        <div class="form-row align-items-center">
                                                            <label id="label-topic" for="student" class="col-md-3 col-form-label form-label">Select Student</label>
                                                            <div class="col-md-9">
                                                                <select class="form-control custom-select w-auto" (change)='onStudentSelected($event)'>
                                                                    <option *ngFor='let student of listStudent' 
                                                                    [value]="student.Id">{{student.Name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="list-group-item">
                                                    <div class="form-group m-0" role="group" aria-labelledby="label-topic">
                                                        <div class="form-row align-items-center">
                                                            <label id="label-topic" for="teacher" class="col-md-3 col-form-label form-label">Select Teacher</label>
                                                            <div class="col-md-9">
                                                                <select class="form-control custom-select w-auto" (change)='onTeacherSelected($event)'>
                                                                    <option *ngFor='let teacher of listTeacher' 
                                                                    [value]="teacher.Id">{{teacher.Name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template #NotShow>
                                            </ng-template>
                        
                        <div class="list-group-item">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>

</div>
</div>