<!-- <div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div> -->

<button id="btnExamNotify" type="button" style="display: none;" (click)="GetExamNotify()">NotifyExam</button>

<div class="mdk-drawer-layout__content page ">

    <div class="container page__container">

        <div class="media align-items-center mb-headings">
            <div class="media-body">
                <h1 class="h2">Exams</h1>
            </div>
        </div>

        <ng-container *ngIf="listExam == null; then Error; else NotError"></ng-container>
            <ng-template #Error>
                <div class="card border-left-3 border-left-primary card-2by1 col-md-12 w-100">
                    <div class="card-body">
                        <div class="media flex-wrap align-items-center">
                            <div class="media-body">
                                You don't have any exam pending yet.
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #NotError>
        <div class="card-columns">
                <div class="card card-sm" *ngFor="let exam of listExam">
                    <div class="card-body media">
                        <div class="media-body pl-2">
                            <h4 class="card-title mb-0"><a [routerLink]="['/exam/take-exam/',exam.ExamId,exam.ModuleId,exam.CourseId]">{{exam.Name}}</a></h4>
                            <small class="text-muted h6">{{exam.ModuleName}} </small> <br />
                            <small class="text-muted">{{exam.TotalQuestions}} Question(s)</small>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                      <small class="text-muted float-left" *ngIf="(exam.IsCompleted == true)">{{exam.CompleteDateTime}}</small>

                      <small class="text-muted float-left" *ngIf="(exam.IsCompleted == false && exam.IsApproved == false && exam.IsPass == false)">&nbsp;</small>
                      <small class="text-muted float-left" *ngIf="(exam.IsCompleted === true && exam.IsApproved === true && exam.IsPass === false)">&nbsp;</small>
                      <span *ngIf="(exam.IsCompleted === true && exam.IsApproved === true && exam.IsPass === false)" class="badge badge-pill badge-warning">Re-Appear </span>
                      <a [routerLink]="['/result/exam-result/',exam.ExamId,exam.ModuleId,exam.CourseId]" *ngIf="(exam.IsCompleted == true && exam.IsApproved == true && exam.IsPass == true)" class="btn btn-success btn-sm float-right"> Show Result </a>
                      <a [routerLink]="['/result/exam-result/',exam.ExamId,exam.ModuleId,exam.CourseId]" *ngIf="(exam.IsCompleted == true && exam.IsApproved == false && exam.IsPass == false)" class="btn btn-success btn-sm float-right"> Show Result </a>
                      <a [routerLink]="['/result/exam-result/',exam.ExamId,exam.ModuleId,exam.CourseId]" *ngIf="(exam.IsCompleted == true && exam.IsApproved == true && exam.IsPass == false)" class="btn btn-warning btn-sm float-right"> Show Result & Start Exam </a>
                      <a [routerLink]="['/exam/take-exam/',exam.ExamId,exam.ModuleId,exam.CourseId]" *ngIf="(exam.IsCompleted == false && exam.IsPass == false)" class="btn btn-primary btn-sm float-right"> Start Exam </a>
                        <div class="clearfix"></div>
                    </div>
                </div>
        </div>
    </ng-template>
    </div>
</div>
