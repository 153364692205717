import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response';
import { Exam } from 'src/app/models/exam';
import { Question } from 'src/app/models/question';
import {FormBuilder, FormControl, FormGroup, Validators, NgForm} from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FileToUpload } from 'src/app/models/file-to-upload';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';



// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;

@Component({
  selector: 'app-take-exam',
  templateUrl: './take-exam.component.html',
  styleUrls: ['./take-exam.component.css']
})

export class TakeExamComponent implements OnInit {

  response : ApiResponse = null;
  loaderPlay = false;
  examId: number;
  moduleId: number;
  courseId: number;

  public examModel = new Exam();

  public questionModel = new Question();

  fileList: FileList;

  fileUploads: FileToUpload[] = [];
  tempFileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();

  currentUser: User = null;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,private context: DataService, private spinner: NgxSpinnerService, private authService: AuthService, private router: Router) {
    this.examId = this.activatedRoute.snapshot.params.ExamId;
    this.moduleId = this.activatedRoute.snapshot.params.ModuleId;
    this.courseId = this.activatedRoute.snapshot.params.CourseId;

    this.examModel.Questions = [];
  }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    this.authService.IsShowLoader(true);

    this.authService.ScrollOverflow();
    this.currentUser = this.authService.currentUserValue;
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }


      this.GetExamDetails(this.examId);

      // var firstQuestionId = this.examModel.Questions.filter(x => x.QuestionNo === 1).QuestionId;

      // console.log(firstQuestionId);

      // this.ChangeQuestion(4);
  }

  GetExamDetails(id: Number) {
    //this.loaderPlay = true;

    this.authService.IsShowLoader(true);

    this.context.GET("GetExamDetails?StudentUserId="+this.currentUser.id+"&ExamId="+id+"&ModuleId="+this.moduleId+"&CourseId="+this.courseId).subscribe(data => {
      this.response=data;
      if(this.response.status_code == 1){
        this.examModel = this.response.data1;
        this.examModel.StudentUserId = this.currentUser.id;
        this.loaderPlay = false;

        if(this.examModel.IsPass){
          this.router.navigate(['/result/exam-result/'+this.examModel.ExamId+"/"+this.examModel.ModuleId+"/"+this.examModel.CourseId]);
        }

        if(!this.examModel.IsPass){
          this.ChangeQuestion(1);
          this.authService.IsShowLoader(true);
        }

        this.authService.IsShowLoader(false);
      }
    });
  }

  ChangeQuestion(QuestionNo: Number){
    this.authService.IsShowLoader(true);
    //this.loaderPlay = true;
    var TotalQuestion = this.examModel.Questions.length;

    for(var i=0; i< TotalQuestion; i++){
      if(this.examModel.Questions[i].QuestionNo == QuestionNo){
        this.questionModel = this.examModel.Questions[i];
        this.questionModel.Options = [this.questionModel.Option1, this.questionModel.Option2, this.questionModel.Option3, this.questionModel.Option4];
      }
    }

    this.questionModel.PreviousQuestionId = this.questionModel.QuestionNo - 1;

    if(TotalQuestion <= this.questionModel.QuestionNo){
      this.questionModel.NextQuestionId = 0;
    }
    else{
      this.questionModel.NextQuestionId = this.questionModel.QuestionNo + 1;
    }

    this.tempFileUploads = [];

    if(this.fileUploads != null){

      for  (var j =  0; j < this.fileUploads.length; j++) {
        if (this.fileUploads[j].fileId.split('-')[2] === String(this.questionModel.QuestionId)){
          this.tempFileUploads.push(this.fileUploads[j]);
        }
      }
    }


    //this.questionModel = this.examModel.Questions.filter(x => x.QuestionNo === QuestionNo);

    // this.questionModel.PreviousQuestionId = parseInt(this.questionModel.QuestionNo+"") - 1;
    // this.questionModel.NextQuestionId = parseInt(this.questionModel.QuestionNo+"") + 1;

    //console.log(this.questionModel);.
    this.authService.IsShowLoader(false);
    //this.loaderPlay = false;
  }

  PreviousQuestion(QuestionNo: number){
    this.ChangeQuestion(QuestionNo);
  }

  SaveAnswer(questionId: number, nextQuestionNo: number){
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);
    if(this.questionModel.Answer != ""){
      for(var i=0; i< this.examModel.Questions.length; i++){
        if(this.examModel.Questions[i].QuestionId == questionId){
          this.examModel.Questions[i].Answer = this.questionModel.Answer;
        }
      }
    }

    //this.loaderPlay = false;
    this.authService.IsShowLoader(false);
    this.ChangeQuestion(nextQuestionNo);
  }

  onFileChange(questionId:number, event)  {
    let count = 0;

    this.tempFileUploads = [];

    if(this.fileUploads != null){
      count = this.fileUploads.length;
    }

    if (event.target.files && event.target.files.length > 0) {
      this.fileList = event.target.files;
        for  (var i =  0; i < event.target.files.length; i++)  {
          // Don't allow file sizes over 1MB
          //if (event.target.files[i].size < MAX_SIZE) {
            // Set theFile property

            count++;

            this.fileUpload = new FileToUpload();
            this.fileUpload.fileId = "file-"+String(count)+"-"+questionId;
            this.fileUpload.fileSize = event.target.files[i].size;
            this.fileUpload.fileName = event.target.files[i].name;
            this.fileUpload.file = event.target.files[i];

            this.fileUploads.push(this.fileUpload);
            //this.tempFileUploads.push(this.fileUpload);
            //console.log(this.fileUploads);
          // }
          // else {
          //     alert("File: " + event.target.files[i].name + " is too large to upload.(2MB)");
          //     this.fileList = null;
          //     return false;
          // }
        }


    if(this.fileUploads != null){

      for  (var j =  0; j < this.fileUploads.length; j++) {
        if (this.fileUploads[j].fileId.split('-')[2] === String(questionId)){
          this.tempFileUploads.push(this.fileUploads[j]);
        }
      }
    }

    }

    // debugger;

    // for(var i=0; i< this.examModel.Questions.length; i++){
    //   if(this.examModel.Questions[i].QuestionId == questionId){
    //     this.examModel.Questions[i].Attachments = this.fileUploads;
    //       //this.fileList = event.target.files;
    //     }
    //   }
    }


    removeFile(questionId:number, fileId: string){
      // for(var i=0; i< this.examModel.Questions.length; i++){
      //   if(this.examModel.Questions[i].QuestionId == questionId){
      //       this.fileUploads = this.examModel.Questions[i].Attachments;
            for  (var j =  0; j < this.fileUploads.length; j++) {
              if (this.fileUploads[j].fileId === fileId)
                this.fileUploads.splice(j, 1);
            }

            //this.examModel.Questions[i].Attachments = this.fileUploads;
        //   }
        // }


    this.tempFileUploads = [];

    if(this.fileUploads != null){

      for  (var j =  0; j < this.fileUploads.length; j++) {
        if (this.fileUploads[j].fileId.split('-')[2] === String(questionId)){
          this.tempFileUploads.push(this.fileUploads[j]);
        }
      }
    }

    }


  onSubmitExam(){

    // if(confirm("Are you sure to submit Exam?")) {

    this.authService.IsShowLoader(true);

    const formData: FormData = new FormData();

    this.examModel.ModuleId = this.moduleId;
    this.examModel.CourseId = this.courseId;
    this.examModel.StudentUserId = this.currentUser.id;

    formData.append("objViewSTR", JSON.stringify(this.examModel));

    // for(var i=0; i < this.examModel.Questions.length; i++){
    //   if(this.examModel.Questions[i] != null){

    //     if(this.examModel.Questions[i].Attachments !== null){
    //       for(var j=0; j< this.examModel.Questions[i].Attachments.length; j++){
    //         if(this.examModel.Questions[i].Attachments[j].file !== null){
    //           formData.append('file-'+j+"-"+this.examModel.Questions[i].QuestionId, this.examModel.Questions[i].Attachments[j].file, this.examModel.Questions[i].Attachments[j].file.name);
    //         }
    //       }
    //     }
    //   }

    //   }

    for(var i=0; i< this.fileUploads.length; i++){
      formData.append(this.fileUploads[i].fileId, this.fileUploads[i].file, this.fileUploads[i].file.name);
    }

    this.authService.IsShowLoader(true);

    this.context.POSTWITHATTACHMENTS("InsertExamAnswer", formData).subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){

        //alert("Exam data is submitted.");
        this.router.navigate(['/result/exam-result/'+this.examModel.ExamId+"/"+this.examModel.ModuleId+"/"+this.examModel.CourseId]);
        //this.router.navigate(['/exam']);
      }
    });

  // }
  // else{
  //   return false;
  // }
  }

}
