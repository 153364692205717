import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/models/api-response';
import { Payment } from 'src/app/models/payment';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-paymenthistory',
  templateUrl: './paymenthistory.component.html',
  styleUrls: ['./paymenthistory.component.css']
})
export class PaymenthistoryComponent implements OnInit {
  
  response : ApiResponse = null;
  loaderPlay = false;
  currentUser: User = null;
      
  listPayment: Payment[] = [];

  TotalPayment: number;

  constructor(private router: Router, private context: DataService, private spinner: NgxSpinnerService, private authService: AuthService) {
    
  }

  ngOnInit(): void {
    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "/home" } });
    }

    this.GetPaymentHistory(this.currentUser.id);
  }

  GetPaymentHistory(id: number) {
    this.loaderPlay = true;
    this.context.GET("GetPaymentHistory?StudentUserId="+id).subscribe(data => {
      this.response=data;
      
      if(this.response.status_code == 1){
        this.listPayment = this.response.data1;
      }
      this.loaderPlay = false;
    });
  }

}
