import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response';
import { Thread } from 'src/app/models/thread';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FileToUpload } from 'src/app/models/file-to-upload';

import * as $ from "jquery";
import { FileExtension } from 'src/app/models/file-extension';
import { Modal } from 'src/app/models/modal';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;

@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.css']
})

export class ThreadComponent implements OnInit {

  loaderPlay = false;
  response : ApiResponse = null;
  discussionId: number;
  public threadModel = new Thread();
  fileList: [];
  discussionForm: FormGroup;
  discussionheight;
  CurrentLoginUser;

  currentUser: User = null;

  fileExtension = new FileExtension();

  fileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();

  lastDiscussionMessageId:number;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;


  constructor(private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,private context: DataService, private authService: AuthService, private router: Router) {
    this.discussionId = this.activatedRoute.snapshot.params.DiscussionId;
   }

   ngOnInit(): void {

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

     this.authService.ScrollOverflow();
    this.discussionheight = window.innerHeight - 200;
    this.currentUser = this.authService.CurrentUser();
    this.CurrentLoginUser = this.authService.CurrentLoginUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "/home" } });
    }

    this.threadModel.DiscussionId = this.discussionId;

    this.discussionForm = this.formBuilder.group({
      comment: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      attachments: ['']
    });

    this.GetThread(Number(this.discussionId));

    
    // const source = interval(60000);
    // //const text = 'Your Text Here';
    // // this.subscription = source.subscribe(val => this.opensnack(text));
    // this.subscription = source.subscribe(val => this.GetOnlyMessage(this.discussionId));
    
    (function ($) {
      $(document).ready(function(){
        
      var connection = $.hubConnection(environment.API_Host, '', false, {xdomain: true});
        
      var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);
      
      hubProxy.on('receiveThread', function(message) {

        $("#btnThreadNotify").click();

        });

      connection.start().then(function () {

          // $('#btnInsertThread').click(function () {
          //     // hubProxy.server.SendTaskDiscussionMessage();
              
          //     hubProxy.invoke('SendThreadMessage').done(function () {
          //         console.log ('SendThreadMessage succeeded');
          //     }).fail(function (error) {
          //         console.log('SendThreadMessage failed. Error: ' + error);
          //     });
          // });

          
        }).catch(function (err) {
            return console.error(err.toString());
        });

        
      connection.disconnected(function () {
          connection.start();
      });

    });
  })(jQuery);

  }

ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  GetThreadNotify(){
    this.GetThread(Number(this.discussionId));
  }

  GetOnlyMessage(Id){

    this.context.GET("GetDiscussionDetails?StudentUserId="+this.currentUser.id +"&DiscussionId="+ Id).subscribe(data => {
      this.response=data;

      if(this.response.status_code == 1){
        this.threadModel.DiscussionMessageList = this.response.data1.DiscussionMessageList;

        //this.threadModel.DiscussionMessageList = this.sortData(this.threadModel.DiscussionMessageList);

        let lastDiscussion:any = this.threadModel.DiscussionMessageList[this.threadModel.DiscussionMessageList.length-1];

        if(this.currentUser.id == lastDiscussion.UserId){
          this.lastDiscussionMessageId = lastDiscussion.Id;
        }
        else{
          this.lastDiscussionMessageId = 0;
        }
      }
    });

    //this.loaderPlay = false;
  }

  GetThread(Id){

    //this.loaderPlay = true;
          this.authService.IsShowLoader(true);

    this.context.GET("GetDiscussionDetails?StudentUserId="+this.currentUser.id +"&DiscussionId="+ Id).subscribe(data => {
      this.response=data;

      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){
        this.threadModel = this.response.data1;

        //this.threadModel.DiscussionMessageList = this.sortData(this.threadModel.DiscussionMessageList);

        let lastDiscussion:any = this.threadModel.DiscussionMessageList[this.threadModel.DiscussionMessageList.length-1];

        if(this.currentUser.id == lastDiscussion.UserId){
          this.lastDiscussionMessageId = lastDiscussion.Id;
        }
        else{
          this.lastDiscussionMessageId = 0;
        }
      }
    });

    //this.loaderPlay = false;
  }

  onFileChange(event) {

    //this.fileUploads = [];
    let count = 0;

    if(this.fileUploads != null){
      count = this.fileUploads.length;
    }

      if (event.target.files && event.target.files.length > 0) {
        this.fileList = event.target.files;
          for  (var i =  0; i < event.target.files.length; i++)  {
            // Don't allow file sizes over 1MB
            //if (event.target.files[i].size < MAX_SIZE) {
              // Set theFile property
              count++;
              this.fileUpload = new FileToUpload();
              this.fileUpload.fileId = String(count);
              this.fileUpload.fileSize = event.target.files[i].size;
              this.fileUpload.fileName = event.target.files[i].name;
              this.fileUpload.file = event.target.files[i];

              this.fileUploads.push(this.fileUpload);
            // }
            // else {
            //     // Display error message
            //     //this.messages.push("File: " + event.target.files[i].name + " is too large to upload.");
            //     alert("File: " + event.target.files[i].name + " is too large to upload.(2MB)");
            //     this.fileList = null;
            //     return false;
            // }
          }
      }

  }

  removeFile(fileId: string){
    for  (var i =  0; i < this.fileUploads.length; i++)  {
      if (this.fileUploads[i].fileId === fileId)
        this.fileUploads.splice(i, 1);
    }
  }

  onSubmit() {

    this.authService.IsShowLoader(true);

    const formData =  new  FormData();

    for  (var i =  0; i < this.fileUploads.length; i++)  {
      formData.append("file"+i,  this.fileUploads[i].file);
    }

    this.threadModel.Comment = this.discussionForm.get('comment').value;
    this.threadModel.DiscussionId = this.discussionId;
    this.threadModel.StudentUserId = this.currentUser.id;
    this.threadModel.UserId = this.currentUser.id;

    formData.append('objViewSTR', JSON.stringify(this.threadModel));

    if(this.threadModel.Comment == null && this.fileUploads == null){
      this.authService.IsShowLoader(false);
      return false;
    }

    if(this.threadModel.Comment == null && this.fileUploads != null && this.fileUploads.length == 0){
      this.authService.IsShowLoader(false);
      return false;
    }

    // if(this.threadModel.Comment != null && this.threadModel.Comment.length > 0)
    // {
      
    this.discussionForm = this.formBuilder.group({
      comment: new  FormControl('',  [Validators.required,  Validators.minLength(3)]),
      attachments: ['']
    });

      this.fileUploads = [];

      this.context.POSTWITHATTACHMENTS("InsertDiscussionMessage", formData).subscribe(data => {
        this.response=data;

        this.authService.IsShowLoader(false);

        if(this.response.status_code == 1){

          // this.threadModel.Comment = "";
          // this.fileUploads = [];
          // //this.discussionForm.setValue({comment: ""});
          // $('#comment').val('');
          //this.GetThread(Number(this.discussionId));


          this.threadModel = this.response.data1;

        
          // let element: HTMLElement = document.getElementById('btnInsertThread') as HTMLElement;
          // element.click();


        //this.threadModel.DiscussionMessageList = this.sortData(this.threadModel.DiscussionMessageList);

        let lastDiscussion:any = this.threadModel.DiscussionMessageList[this.threadModel.DiscussionMessageList.length-1];

        if(this.currentUser.id == lastDiscussion.UserId){
          this.lastDiscussionMessageId = lastDiscussion.Id;
        }
        else{
          this.lastDiscussionMessageId = 0;
        }
        }
      });
  }
  
  matchUserId(UserId,currentUserId){
    if(UserId == currentUserId){
      return true;
    }
    else{
      return false;
    }
}


ShowModal(AttachmentPath: string){

          this.modal = new Modal();

          this.modal.IsModalShow = true;
          this.modal.Title = "Attachment";
          
          if(AttachmentPath != null && AttachmentPath != ''){
            this.modal.IsInnerHTML = true;

            //this.modal.Body = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+AttachmentPath+"\" allowfullscreen></iframe></div>";
            this.modal.Body = AttachmentPath;

          }

          this.authService.IsShowModal(this.modal);
}

ReturnDateTime(date){
  var ConvertDateTime = new Date(date);
  return ConvertDateTime.getDate()+"/"+(parseInt(ConvertDateTime.getMonth().toString())+1)+"/"+ConvertDateTime.getFullYear()+" "+ConvertDateTime.toLocaleTimeString();
}


RemoveDiscussion(discussionForumId: number){
  this.authService.ScrollOverflow();
  //this.loaderPlay = true;
  this.authService.IsShowLoader(true);
  this.context.GET("RemoveDiscussion?StudentUserId="+this.currentUser.id + "&DiscussionForumId=" + discussionForumId).subscribe(data => {
    this.response=data;
    this.authService.IsShowLoader(false);
    if(this.response.status_code == 1){
      this.router.navigate(['/discussion']);
    }
    //this.loaderPlay = false;
  });
  //this.loaderPlay = false;
}

CloseDiscussion(discussionForumId: number){
  this.authService.ScrollOverflow();
  //this.loaderPlay = true;
  this.authService.IsShowLoader(true);
  this.context.GET("CloseDiscussion?StudentUserId="+this.currentUser.id + "&DiscussionForumId=" + discussionForumId).subscribe(data => {
    this.response=data;
    this.authService.IsShowLoader(false);
    if(this.response.status_code == 1){
      this.router.navigate(['/discussion']);
    }
    //this.loaderPlay = false;
  });
  //this.loaderPlay = false;
}

RemoveFromDiscussionMessage(discussionForumMessageId: number){
  this.authService.ScrollOverflow();
  //this.loaderPlay = true;
          this.authService.IsShowLoader(true);

  this.context.GET("RemoveMessageFromDiscussion?StudentUserId="+this.currentUser.id + "&DiscussionForumMessageId=" + discussionForumMessageId).subscribe(data => {

    this.response=data;

    if(this.response.status_code == 1){
      this.threadModel = this.response.data1;
    }

    //this.loaderPlay = false;
          this.authService.IsShowLoader(false);

  });
  //this.loaderPlay = false;
}


sortData(dataList: any) {

let data = dataList.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());

  return data;


}

}