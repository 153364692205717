import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import{DataService} from "../../services/data.service";
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {
  
  CourseList = [];
  SampleCertificate;
  currentUser = new User();

  constructor(private data:DataService, private spinner: NgxSpinnerService,private authService: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.CurrentUser();
    this.data.GetStudentCourseById(this.currentUser.id).then(res => {
      this.CourseList = res.data1;
      this.SampleCertificate = res.data2;
    });
  }

}
