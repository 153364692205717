import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import{DataService} from "../../services/data.service";

@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.css']
})
export class PlacementComponent implements OnInit {

  placementList = [];

  constructor(private data:DataService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.data.GetAllPlacement().then(res => {
      this.placementList = res.data1;
    });
  }

}
