export class Thread {
    Id?: number;
    DiscussionId : number = 0;
    Comment? : string;
    StudentUserId: number = 0;
    Attachments: [];
    AttachmentPathList: [];
    IsClosed: Boolean;
    IsDelete: Boolean;
    IsGroupWise: Boolean;
    IsPrivate: Boolean;
    IsPublic: Boolean;
    ThreadDetails: string;
    ThreadName: string;
    ThreadCreatedBy: string;
    ThreadCreatedByImagePath: string;
    DiscussionMessageList: [];
    UserId: number = 0;
}
