import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/api-response';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
import * as $ from "jquery";

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {

  response : ApiResponse = null;
  loaderPlay = false;
  listExam = [];
  currentUser: User = null;
  errorMsg: string;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private context: DataService, private authService: AuthService, private router: Router) {

    }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    this.authService.IsShowLoader(true);

    this.authService.ScrollOverflow();
    this.currentUser = this.authService.currentUserValue;
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }
    //this.loaderPlay = true;

    this.authService.IsShowLoader(true);

    this.GetExams();

    (function ($) {
      $(document).ready(function(){

      var connection = $.hubConnection(environment.API_Host, '', false, {xdomain: true});

      var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);

      hubProxy.on('receiveExamChecked', function(message) {
        $("#btnExamNotify").click();

        });

      connection.start().then(function () {
        }).catch(function (err) {
            return console.error(err);
        });


      connection.disconnected(function () {
          connection.start();
      });

    });
  })(jQuery);

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  GetExams(){

    this.context.GET("GetAllExam?StudentUserId="+this.currentUser.id).subscribe(data => {
      this.response=data;
      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){
        this.listExam = this.response.data1;
      }
      else{
        this.errorMsg = this.response.message;
        this.listExam = null;
      }
      //this.loaderPlay = false;
    });


  }

  GetExamNotify(){
    this.GetExams();
  }
}
