import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { collectExternalReferences } from '@angular/compiler';
// import * as $ from "jquery";
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
import { Modal } from 'src/app/models/modal';
import { Notification } from 'src/app/models/notification';
import { FileExtension } from 'src/app/models/file-extension';
import { DataService } from 'src/app/services/data.service';
import { ApiResponse } from 'src/app/models/api-response';
import { elementAt } from 'rxjs/operators';
//import { debug } from 'console';
import * as moment from 'moment';
//import { HubConnectionBuilder } from 'signalr';
import { HubConnection } from '@aspnet/signalr';
import { SignalrService } from 'src/app/services/signalr.service';
import * as signalR from '@aspnet/signalr';
declare var jQuery: any;

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
  providers: [DatePipe],
})
export class MasterComponent implements OnInit {
  loaderPlay = false;
  currentUser: User;
  LoginUser;

  IsLoaderShow: boolean = true;
  modal = new Modal();
  subscription: Subscription;
  subscriptionModal: Subscription;

  fileExtensions = new FileExtension();

  intervalId: number;

  latestNotification: Notification;
  lastNotificationDateTime: any;
  listNotification: Notification[] = [];
  IsNewNotification: boolean = false;

  response: ApiResponse = null;
  private connection: signalR.HubConnection;

  //ajmera//
  notificationCourseName: any;
  notificationModuleName: any;
  notificationTaskName: any;

  notificationTuneSound = '../assets/notification.mp3';

  constructor(
    private router: Router,
    private authService: AuthService,
    private context: DataService,
    private datepipe: DatePipe,
    private signalr: SignalrService
  ) {
    this.currentUser = this.authService.CurrentUser();

    if (
      this.currentUser.attachmentPath == '' ||
      this.currentUser.attachmentPath == null
    ) {
      this.currentUser.attachmentPath = this.authService.FillEmptyImage(true);
    }

    this.LoginUser = this.authService.CurrentLoginUser();

    this.subscription = this.authService.currentMessage.subscribe(
      (IsLoaderShow) => (this.IsLoaderShow = IsLoaderShow)
    );
    this.subscriptionModal = this.authService.modalViewObj.subscribe(
      (modal) => (this.modal = modal)
    );
    // this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = this.OpenModal(modal));

    // this.connection = new signalR.HubConnectionBuilder()
    // .withUrl("http://localhost:43210/signalr/flagshipnotificationhub")
    // .configureLogging(signalR.LogLevel.Information)
    // .build();

    // this.connection.start().catch(err => console.log(err));
    // this.connection.on('receive', (message) => {
    //   debugger;
    //   console.log("Get Notification from Admin Panel.");
    //     console.log(message);
    // });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  ngOnInit(): void {
    this.authService.ScrollOverflow();
    // (function ($) {
    //   $(document).ready(function(){
    //   });
    //   $('#closePreview').on('click',function(){
    //     $('#dvPreview').hide();
    //   })
    // })(jQuery);

    this.subscription = this.authService.currentMessage.subscribe(
      (IsLoaderShow) => (this.IsLoaderShow = IsLoaderShow)
    );
    this.subscriptionModal = this.authService.modalViewObj.subscribe(
      (modal) => (this.modal = modal)
    );

    // this.signalr.startConnection();

    this.GetNotification();

    // const source = interval(60000);
    // //const text = 'Your Text Here';
    // // this.subscription = source.subscribe(val => this.opensnack(text));
    // this.subscription = source.subscribe(val => this.opensnack());

    // if(this.listNotification != null && this.listNotification.length == 0){
    //   this.listNotification = null;
    // }

    (function ($) {
      $(document).ready(function () {
        var connection = $.hubConnection(environment.API_Host, '', false, {
          xdomain: true,
        });

        var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);

        hubProxy.on('receive', function (message) {
          $('#btnNotification').click();
        });

        connection
          .start()
          .then(function () {})
          .catch(function (err) {
            return console.error(err.toString());
          });

        connection.disconnected(function () {
          connection.start();
        });

        // hubProxy.invoke('SendTaskDiscussionMessage').done(function () {
        //     console.log ('SendTaskDiscussionMessage succeeded');
        // }).fail(function (error) {
        //     console.log('SendTaskDiscussionMessage failed. Error: ' + error);
        // });
      });
    })(jQuery);
  }

  OpenModal(modal) {
    this.modal.IsModalShow = modal.IsModalShow;

    if (modal.IsModalShow) {
      document.getElementById('openModalButton').click();
    } else {
      $(function () {
        document.getElementById('modalClose').click();
      });
    }

    if (modal.IsAlertModalShow) {
      document.getElementById('openModalButton').click();
    } else {
      $(function () {
        document.getElementById('modalClose').click();
      });
    }

    return modal;
  }

  // opensnack(text) {
  //   console.log(text);
  // }

  opensnack() {
    this.GetNotification();
  }

  Logout() {
    this.authService.Logout();
    this.router.navigate(['/login']);
  }

  clickModalClose() {
    this.modal.IsModalShow = false;
    this.modal.IsAlertModalShow = false;
    this.modal.Title = '';
    this.modal.Body = '';
  }

  GetInnerHTML(link: string) {
    this.authService.IsShowLoader(true);

    let data = '';

    if (link != '' || link != null) {
      if (link.length > 0) {
        // if(this.fileExtensions.PDF.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        // else if(this.fileExtensions.WORD.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        // else if(this.fileExtensions.EXCEL.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        // else if(this.fileExtensions.POWERPOINT.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        // else if(this.fileExtensions.AUDIO.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        // else if(this.fileExtensions.VIDEO.indexOf(link.substring(link.lastIndexOf('.') + 1)) > -1){

        //   data = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+link+"\" allowfullscreen></iframe></div>";

        // }
        if (
          this.fileExtensions.IMAGE.indexOf(
            link.substring(link.lastIndexOf('.') + 1)
          ) > -1
        ) {
          data =
            '<img src="' +
            link +
            '" class="img-fluid rounded mx-auto d-block w-100" alt="Responsive image" >';
        } else {
          data =
            '<span class="text-dark">' +
            link.substring(link.lastIndexOf('/') + 1) +
            '</span> &nbsp; <a href="' +
            link +
            '" target="_blank">Click Here</a>';
        }
      }
    }

    this.authService.IsShowLoader(false);
    return data;
  }

  GetNotification() {
    //this.authService.IsShowLoader(true);

    this.context
      .GET(
        'GetAllNotification?StudentUserId=' +
          this.currentUser.id +
          "&Type='Web'" +
          '&Count=5'
      )
      .subscribe((data) => {
        this.response = data;

        if (this.response.status_code == 1) {
          this.listNotification = this.response.data1;
          if (
            this.listNotification != null &&
            this.listNotification.length > 0
          ) {
            // $('#notificationtune').get(0).play();

            let playNotification = <HTMLAudioElement>(
              document.getElementById('notificationtune')
            );
            playNotification.play();
            console.clear();

            this.latestNotification = this.listNotification[0];

            //let letestNotificationDateTime = this.datepipe.transform(new Date(this.latestNotification.CreatedDate), 'yyyyMMddHHmmsss');

            let letestNotificationDateTime: Number =
              this.latestNotification.CreatedDateTimeTicks;

            let strOldNotificationDateTime = localStorage.getItem(
              'LastNotificationDateTime'
            );

            let lastNotificationDateTime: Number;

            if (strOldNotificationDateTime != null) {
              lastNotificationDateTime = Number(
                JSON.parse(strOldNotificationDateTime)
              );
            } else {
              localStorage.setItem(
                'LastNotificationDateTime',
                JSON.stringify(letestNotificationDateTime)
              );
            }

            if (letestNotificationDateTime > lastNotificationDateTime) {
              localStorage.setItem(
                'LastNotificationDateTime',
                JSON.stringify(letestNotificationDateTime)
              );
              //localStorage.setItem('LastNotificationDateTime',letestNotificationDateTime);
              this.IsNewNotification = true;
            }
            // else{
            //   this.IsNewNotification = false;
            // }
          }
        } else {
          this.listNotification = null;
        }

        //this.authService.IsShowLoader(false);
      });
  }

  ShowNotificationDone() {
    this.IsNewNotification = false;
  }

  SplitNotificationTitle(type, title) {
    if (type == 'TaskDiscussion') {
      title = title.split('#');

      this.notificationCourseName = title[0];
      this.notificationModuleName = title[1];
      this.notificationTaskName = title[2];

      //title = title[0]+"MOD: "+title[1]+"Task: "+title[2]+""
    }
    return title;
  }
}
