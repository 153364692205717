import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { tap, catchError, map} from 'rxjs/operators';
import { ApiResponse } from '../models/api-response';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { FileToUpload } from '../models/file-to-upload';
import { User } from '../models/user';

const httpOptions = {
  headers: new HttpHeaders( {
    'Content-Type': 'application/json;charset=utf-8'
    //,Authorization': 'Basic ' + btoa( Username + ":" + Password ),
  } ),
};

@Injectable({
  providedIn: 'root'
})
export class DataService {

  API_URL: string = environment.API_URL;
  API_Host: string = environment.API_Host;

  response : ApiResponse = null;

  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8').set('Accept', 'application/json')
  };
  currentUser: User;
  constructor(private http: HttpClient, private authService: AuthService, private routes : Router) {

  this.currentUser = this.authService.CurrentUser();
  if (this.currentUser) { }
    else{
      this.routes.navigate(['/login'], { queryParams: { returnUrl: "/home" } });
    }

  }

  private handleError(error: any) {
    let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }

     return throwError(errorMessage);
  }

  GET(action:string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.API_URL+action, this.httpOptions).pipe(
      //tap(data => console.log("GET-"+action+": "+ JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  GETBYID(action:string, id:number): Observable<ApiResponse> {
    const url = `${this.API_URL+action}?Id=${id}`;
    return this.http.get<ApiResponse>(url, this.httpOptions).pipe(
      //tap(data => console.log("GET-"+action+": "+ JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  GETBYURL(action:string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>('${this.API_URL+action}', this.httpOptions).pipe(
      //tap(data => console.log("GET-"+action+": "+ JSON.stringify(data))),
      catchError(this.handleError)
    );
  }





  POST(action:string, obj: any): Observable<ApiResponse> { 
    // const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
    // const body = { title: 'Angular POST Request Example' };
    // this.http.post<any>('https://jsonplaceholder.typicode.com/posts', body, { headers }).subscribe(data => {
    //     this.postId = data.id;
    // });
    return this.http.post<ApiResponse>(this.API_URL+action, JSON.stringify(obj), this.httpOptions).pipe(
    //tap(data => console.log("POST-"+action+": "+ JSON.stringify(data))),
    catchError(this.handleError));
}

  // GETBYPARAMETERS(action, params){
  //   return this.http.get<ApiResponse>(this.API_URL+action, {params: params}).pipe(
  //     tap(data => console.log(data)),
  //     catchError(this.handleError)
  //   );
  // }

  //Course
  GetAllCourse(){
    return new Promise<any>( ( resolve, reject ) => {
      this.http.get( this.API_URL + 'GetAllCourse', this.httpOptions )
        .subscribe( response => {
          if ( response ) {
            resolve( response );
          }
        } )
    } );
  }

  GetCourseDetails(studentUserId,courseId){
    return new Promise<any>( ( resolve, reject ) => {
      this.http.get( this.API_URL + 'GetCourseDetails?StudentUserId='+studentUserId+'&CourseId='+courseId, this.httpOptions )
        .subscribe( response => {
          if ( response ) {
            resolve( response );
          }
        } )
    } );
  }

  //Student Profile

  //Stationary
  GetAllStattionary(){
    return new Promise<any>( ( resolve, reject ) => {
      this.http.get( this.API_URL + 'GetAllStationary', this.httpOptions )
        .subscribe( response => {
          if ( response ) {
            resolve( response );
          }
        } )
    } );
  }

  //Placement
  GetAllPlacement(){
    return new Promise<any>( ( resolve, reject ) => {
      this.http.get( this.API_URL + 'GetAllPlacement', this.httpOptions )
        .subscribe( response => {
          if ( response ) {
            resolve( response );
          }
        } )
    } );
  }


  StudentBuyCourse(courseId: number, studentUserId: number): Observable<ApiResponse> { 
    // const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
    const body = { StudentUserId: studentUserId, CourseId: courseId };
    return this.http.post<ApiResponse>(this.API_URL+"StudentBuyCourse", JSON.stringify(body), this.httpOptions).pipe(
    //tap(data => console.log("StudentBuyCourse: " + JSON.stringify(data))),
    catchError(this.handleError)
  );
  }

  public POSTWITHATTACHMENTS(action, data: FormData) {

    return this.http.post<ApiResponse>(this.API_URL+action, data).pipe(
      //tap(data => console.log("POST-"+action+": "+ JSON.stringify(data))),
      catchError(this.handleError));
  }


  
  uploadFile(action: string, obj: any) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<FileToUpload>(this.API_URL+action, obj, this.httpOptions);
}

GetStudentCourseById(studentUserId){
  return new Promise<any>( ( resolve, reject ) => {
    this.http.get( this.API_URL + 'GetStudentCourse?Id='+studentUserId, this.httpOptions )
      .subscribe( response => {
        if ( response ) {
          resolve( response );
        }
      } )
  } );
}


GetNotification(count: number){
    
  return this.http.get<ApiResponse>(this.API_URL+"GetAllNotification?StudentUserId="+this.currentUser.id+"?Type='Web'"+"?Count="+count, this.httpOptions).pipe(
    //tap(data => console.log("GET-"+action+": "+ JSON.stringify(data))),
    catchError(this.handleError)
  );
}

}
