
    <!-- Header Layout -->
    <div class="mdk-header-layout js-mdk-header-layout">

        <div class="page__header">
            <div class="navbar navbar-dark bg-primary navbar-expand-sm d-none2 d-md-flex2">
                <div class="container-fluid">
                    <!-- Toggle sidebar -->
                    <button class="navbar-toggler d-block" data-toggle="sidebar" type="button">
                        <span class="material-icons">menu</span>
                    </button>





                </div></div>
        </div>

    <!-- Header Layout Content -->
    <div class="mdk-header-layout__content">

        <div data-push data-responsive-width="992px" class="mdk-drawer-layout js-mdk-drawer-layout">
            <div class="mdk-drawer-layout__content page pt-0">

               
                

               

            </div>




            <div class="mdk-drawer js-mdk-drawer" id="default-drawer">
                <div class="mdk-drawer__content ">
                    <div class="sidebar sidebar-left sidebar-dark bg-dark o-hidden" data-perfect-scrollbar>
                        <div class="sidebar-p-y">
                            <div class="sidebar-heading">APPLICATIONS</div>
                            <ul class="sidebar-menu sm-active-button-bg">
                                <li class="sidebar-menu-item active">
                                    <a class="sidebar-menu-button" href="Homepage.html">
                                        <i class="sidebar-menu-icon sidebar-menu-icon--left material-icons">dashboard</i> Dashboard
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    </div>
</div>