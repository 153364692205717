import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { FileExtension } from 'src/app/models/file-extension';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { Notification } from 'src/app/models/notification';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';


declare var jQuery: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  loaderPlay = false;
  currentUser: User;
  LoginUser;
  
  IsLoaderShow:boolean = true;
  modal = new  Modal();
  subscription: Subscription;
  subscriptionModal: Subscription;
  
  fileExtensions = new FileExtension();

  intervalId: number;

  latestNotification = new Notification();
  lastNotificationDateTime: any;
  listNotification: Notification[] = [];
  IsNewNotification:boolean = false;

  response : ApiResponse = null;
  private connection: signalR.HubConnection;

  p: number = 1;

  constructor(
    private router: Router,
    private authService: AuthService, private context: DataService, private datepipe: DatePipe, private signalr: SignalrService
  ) {
      this.currentUser = this.authService.CurrentUser();

      if(this.currentUser.attachmentPath == "" || this.currentUser.attachmentPath == null){
        this.currentUser.attachmentPath = this.authService.FillEmptyImage(true);
      }

      this.LoginUser = this.authService.CurrentLoginUser();
      
      this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
      this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);
     }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  ngOnInit(): void {

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    // this.signalr.startConnection();

    this.GetNotification();

    
    (function ($) {
      $(document).ready(function(){
        
      var connection = $.hubConnection(environment.API_Host, '', false, {xdomain: true});
        
      var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);
      
      hubProxy.on('receive', function(message) {
        
        $("#btnNotify").click();

        });

      connection.start().then(function () {
          
        }).catch(function (err) {
            return console.error(err);
        });

        
      connection.disconnected(function () {
          connection.start();
      });

    });
  })(jQuery);


  }


  
  GetNotification(){
    
    this.authService.IsShowLoader(true);

    this.context.GET("GetAllNotification?StudentUserId="+this.currentUser.id).subscribe(data => {
      this.response=data;
      
      if(this.response.status_code == 1){
          this.listNotification = this.response.data1;
      }
      else{
        this.listNotification = null;
      }

      this.authService.IsShowLoader(false);
    });
  }

}
