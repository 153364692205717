import { Component, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ApiResponse } from '../../models/api-response';
import { Task } from '../../models/task';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  NgForm,
} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FileToUpload } from 'src/app/models/file-to-upload';
// import { Track } from 'ngx-audio-player';
declare var jQuery: any;
// import * as $ from 'jquery';
import { Modal } from 'src/app/models/modal';
import { interval, Subscription } from 'rxjs';
import { Answer } from 'src/app/models/answer';
import { FileExtension } from 'src/app/models/file-extension';
import { environment } from 'src/environments/environment';
import { elementAt } from 'rxjs/operators';
// import { flatten } from '@angular/compiler';
// import { truncate } from 'fs';
import { DomSanitizer } from '@angular/platform-browser';
import { canvasToBlob } from 'blob-util';
Pipe({
  name: 'safe',
});

// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css'],
})
export class TaskComponent implements OnInit {
  pdfSource = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';

  dvTaskContentHeight;
  TaskListHeight;
  response: ApiResponse = null;
  taskId: number;
  courseId: number;
  moduleId: number;
  public taskModel = new Task();
  public SendTaskModel = new Task();
  public listTask: Task[] = [];
  // files:string  []  =  [];
  // file: File;
  fileList: [];
  taskForm: FormGroup;
  currentUser: User = null;
  Submitted: boolean = false;
  lastAnswerId: number = 0;
  theFile: any = null;
  messages: string[] = [];

  ans: Answer;

  fileToUpload: File;

  fileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();
  loaderPlay = false;

  videoContent: any;
  audioContent: any;
  pdfContent: any;

  IsVideoContent: boolean;
  IsAudioContent: boolean;
  IsPdfContent: boolean;

  Videolink: string;
  Videolinks: any;
  Audiolink: string;
  Pdflink: string;
  Pdflinks: any;

  videoFile = '';
  PdfFile = '';
  AudioFile = '';

  IsLoaderShow: boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  fileExtensions = new FileExtension();
  sanitizer;
  IsTaskModalShow: boolean = false;

  constructor(
    private s: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private context: DataService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.taskId = this.activatedRoute.snapshot.params.TaskId;

    this.courseId = this.activatedRoute.snapshot.params.CourseId;
    this.moduleId = this.activatedRoute.snapshot.params.ModuleId;

    // var url = "http://admin.flagshipdesignacademy.in//Uploads/Task/How_to_cut_paper_with_cutter_FDA.mp4"

    // this.Videolinks=this.s.bypassSecurityTrustResourceUrl(url);

    this.sanitizer = s;
  }

  ngOnInit(): void {
    this.authService.ScrollOverflow();

    this.subscription = this.authService.currentMessage.subscribe(
      (IsLoaderShow) => (this.IsLoaderShow = IsLoaderShow)
    );
    this.subscriptionModal = this.authService.modalViewObj.subscribe(
      (modal) => (this.modal = modal)
    );

    (function ($) {
      $(document).ready(function () {
        window.scrollTo(0, 0);
        $('#mainBody').css('overflow', 'hidden');
      });
      $('#pdfViewer').on('click', function () {
        $('#apdfView').trigger('click');
      });

      var connection = $.hubConnection(environment.API_Host, '', false, {
        xdomain: true,
      });
      var connection1 = $.hubConnection(environment.API_Host, '', false, {
        xdomain: true,
      });

      var hubProxy = connection.createHubProxy(environment.API_SignalR_Hub);

      hubProxy.on('receiveTaskComplete', function (message) {
        $('#notificationtune').get(0).play();
        $('#btnTaskCompleteNotify').click();
      });

      hubProxy.on('receiveTaskDiscussion', function (message) {
        $('#notificationtune').get(0).play();
        $('#btnTaskCompleteNotify').click();
      });

      connection
        .start()
        .then(function () {
          $('#btnInsertTaskDiscussion').click(function () {});
        })
        .catch(function (err) {
          return console.error(err.toString());
        });

      connection1
        .start()
        .then(function () {
          $('#btnInsertTaskDiscussion').click(function () {});
        })
        .catch(function (err) {
          return console.error(err.toString());
        });

      connection.disconnected(function () {
        connection.start();
      });

      connection1.disconnected(function () {
        connection1.start();
      });
    })(jQuery);

    // $('#dvPreview').show();     This code is use for open model popup of preview
    this.dvTaskContentHeight = window.innerHeight - 150;
    this.TaskListHeight = window.innerHeight - 130;

    this.currentUser = this.authService.currentUserValue;
    if (this.currentUser == null) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: 'home' } });
    }

    this.taskModel.Id = this.taskId;
    this.taskModel.ModuleId = this.moduleId;
    this.taskModel.CourseId = this.courseId;

    this.taskForm = this.formBuilder.group({
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      attachments: [''],
    });

    let obj = {
      StudentUserId: this.currentUser.id,
      TaskId: Number(this.taskId),
      CourseId: Number(this.courseId),
      ModuleId: Number(this.moduleId),
    };

    this.GetTaskDetails(obj);

    // const source = interval(10000);
    // //const text = 'Your Text Here';
    // // this.subscription = source.subscribe(val => this.opensnack(text));
    // this.subscription = source.subscribe(val => this.GetTaskDetails(obj));

    // const source = interval(60000);
    // //const text = 'Your Text Here';
    // // this.subscription = source.subscribe(val => this.opensnack(text));
    // this.subscription = source.subscribe(val => this.GetOnlyTaskMessage(obj));
  }

  TaskClickEvent(taskId: number, moduleId: number, courseId: number) {
    this.taskId = taskId;

    for (var i = 0; i < this.listTask.length; i++) {
      if (this.listTask[i].Id === taskId) {
        if (this.listTask[i].IsHidden == true) {
          return false;
        }
      }
    }

    let obj = {
      StudentUserId: this.currentUser.id,
      TaskId: Number(taskId),
      CourseId: Number(courseId),
      ModuleId: Number(moduleId),
    };

    this.GetTaskDetails(obj);
    // this.router.navigate(['/task'], { queryParams: { returnUrl: "home" } });
    // window.location.href =
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  showModal(AttachmentPath) {
    this.modal = new Modal();

    this.modal.IsModalShow = true;
    this.modal.Title = 'Attachment';

    if (AttachmentPath != null && AttachmentPath != '') {
      this.modal.IsInnerHTML = true;

      //this.modal.Body = "<div class=\"embed-responsive embed-responsive-16by9\"><iframe class=\"embed-responsive-item\" src=\""+AttachmentPath+"\" allowfullscreen></iframe></div>";
      this.modal.Body = AttachmentPath;
    }

    this.authService.IsShowModal(this.modal);
  }

  ShowAlertModal() {
    this.modal = new Modal();

    this.modal.IsAlertModalShow = true;
    this.modal.Title = 'Attachment';

    this.modal.IsPLANTEXT = true;

    this.modal.Body = 'Please, complete previous task first.';

    this.authService.IsShowModal(this.modal);
  }

  GetOnlyTaskMessage(obj: any) {
    this.context.POST('GetTaskDetails/', obj).subscribe((data) => {
      this.response = data;
      if (this.response.status_code == 1) {
        this.taskModel.Answers = this.response.data1.Answers;

        if (
          this.taskModel.Answers != null &&
          this.taskModel.Answers.length > 0
        ) {
          this.ans = this.taskModel.Answers[this.taskModel.Answers.length - 1];

          if (this.currentUser.id == this.ans.CreatedBy) {
            this.lastAnswerId = this.ans.Id;
          } else {
            this.lastAnswerId = 0;
          }
        }

        if (this.taskModel.IsCompleted != this.response.data1.IsCompleted) {
          this.listTask = this.response.data2;
          this.taskModel.IsCompleted = this.response.data1.IsCompleted;
          this.IsTaskModalShow = true;
        }
      }
    });
  }

  GetTaskCompleteDetails() {
    let obj = {
      StudentUserId: this.currentUser.id,
      TaskId: Number(this.taskId),
      CourseId: Number(this.courseId),
      ModuleId: Number(this.moduleId),
    };

    this.GetOnlyTaskMessage(obj);
  }

  GetTaskDetails(obj: any) {
    $('#taskvideo').attr('src', '');
    //this.loaderPlay = true;
    this.authService.IsShowLoader(true);

    this.context.POST('GetTaskDetails/', obj).subscribe((data) => {
      this.response = data;
      if (this.response.status_code == 1) {
        this.authService.IsShowLoader(false);

        this.authService.IsShowLoader(true);
        this.taskModel = this.response.data1;

        this.taskModel.ModuleId = this.moduleId;
        this.taskModel.CourseId = this.courseId;
        this.taskModel.StudentUserId = this.currentUser.id;
        this.listTask = this.response.data2;

        this.taskModel.Answers = this.response.data1.Answers;

        if (
          this.taskModel.Answers != null &&
          this.taskModel.Answers.length > 0
        ) {
          this.ans = this.taskModel.Answers[this.taskModel.Answers.length - 1];

          if (this.currentUser.id == this.ans.CreatedBy) {
            this.lastAnswerId = this.ans.Id;
          } else {
            this.lastAnswerId = 0;
          }
        }

        for (
          let index = 0;
          index < this.taskModel.videoTaskMappingViewModels.length;
          index++
        ) {
          this.taskModel.videoTaskMappingViewModels[index].VideoLink =
            this.taskModel.videoTaskMappingViewModels[index].VideoLink +
            '?byline=0&portrait=0&title=0';
        }

        this.IsVideoContent = false;
        this.IsAudioContent = false;
        this.IsPdfContent = false;
        $('#divVideo').hide();
        $('#divAudio').hide();
        $('#divPDF').hide();

        if (
          this.taskModel.AttachmentPathList != null &&
          this.taskModel.AttachmentPathList.length > 0
        ) {
          for (var i = 0; i < this.taskModel.AttachmentPathList.length; i++) {
            if (
              this.fileExtensions.VIDEO.indexOf(
                this.taskModel.AttachmentPathList[i].substring(
                  this.taskModel.AttachmentPathList[i].lastIndexOf('.') + 1
                )
              ) > -1
            ) {
              this.Videolink = this.taskModel.AttachmentPathList[i];
              this.Videolinks = this.s.bypassSecurityTrustResourceUrl(
                this.Videolink
              );
              $('#taskvideo').attr(
                'src',
                this.Videolinks.changingThisBreaksApplicationSecurity
              );
              this.IsVideoContent = true;
              $('#divVideo').show();
            } else if (
              this.fileExtensions.AUDIO.indexOf(
                this.taskModel.AttachmentPathList[i].substring(
                  this.taskModel.AttachmentPathList[i].lastIndexOf('.') + 1
                )
              ) > -1
            ) {
              this.Audiolink = this.taskModel.AttachmentPathList[i];
              // this.Audiolink = "data:audio/"+this.taskModel.Attachments[i].FileExtension.replace('.','')+";base64,"+this.taskModel.Attachments[i].FileByte;
              this.IsAudioContent = true;
              $('#divAudio').hide();
              //console.log(this.Audiolink);
            } else if (
              this.fileExtensions.PDF.indexOf(
                this.taskModel.AttachmentPathList[i].substring(
                  this.taskModel.AttachmentPathList[i].lastIndexOf('.') + 1
                )
              ) > -1
            ) {
              this.Pdflink = this.taskModel.AttachmentPathList[i];
              this.Pdflinks = this.s.bypassSecurityTrustResourceUrl(
                this.Pdflink
              );
              this.IsPdfContent = true;
              $('#divPDF').hide();
            }
          }
        }
        this.authService.IsShowLoader(false);
        this.authService.ScrollOverflow();
      }
    });
  }

  onFileChange(event) {
    //this.fileUploads = [];
    let count = 0;

    if (this.fileUploads != null) {
      count = this.fileUploads.length;
    }

    if (event.target.files && event.target.files.length > 0) {
      this.fileList = event.target.files;
      for (var i = 0; i < event.target.files.length; i++) {
        count++;
        this.fileUpload = new FileToUpload();
        this.fileUpload.fileId = String(count);
        this.fileUpload.fileSize = event.target.files[i].size;
        this.fileUpload.fileName = event.target.files[i].name;
        this.fileUpload.file = event.target.files[i];

        this.fileUploads.push(this.fileUpload);
      }
    }
  }

  removeFile(fileId: string) {
    for (var i = 0; i < this.fileUploads.length; i++) {
      if (this.fileUploads[i].fileId === fileId) this.fileUploads.splice(i, 1);
    }
  }

  // onFileChange(event) {
  //   if (event.target.files.length > 0) {
  //     const files = event.target.files;
  //     this.taskForm.get('attachments').setValue(files);
  //   }
  // }
  // // onFileChange(event)  {
  // //   debugger;

  // //   this.fileList = null;
  // //   this.fileList = event.target.files;
  // //   // this.files =  [];
  // //   // for  (var i =  0; i <  event.target.files.length; i++)  {
  // //   //     this.files.push(event.target.files[i]);
  // //   // }
  // // }

  // onSubmit() {
  //   console.log('Your form data : ', this.taskForm.value );
  //   console.log('Your files : ', this.files );

  //   const formData =  new  FormData();
  //   for  (var i =  0; i <  this.files.length; i++)  {
  //       formData.append("file[]",  this.files[i]);
  //   }
  // }

  onSubmit() {
    //this.loaderPlay = true;

    //this.authService.IsShowLoader(true);
    const formData = new FormData();

    if (this.fileUploads != null) {
      for (var i = 0; i < this.fileUploads.length; i++) {
        formData.append('file' + i, this.fileUploads[i].file);
      }
    }

    this.taskModel.Comment = this.taskForm.get('comment').value;
    this.taskModel.TaskId = this.taskId;

    this.taskModel.StudentUserId = this.currentUser.id;

    /// Below code is use for send task details
    this.SendTaskModel = this.taskModel;
    // this.SendTaskModel.ModuleId = this.taskModel.ModuleId;
    // this.SendTaskModel.CourseId = this.taskModel.CourseId;
    this.SendTaskModel.Comment = this.taskForm.get('comment').value;
    this.SendTaskModel.TaskId = this.taskId;

    this.SendTaskModel.StudentUserId = this.currentUser.id;
    this.SendTaskModel.TaskDetails = '';

    formData.append('objViewSTR', JSON.stringify(this.SendTaskModel));

    if (this.SendTaskModel.Comment == null && this.fileUploads == null) {
      // this.authService.IsShowLoader(false);
      return false;
    }

    if (
      this.SendTaskModel.Comment == null &&
      this.fileUploads != null &&
      this.fileUploads.length == 0
    ) {
      // this.authService.IsShowLoader(false);
      return false;
    }

    // if(this.taskModel.Comment != null && this.taskModel.Comment.length > 0)
    // {

    this.taskForm = this.formBuilder.group({
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      attachments: [''],
    });

    this.fileUploads = [];

    this.context
      .POSTWITHATTACHMENTS('InsertTaskDetails', formData)
      .subscribe((data) => {
        this.response = data;

        if (this.response.status_code == 1) {
          this.Submitted = true;

          // this.authService.IsShowLoader(true);

          //alert("Task data is submitted.");

          this.taskModel = new Task();

          this.taskModel = this.response.data1;
          this.taskModel.ModuleId = this.moduleId;
          this.taskModel.CourseId = this.courseId;
          this.taskModel.StudentUserId = this.currentUser.id;
          this.listTask = this.response.data2;

          if (
            this.taskModel.Answers != null &&
            this.taskModel.Answers.length > 0
          ) {
            this.ans =
              this.taskModel.Answers[this.taskModel.Answers.length - 1];

            if (this.currentUser.id == this.ans.CreatedBy) {
              this.lastAnswerId = this.ans.Id;
            } else {
              this.lastAnswerId = 0;
            }
          }

          // let element: HTMLElement = document.getElementById('btnInsertTaskDiscussion') as HTMLElement;
          // element.click();

          // this.authService.IsShowLoader(false);

          // this.fileUploads = [];
          // this.taskModel.Comment = "";
          // //this.taskForm.setValue({comment: ""});
          // $('#comment').val('');

          // $('#messages').height(0);
          //   var objDiv = document.getElementById("messages");
          // objDiv.scrollTop = $('#messages').innerHeight();
        } else {
          // this.taskModel = null;
          // this.listTask = null;
          // this.authService.IsShowLoader(false);
        }

        // this.authService.IsShowLoader(false);

        // this.fileUploads = [];
        // this.taskModel.Comment = "";
        // //this.taskForm.setValue({comment: ""});
        // $('#comment').val('');
      });

    // this.fileUploads = [];
    // this.taskModel.Comment = "";
    // //this.taskForm.setValue({comment: ""});
    // $('#comment').val('');

    // }
    // else{
    //   return false;
    // }

    //this.loaderPlay = false;
  }

  postMethod(files: FileList) {
    this.fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    formData.append('Id', '1');

    this.http
      .post('http://localhost:43210/api/FlagshipApi/UploadFiles', formData)
      .subscribe((val) => {});
    return false;
  }

  resetForm(form: NgForm) {
    form.resetForm();
  }

  matchUserId(studentUserId, currentUserId) {
    if (studentUserId == currentUserId) {
      return true;
    } else {
      return false;
    }
  }

  GetDateTime(dateTime, date) {
    var ConvertDateTime = new Date(dateTime);
    return date + ' ' + ConvertDateTime.toLocaleTimeString();
  }

  UseFillDefaultAttach() {
    return this.authService.FillEmptyImage(false);
  }

  // GetVideo(vfileName){
  //   debugger;
  //   return vfileName;
  // }

  CheckCourse(taskID, ThisTaskId) {
    if (taskID == ThisTaskId) {
      return true;
    } else {
      return false;
    }
  }

  RemoveLastAnswer(id: number) {
    // this.authService.ScrollOverflow();
    //this.loaderPlay = true;

    this.authService.IsShowLoader(true);

    this.context
      .GET(
        'RemoveTaskAnswer?StudentUserId=' +
          this.currentUser.id +
          '&TaskAnswerId=' +
          id
      )
      .subscribe((data) => {
        this.response = data;

        this.authService.IsShowLoader(false);

        if (this.response.status_code == 1) {
          // for  (var j =  0; j < this.taskModel.Answers.length; j++) {
          //   if (this.taskModel.Answers[j].Id === id){
          //     this.taskModel.Answers.splice(j, 1);
          // }
          // }

          let obj = {
            StudentUserId: this.currentUser.id,
            TaskId: Number(this.taskId),
            CourseId: Number(this.courseId),
            ModuleId: Number(this.moduleId),
          };

          this.GetTaskDetails(obj);
        }

        //this.loaderPlay = false;
      });
    //this.loaderPlay = false;
  }

  LoadPdf(src) {
    var file = new File(['foo'], 'foo.txt', {
      type: 'text/plain',
    });
    $('#PDFView').attr('src', src);
  }

  loadVideo(src) {
    // $('#ifTaskVideo').attr('src',src);
    //window.location.href = window.location.href;
    return src;
  }

  ConvertBlobUrl(fileArray) {
    var binary_string = window.atob(fileArray);
    var len = binary_string.length;
    var uint8Array = new Uint8Array(len);
    var arrayBuffer = uint8Array.buffer;
    var blob = new Blob([arrayBuffer]);
    // var mediaSource = new MediaSource(blob);

    return URL.createObjectURL(blob);
  }

  clickModalClose() {
    this.IsTaskModalShow = false;
  }

  onRightClick(event) {
    event.preventDefault();
  }

  error() {
    debugger;
  }
}
