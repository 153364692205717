<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
    <img src="assets/images/imageLoading.gif" alt="Alt Image"
        style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>

<button id="btnTaskCompleteNotify" type="button" style="display: none;"
    (click)="GetTaskCompleteDetails()">NotifyTaskComplete</button>


<button id="btnInsertTaskDiscussion" type="button" style="display: none;">NotifyTaskComplete</button>
<button id="btnTaskDiscussionNotify" type="button" style="display: none;">NotifyTaskDiscussion</button>
<div class="mdk-drawer-layout__content page " style="padding-top:0px;">
    <div class="card" style="margin-bottom: 0.5rem;">
        <div class="card-body" style="padding-top:17px !important;padding-bottom:10px !important;">
            <div class="media flex-wrap align-items-center">
                <div class="media-left">
                    <!-- <h3 class="h3">Task Detail</h3> -->
                    <h3 class="h3">Chapter</h3>
                </div>
                <div class="media-body"> &nbsp; </div>
                <div class="media-right">
                    <a class="btn btn-primary handCursor" [routerLink]="['/course/details/',courseId]">Back to
                        Module</a>
                </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="input-group input-group-xl">
                    <div class="col-md-10">
                        <h3 class="h3 mb-2 col-md-10">Task Detail</h3>
                    </div>
                    <div class="col-md-2 text-right" style="padding: 0px;">
                        <a class="btn btn-primary handCursor" [routerLink]="['/course/details/',courseId]">Back to Module</a>
                    </div>
                </div>
            </div> -->

        </div>
    </div>





    <div class="row m-0">
        <div class="col-lg container-fluid page__container" style="padding: 0px;">
            <div class="row d-flex" *ngIf="taskModel == null">
                <div class="card border-left-3 border-left-primary card-2by1 col-md-12">
                    <div class="card-body">
                        <div class="media flex-wrap align-items-center">
                            <div class="media-body">
                                You have not purchased any course or approval is pending. Please contact the
                                administrator.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex" *ngIf="taskModel != null">

                <div class="col-md-8">

                    <div class="card card-2by1">
                        <div class="card-header">
                            <h4 class="card-title">{{taskModel.TaskTitle}}</h4>
                            <!-- <p class="card-subtitle">Your latest achievements</p> -->

                        </div>
                    </div>

                    <div class="card card-2by1" *ngIf="IsVideoContent == true">
                        <div class="card-header">
                            <h4 class="card-title">Video</h4>
                        </div>
                        <div class="card-body text-center">
                            <div class="row mb-3" id="dvVideo">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <video id="taskvideo" controls controlsList="nodownload" height="30px" width="30px" class="embed-responsive-item" allowfullscreen=""
                                            style="border-radius: 10px !important;">
                                            <source [src]="Videolinks">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-2by1" *ngIf="taskModel.videoTaskMappingViewModels != null && taskModel.videoTaskMappingViewModels.length > 0">
                        <div class="card-header">
                            <h4 class="card-title">Video</h4>
                        </div>
                        <div class="card-body text-center">
                            <div class="row mb-3" id="dvVideo">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4"
                                    *ngIf="taskModel.videoTaskMappingViewModels != null && taskModel.videoTaskMappingViewModels.length > 0">

                                    <div class="card"
                                        *ngFor="let video of taskModel.videoTaskMappingViewModels;let i = index">
                                        <div id="headingOne" class="card-header bg-white shadow-sm border-0">
                                            <h6 class="mb-0 font-weight-bold text-left"><a href="#" data-toggle="collapse"
                                                    [attr.data-target]="'#collapse' +i"
                                                    [attr.aria-controls]="'#collapse' +i"
                                                    class="d-block position-relative text-dark text-uppercase collapsible-link p-0"
                                                    style="text-decoration: none;padding-right: 15%;">
                                                    {{video.Name}} &nbsp;
                                                    <span class="badge badge-primary"></span></a></h6>
                                        </div>
                    
                                        <div [attr.id]="'collapse' +i" class="collapse">
                                            <div class="card-body">
                                                <iframe class="vimeoHeight" [src]="this.s.bypassSecurityTrustResourceUrl(video.VideoLink)"
                                                    width="100%" frameborder="0" allow="autoplay; fullscreen" title="false"
                                                    allowfullscreen></iframe>
                                            </div>

                                        </div>
                                      
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="card card-2by1" *ngIf="IsAudioContent == true">
                        <div class="card-header">
                            <h4 class="card-title">Audio</h4>
                        </div>
                        <div class="card-body text-center">
                            <div class="row mb-3" id="dvAudio">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="">
                                        <audio id="taskAudioFile" class="form-control"
                                            style="height:60px;border-radius: 10px;" [src]="this.Audiolink" controls
                                            controlsList="nodownload"></audio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-2by1" *ngIf="IsPdfContent == true" (contextmenu)="onRightClick()">
                        <div class="card-header">
                            <h4 class="card-title">PDF</h4>
                        </div>
                        <div class="card-body text-center">
                            <div class="row mb-3" id="dvPdf">
                                <!-- <pdf-viewer
  [src]="'https://fdafiles.s3.us-east-2.amazonaws.com/Uploads/Task/0.1%20and%201.1_INTRODUCTION%20OF%20INTERIOR%20DESIGN%20&%20INTRODUCTION%20OF%20STATIONERY.pdf'"
  [render-text]="true"
  [original-size]="false"
  style="width: 800px; height: 600px"
></pdf-viewer> -->
                              <!-- <a target="blank" [href]="Pdflinks" id="apdfView" class="col-lg-12"></a> -->
                                <!--<iframe id="PDFView" [src]="Pdflinks" name="PDFView" src="" width="100%"
                                        height="350vh">
                                </iframe>-->

                                <!-- Bind direct url in src instead of safe url -->
                                <pdf-viewer [src]="Pdflink"
                                            [render-text]="true"
                                            [original-size]="true"
                                            style="width: 100%; height: 500px"></pdf-viewer>
                              

                                <!-- <div class="col-lg-12">
                                    <span>If you can't read properly please download pdf from here.</span>
                                    <a target="blank" [href]="Pdflinks"
                                        class="btn btn-primary text-white p-1">Download</a>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="row ml-3">
                        <div class="card col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3">
                            <div class="">
                                <h4>Chapter Exercise</h4>
                                <hr />
                                <p style="overflow-wrap: anywhere;" [innerHtml]="taskModel.TaskDetails"></p>
                            </div>
                        </div>

                    </div>


                    <div class="row m-0">
                        <div class="app-messages__container d-flex flex-column h-100 w-100 card">

                            <div class="SetMsgHeight">
                                <div class="navbar navbar-light bg-white navbar-expand-sm navbar-shadow z-1">
                                    <div class="container flex-wrap px-sm-0">
                                        <div class="nav py-2">
                                            <div class="nav-item align-items-center mr-3">

                                                <div class="d-flex flex-column"
                                                    style="max-width: 200px; font-size: 15px">
                                                    <h4>Chapter Discussion</h4>
                                                </div>

                                                <!-- <div class="d-flex flex-column"
                                                    style="max-width: 200px; font-size: 15px">
                                                    <strong class="text-body">{{this.currentUser.firstname}}
                                                        {{this.currentUser.lastname}}</strong>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="py-2 flex d-flex align-items-center">
                                        </div>
                                    </div>
                                </div>
                                <div class="flex pt-4" style="position: relative;" data-perfect-scrollbar>
                                    <div class="container page__container">

                                        <ng-container
                                            *ngIf="taskModel.Answers != null && taskModel.Answers.length > 0; then AvailableMessage; else NotAvailableMessage">
                                        </ng-container>
                                        <ng-template #AvailableMessage>

                                            <ul class="d-flex flex-column list-unstyled" id="messages">

                                                <li class="message d-inline-flex"
                                                    *ngFor="let answer of taskModel.Answers;let i = index">
                                                    <!-- <ng-container *ngIf="answer.StudentUserId === this.currentUser.id;then Have else HaveNot"></ng-container> -->
                                                    <ng-container
                                                        *ngIf="matchUserId(answer.CreatedBy,this.currentUser.id); else HaveNot">
                                                        <div class="order-right">
                                                            <a class="avatar">
                                                                <img [src]="answer.UserImagePath" alt="people"
                                                                    class="avatar-img rounded-circle">
                                                            </a>
                                                        </div>
                                                        <div class="card margin-left-auto">
                                                            <div class="card-body bg-f2fff3">
                                                                <div class="media align-items-start">
                                                                    <div class="media-body">
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="flex mr-3">
                                                                                <a href="javascript: void(0);"
                                                                                    class="text-body">
                                                                                    <strong>{{answer.Name}}</strong>
                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <small
                                                                                    class="text-muted">{{GetDateTime(answer.CreatedDate,answer.SubmitDateTime)}}</small>
                                                                            </div>
                                                                        </div>
                                                                        <span
                                                                            class="text-black-70">{{answer.Comment}}</span>
                                                                        <ng-container
                                                                            *ngIf="answer.AttachmentPathList != null; then Available; else NotAvailable">
                                                                        </ng-container>
                                                                        <ng-template #Available>
                                                                            <div class="text-right mb-0"
                                                                                style="min-width: 100px;"
                                                                                *ngFor="let attachment of answer.AttachmentPathList">
                                                                                <!-- <span *ngIf="fileExtension.IMAGE.indexOf(attachment.substring(attachment.lastIndexOf('.') + 1)) > -1"> -->
                                                                                <a href="javascript: void(0);"
                                                                                    class="btn btn-outline-primary btn-sm"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title="{{attachment.substring(attachment.lastIndexOf('/') + 1)}}"
                                                                                    style="font-size: .6203125rem !important;"
                                                                                    (click)="showModal(attachment);">
                                                                                    <i class="fas fa-file"></i> &nbsp;
                                                                                    {{attachment.substring(0,
                                                                                    attachment.lastIndexOf('.')).substring(attachment.lastIndexOf('/')
                                                                                    + 1).slice(0,30)}}....{{attachment.substring(attachment.lastIndexOf('.')
                                                                                    + 1)}}
                                                                                </a>
                                                                                <br />
                                                                                <!-- </span> -->
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template #NotAvailable>
                                                                        </ng-template>

                                                                    </div>

                                                                    <div class="media-right border-left border-dark pl-2"
                                                                        *ngIf="answer.Id == lastAnswerId && taskModel.IsCompleted == false">
                                                                        <a href="javascript:void(0)"
                                                                            (click)="RemoveLastAnswer(answer.Id);">
                                                                            <i class="fas fa-trash"
                                                                                style="color:red"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #HaveNot>
                                                        <div class="order-left">
                                                            <a class="avatar">
                                                                <img [src]="answer.UserImagePath" alt="people"
                                                                    class="avatar-img rounded-circle">
                                                            </a>
                                                        </div>
                                                        <div class="margin-left-0 card">
                                                            <div class="card-body bg-f1fbff">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="flex mr-3">
                                                                        <a class="text-body">
                                                                            <strong>{{answer.Name}}</strong>
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <!-- <small class="text-muted">1 hour ago</small> -->
                                                                        <small
                                                                            class="text-muted">{{GetDateTime(answer.CreatedDate,answer.SubmitDateTime)}}</small>

                                                                    </div>
                                                                </div>
                                                                <span class="text-black-70">{{answer.Comment}}</span>

                                                                <ng-container
                                                                    *ngIf="answer.AttachmentPathList != null; then OtherAvailable; else NotOtherAvailable">
                                                                </ng-container>
                                                                <ng-template #OtherAvailable>
                                                                    <div class="text-right mb-0"
                                                                        style="min-width: 100px;"
                                                                        *ngFor="let attachment of answer.AttachmentPathList">
                                                                        <!-- <span *ngIf="fileExtension.IMAGE.indexOf(attachment.substring(attachment.lastIndexOf('.') + 1)) > -1"> -->
                                                                        <a href="javascript: void(0);"
                                                                            (click)="showModal(attachment)"
                                                                            class="btn btn-outline-primary btn-sm"
                                                                            data-toggle="tooltip" data-placement="top"
                                                                            title="{{attachment.substring(attachment.lastIndexOf('/') + 1)}}"
                                                                            style="font-size: .6203125rem !important;">
                                                                            <i class="fas fa-file"></i> &nbsp;
                                                                            {{attachment.substring(0,
                                                                            attachment.lastIndexOf('.')).substring(attachment.lastIndexOf('/')
                                                                            + 1).slice(0,
                                                                            30)}}....{{attachment.substring(attachment.lastIndexOf('.')
                                                                            + 1)}}
                                                                        </a>
                                                                        <br />
                                                                        <!-- </span> -->
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template #NotOtherAvailable>
                                                                </ng-template>

                                                                <!-- <ng-container *ngIf="answer.Id == lastAnswerId; then LastAnswer; else NotLastAnswer"></ng-container>
                                                                <ng-template #LastAnswer>
                                                                    <div class="media-right border-left border-dark pl-2">
                                                                        <a href="javascript:void(0)" (click)="RemoveLastAnswer(answer.Id);">
                                                                            <i class="fas fa-trash" style="color:red"></i>
                                                                        </a>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template #NotLastAnswer>
                                                                </ng-template> -->

                                                                <!--
                                                                <div class="media-right border-left border-dark pl-2" *ngIf="answer.Id == lastAnswerId">
                                                                    <a href="javascript:void(0)" (click)="RemoveLastAnswer(answer.Id);">
                                                                        <i class="fas fa-trash" style="color:red"></i>
                                                                    </a>
                                                                </div> -->

                                                                <!-- <a href="#" class="media align-items-center mt-2 text-decoration-0 bg-white px-3">
                                                                                                        <span class="avatar mr-2">
                                                                                                            <span class="avatar-title rounded-circle">
                                                                                                                <i class="material-icons font-size-24pt">attach_file</i>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span class="media-body" style="line-height: 1.5">
                                                                                                            <span class="text-primary">draft.sketch</span>
                                                                                                            <br>
                                                                                                            <span class="text-muted">5 MB</span>
                                                                                                        </span>
                                                                                                    </a> -->

                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                        </ng-template>
                                        <ng-template #NotAvailableMessage>

                                            <div class="media flex-wrap align-items-center">
                                                <div class="media-body">
                                                    No any Discussion Available.
                                                </div>
                                            </div>

                                        </ng-template>

                                    </div>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="(taskModel.IsCompleted === true || taskModel.IsHidden === true ); then Available else NotAvailable">
                            </ng-container>
                            <ng-template #Available>

                                <!-- <div class="container page__container">
                                        <div class="mb-4 border-0 " style="text-align: center;">

                                            <span >Task Completed. Please move to next task</span>

                                        </div>
                                    </div> -->

                            </ng-template>
                            <ng-template #NotAvailable>
                                <div class="container page__container">
                                    <div class="card d-flex mb-4 border-0">
                                        <form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
                                            <div class="list-group list-group-fit">
                                                <div class="p-2">
                                                    <div role="group" aria-labelledby="label-question"
                                                        class="m-0 form-group">
                                                        <div class="form-row">
                                                            <div class="col-md-11">

                                                                <textarea name="comment" formControlName="comment"
                                                                    Id="comment" placeholder="Type Comment"
                                                                    class="form-control" rows="3" required>

                                                                        </textarea>

                                                                <!-- <input type="text" name="comment"
                                                                            formControlName="comment"
                                                                            placeholder="Type Comment" class="form-control"
                                                                            required /> -->
                                                            </div>
                                                            <div class="col-md-1">
                                                                <span class="btn fileinput-button">
                                                                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                                    <input type="file" multiple name="attachments"
                                                                        formControlName="attachments"
                                                                        (change)="onFileChange($event)"
                                                                        accept="image/jpeg, image/png, image/gif,application/pdf">
                                                                    <br />
                                                                </span>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <ul class="TaskFilesList">
                                                                    <ng-container
                                                                        *ngIf="fileUploads != null; then Available; else NotAvailable">
                                                                    </ng-container>
                                                                    <ng-template #Available>
                                                                        <li *ngFor="let attachment of fileUploads">
                                                                            <strong>{{attachment.fileName}}</strong>
                                                                            - {{attachment.fileSize}} bytes. &nbsp;
                                                                            &nbsp;
                                                                            <a href="javascript: void(0);"
                                                                                (click)="removeFile(attachment.fileId)"
                                                                                class="removeFile">Remove</a>
                                                                        </li>
                                                                    </ng-template>
                                                                    <ng-template #NotAvailable>
                                                                    </ng-template>

                                                                </ul>

                                                                <output id="Filelist"></output>

                                                            </div>



                                                            <div class="col-md-12">
                                                                <button type="submit"
                                                                    class="btn btn-primary">Submit</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="list-group-item pl-1 pr-1">
                                                            <div class="form-row align-items-center">
                                                                <div class="col-md-12">
                                                                    <ul class="TaskFilesList">
                                                                        <ng-container
                                                                            *ngIf="fileUploads != null; then Available; else NotAvailable">
                                                                        </ng-container>
                                                                        <ng-template #Available>
                                                                            <li *ngFor="let attachment of fileUploads">
                                                                                <strong>{{attachment.fileName}}</strong>
                                                                                - {{attachment.fileSize}}
                                                                                bytes. &nbsp; &nbsp;
                                                                                <a href="javascript: void(0);"
                                                                                    (click)="removeFile(attachment.fileId)"
                                                                                    class="removeFile">Remove</a>
                                                                            </li>
                                                                        </ng-template>
                                                                        <ng-template #NotAvailable>
                                                                        </ng-template>

                                                                    </ul>

                                                                    <output id="Filelist"></output>

                                                                </div>
                                                            </div>
                                                        </div> -->
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </ng-template>
                        </div>

                    </div>

                    <div class="row" *ngIf="taskModel.IsCompleted == true">
                        <div class="alert alert-success w-100 text-center" role="alert">
                            <h5 class="alert-heading">Well done!</h5>
                            <p>You successfully completed this task.</p>
                        </div>
                    </div>
                </div>

                <!-- Task list start -->

                <div class="col-md-4">

                    <div class="card">
                        <div class="card-header d-flex align-items-center">
                            <div class="flex">
                                <!-- <h4 class="card-title">Task List</h4> -->
                                <h4 class="card-title">Chapters List</h4>
                                <!-- <p class="card-subtitle">Module Name</p> -->
                            </div>
                        </div>
                        <ul class="list-group list-group-fit mb-0">
                            <li class="list-group-item" *ngFor="let task of listTask;">
                                <div class="media align-items-center"
                                    (click)="TaskClickEvent(task.Id, task.ModuleId, task.CourseId)">
                                    <div class="media-left">
                                        <div class="text-center">
                                            <i class="fas fa-clock" *ngIf="task.InProccess === true"
                                                style="color: orange;"></i>
                                            <i class="fas fa-check-circle" *ngIf="task.IsCompleted === true"
                                                style="color: green;"></i>
                                            <i class="fas fa-exclamation-circle" *ngIf="task.IsHidden === true"
                                                style="color: blue;"></i>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <a [routerLink]="['/task',task.Id, task.ModuleId, task.CourseId]"
                                            *ngIf="task.InProccess === true" class="text-body">
                                            <strong>{{task.TaskTitle}}</strong>
                                        </a>
                                        <a [routerLink]="['/task',task.Id, task.ModuleId, task.CourseId]"
                                            *ngIf="task.IsCompleted === true" class="text-body">
                                            <strong>{{task.TaskTitle}}</strong>
                                        </a>
                                        <a href="javascript: void(0);"
                                            [swal]="['Oops!', 'Please, complete previous task first.', 'error']"
                                            *ngIf="task.IsHidden === true" class="text-body">
                                            <strong>{{task.TaskTitle}}</strong>
                                        </a>
                                    </div>
                                    <!-- <div class="media-right">
                                        <small>{{task.InProccess}}</small>
                                        <small>{{task.IsCompleted}}</small>
                                        <small>{{task.IsHidden}}</small>
                                    </div> -->
                                </div>
                                <!-- <ng-container *ngIf="(task.InProccess === true); then InProccess else NotInProccess"></ng-container>
                                <ng-template #InProccess>
                                       <div class="media align-items-center">
                                            <div class="media-left">
                                                <div class="text-center">
                                                  <i class="fas fa-clock" style="color: orange;"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                              <a [routerLink]="['/task',task.Id, task.ModuleId, task.CourseId]" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                            </div>
                                        </div>
                                </ng-template>
                                <ng-template #NotInProccess></ng-template>

                                <ng-container *ngIf="(task.IsCompleted === true); then IsCompleted else NotIsCompleted"></ng-container>
                                <ng-template #IsCompleted>
                                        <div class="media align-items-center">
                                            <div class="media-left">
                                                <div class="text-center">
                                                  <i class="fas fa-clock" style="color: orange;"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                              <a [routerLink]="['/task',task.Id, task.ModuleId, task.CourseId]" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                            </div>
                                        </div>
                                </ng-template>
                                <ng-template #NotIsCompleted></ng-template>

                                <ng-container *ngIf="(task.IsHidden === true); then IsHidden else NotIsHidden"></ng-container>
                                <ng-template #IsHidden>
                                        <div class="media align-items-center">
                                            <div class="media-left">
                                                <div class="text-center">
                                                  <i class="fas fa-clock" style="color: orange;"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                              <a [routerLink]="['/task',task.Id, task.ModuleId, task.CourseId]" class="text-body"><strong>{{task.TaskTitle}}</strong></a>
                                            </div>
                                        </div>
                                </ng-template>
                                <ng-template #NotIsHidden></ng-template>  -->
                            </li>
                            <span>
                            </span>
                        </ul>
                    </div>

                </div>

                <!-- Task List End -->

            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="IsTaskModalShow === true; then ShowModal; else HideModal"></ng-container>
<ng-template #ShowModal>

    <div id="taskCompleteModel" class="modal d-flex bd-highlight bd-example-modal-lg taskCompleteModel">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header d-block p-0">
                    <button type="button" class="close float-right" (click)="clickModalClose()" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title text-center"></h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 text-center text-success">
                            <i class="fa fa-check-circle fa-4x"></i>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <h3 class="text-success">
                                Congratulations on completing chapter , please start new chapter
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>
<ng-template #HideModal>

</ng-template>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
    const iframe = document.querySelector('iframe');
    const player = new Vimeo.Player(iframe);

    player.on('play', function () {
        console.log('played the video!');
    });

    player.getVideoTitle().then(function (title) {
        console.log('title:', title);
    });
</script>
