export class User {
    id: number;
    firstname: string;
    lastname: string;
    fullname: string;
    token?: string;
    attachmentPath: string;

    loader: boolean = true;
}


