<ng-container
  *ngIf="IsLoaderShow === true; then ShowLoader; else HideLoader"
></ng-container>
<ng-template #ShowLoader>
  <div class="preloader">
    <div id="loader">
      <div class="book">
        <div class="book__page"></div>
        <div class="book__page"></div>
        <div class="book__page"></div>
      </div>
    </div>
  </div>
  <!-- <div class="preloader">
            <div id="loader"></div>
    </div> -->
</ng-template>
<ng-template #HideLoader> </ng-template>

<!--
<ng-container *ngIf="modal.IsModalShow === true; then ShowModal; else HideModal"></ng-container>
<ng-template #ShowModal>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 1050 !important;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{modal.Title}}</h5>
              <a id="modalClose" href="javascript: $('#exampleModal').modal('toggle');" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="modal.IsInnerHTML === true; then HTML; else TEXT"></ng-container>
                                            <ng-template #HTML>
                                                <div class="d-flex justify-content-center">
                                                    <span [innerHTML]="GetInnerHTML(modal.Body)"></span>
                                                </div>
                                            </ng-template>
                                            <ng-template #TEXT>
                                                <h3 class="card-title text-center divThougth">{{modal.Body}}</h3>
                                            </ng-template>
            </div>
          </div>
        </div>
      </div>

</ng-template>
<ng-template #HideModal>

</ng-template> -->

<ng-container
  *ngIf="modal.IsModalShow === true; then ShowModal; else HideModal"
></ng-container>
<ng-template #ShowModal>
  <div
    id="myModal"
    class="modal d-flex align-items-center bd-highlight bd-example-modal-lg"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="max-width: 60% !important">
        <div class="modal-header d-block">
          <button
            type="button"
            class="close float-right"
            (click)="clickModalClose()"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title text-center">{{ modal.Title }}</h4>
        </div>
        <div class="modal-body">
          <h3
            *ngIf="modal.IsPLANTEXT === true"
            class="card-title text-center divThougth"
          >
            {{ modal.PlainText }}
          </h3>
          <br />
          <div
            *ngIf="modal.IsInnerHTML === true"
            class="d-flex justify-content-center mt-3"
          >
            <span [innerHTML]="GetInnerHTML(modal.Body)"></span>
          </div>
        </div>
        <!-- <div class="media border-bottom pb-2">
                    <div class="media-left">
                        
                    </div>
                    <div class="media-body">
                    </div>
                    <div class="media-right">
                        <a href="javascript: void(0);" (click)="clickModalClose()" class="close text-right" style="cursor: pointer;">&times;</a>
                    </div>
                </div>
                <hr />
                <div class="card-body">
                    
    
                </div> -->
      </div>
    </div>
  </div>
</ng-template>
<ng-template #HideModal> </ng-template>

<!-- <ng-container *ngIf="modal.IsAlertModalShow === true; then AlertShowModal; else AlertHideModal"></ng-container>
<ng-template #AlertShowModal>

  <div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="media border-bottom pb-2">
          <div class="media-left">
              <h4 class="card-title divThougth">Alert</h4>
          </div>
          <div class="media-body">
          </div>
          <div class="media-right">
              <a href="javascript: void(0);" (click)="clickModalClose()" class="close text-right" style="cursor: pointer;">&times;</a>
          </div>
        </div>
        <hr />
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            {{modal.PlainText}}
          </div>
        </div>
      </div>
    </div>
  </div>
    
</ng-template>
<ng-template #AlertHideModal>

</ng-template> -->

<div class="mdk-header-layout js-mdk-header-layout">
  <!-- <div class="page__header">
        <div class="navbar navbar-dark bg-primary navbar-expand-sm d-none2 d-md-flex2">
            <div class="container-fluid">
               
                <button class="navbar-toggler d-block" data-toggle="sidebar" type="button">
                    <span class="material-icons">menu</span>
                </button>





            </div></div>
    </div> -->

  <!-- <app-header></app-header> -->

  <div id="header" data-fixed class="mdk-header js-mdk-header mb-0">
    <div class="mdk-header__content">
      <!-- Navbar -->
      <nav
        id="default-navbar"
        class="navbar navbar-expand navbar-light bg-light m-0"
      >
        <div class="container-fluid">
          <!-- Toggle sidebar -->
          <button
            class="navbar-toggler d-block sidemenubutton"
            data-toggle="sidebar"
            type="button"
          >
            <span class="material-icons">menu</span>
          </button>

          <!-- Brand -->
          <a routerLink="/home" class="navbar-brand">
            <img src="/assets/images/logo/fda.png" class="" alt="Paintastica" />
            <!-- <span class="d-none d-sm-block ml-15">Flagship Design Academy</span> -->
          </a>

          <div class="flex"></div>

          <!-- Menu -->
          <ul class="nav navbar-nav flex-nowrap" id="ulNotifications">
            <!-- Notifications dropdown -->
            <li
              class="nav-item dropdown dropdown-notifications dropdown-menu-sm-full"
            >
              <button
                class="nav-link btn-flush dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                data-dropdown-disable-document-scroll
                data-caret="false"
                (click)="ShowNotificationDone()"
              >
                <!-- <i class="fas fa-bell fa-2x"></i> -->
                <i class="material-icons" style="font-size: 35px"
                  >notifications</i
                >
                <span
                  *ngIf="IsNewNotification"
                  class="badge badge-notifications badge-warning small"
                  style="
                    padding: 0 0.3rem !important;
                    line-height: 0.8rem !important;
                    height: 15px;
                    color: #ffa726;
                  "
                >
                  .
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div data-perfect-scrollbar class="position-relative">
                  <div class="dropdown-header">
                    <strong>Notification</strong>
                  </div>
                  <div
                    class="list-group list-group-flush mb-0"
                    style="overflow-y: auto"
                  >
                    <ng-container
                      *ngIf="listNotification == null; then Have; else NotHave"
                    ></ng-container>
                    <ng-template #Have>
                      <a
                        href="javascript: void(0);"
                        class="list-group-item list-group-item-action unread"
                      >
                        <span class="d-flex">
                          <span class="flex d-flex flex-column">
                            <span class="text-black-70"
                              >No Notification found</span
                            >
                          </span>
                        </span>
                      </a>
                    </ng-template>
                    <ng-template #NotHave>
                      <a
                        [routerLink]="['/notification']"
                        class="list-group-item list-group-item-action unread"
                        *ngFor="let notification of listNotification"
                      >
                        <!-- <span class="d-flex align-items-center mb-1">
                                                    <small class="text-muted">{{notification.CreatedTime}}</small>
                                                </span> -->
                        <span class="d-flex">
                          <span class="flex d-flex flex-column">
                            <strong
                              *ngIf="notification.Type != 'TaskDiscussion'"
                              >{{
                                this.SplitNotificationTitle(
                                  notification.Type,
                                  notification.Title
                                )
                              }}
                            </strong>
                            <span style="display: none">{{
                              this.SplitNotificationTitle(
                                notification.Type,
                                notification.Title
                              )
                            }}</span>
                            <strong
                              *ngIf="notification.Type === 'TaskDiscussion'"
                            >
                              {{ notificationCourseName }}
                              <!-- Course Name -->
                            </strong>
                            <span
                              *ngIf="
                                notification.Type === 'TaskDiscussion' &&
                                (notificationModuleName != null ||
                                  notificationModuleName != '')
                              "
                            >
                              <b>MOD: </b> {{ notificationModuleName
                              }}<!-- Module Name -->
                            </span>
                            <span
                              *ngIf="
                                notification.Type == 'TaskDiscussion' &&
                                (notificationTaskName != null ||
                                  notificationTaskName != '')
                              "
                            >
                              <b> Task: </b> {{ notificationTaskName
                              }}<!-- Task sample -->
                            </span>

                            <span
                              class="text-black-70"
                              *ngIf="notification.Message != null"
                              >{{ notification.Message.slice(0, 30) }}</span
                            >
                          </span>
                        </span>
                      </a>

                      <a
                        [routerLink]="['/notification']"
                        class="list-group-item list-group-item-action text-center unread"
                        >See all notification</a
                      >
                    </ng-template>
                  </div>
                </div>
              </div>
            </li>
            <!-- // END Notifications dropdown -->

            <!-- User dropdown -->
            <li class="nav-item dropdown ml-1 ml-md-3" *ngIf="currentUser">
              <a
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
              >
                <img
                  [src]="currentUser.attachmentPath"
                  alt="Avatar"
                  class="rounded-circle"
                  width="35"
                  height="35"
                />
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a
                  class="dropdown-item"
                  [routerLink]="['/myprofile']"
                  routerLinkActive="active"
                >
                  <i class="material-icons">edit</i> View Profile
                </a>
                <a class="dropdown-item" href="#" (click)="Logout()">
                  <i class="material-icons">lock</i> Logout
                </a>
              </div>
            </li>
            <!-- // END User dropdown -->
          </ul>
        </div>
      </nav>
      <!-- // END Navbar -->
    </div>
  </div>

  <div class="mdk-header-layout__content">
    <!-- <div data-push data-responsive-width="992px" class="mdk-drawer-layout js-mdk-drawer-layout mx-5"> -->
    <div
      data-push
      data-responsive-width="992px"
      class="mdk-drawer-layout js-mdk-drawer-layout"
    >
      <div class="mdk-drawer-layout__content page">
        <!-- <h1>Modal: {{modal.IsModalShow}}</h1>
        <h1>Loader: {{IsLoaderShow}}</h1> -->
        <!-- Button trigger modal -->

        <!-- <button id="openModalButton" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
  </button> -->

        <button
          id="btnNotification"
          type="button"
          style="display: none"
          (click)="GetNotification()"
        >
          Notify
        </button>

        <router-outlet></router-outlet>
      </div>
      <!-- <app-sidebar></app-sidebar> -->

      <div class="mdk-drawer js-mdk-drawer" id="default-drawer">
        <div class="mdk-drawer__content">
          <div
            class="sidebar sidebar-left sidebar-light o-hidden ps ps--active-y"
            data-perfect-scrollbar
          >
            <div class="sidebar-p-y">
              <ul class="sidebar-menu sm-active-button-bg">
                <li class="sidebar-menu-item" routerLinkActive="active">
                  <a class="nav-link sidebar-menu-button" routerLink="/home">
                    <i
                      class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                      >dashboard</i
                    >
                    My Learning
                  </a>
                </li>
                <li class="sidebar-menu-item" routerLinkActive="active">
                  <a class="nav-link sidebar-menu-button" routerLink="/course">
                    <i
                      class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                      >import_contacts</i
                    >
                    Courses
                  </a>
                </li>

                <li class="sidebar-menu-item" routerLinkActive="active">
                  <a class="nav-link sidebar-menu-button" routerLink="/exam">
                    <i
                      class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                      >help</i
                    >
                    Exam
                  </a>
                </li>
                <li class="sidebar-menu-item" routerLinkActive="active">
                  <a
                    class="nav-link sidebar-menu-button"
                    routerLink="/discussion"
                  >
                    <i
                      class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                      >chat</i
                    >
                    Q & A
                  </a>
                </li>
                <li class="sidebar-menu-item" routerLinkActive="active">
                  <a
                    class="nav-link sidebar-menu-button"
                    routerLink="stationary"
                  >
                    <i
                      class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                      >live_help</i
                    >
                    Stationary
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <audio id="notificationtune" class="form-control" style="height:60px;border-radius: 10px;" src="/src/assets/notification.mp3" controls></audio> -->
<!-- <audio id="notificationtune" [src]="notificationTuneSound" preload="auto"> </audio> -->
<audio
  id="notificationtune"
  class="form-control"
  style="height: 60px; border-radius: 10px; display: none"
  [src]="this.notificationTuneSound"
  controls
  controlsList="nodownload"
></audio>
<style>
  .logo-width {
    width: 100px !important;
  }

  .ml-15 {
    margin-left: 15px !important;
  }

  .FixedHeader {
    padding-top: 64px;
    position: fixed;
    z-index: 1;
    width: 100% !important;
  }
</style>

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 1050 !important;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <img src="http://admin.flagshipdesignacademy.in//Uploads/ThreadAttachment/image (9).png" class="img-fluid mx-auto d-block" alt="Responsive image">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div> -->
