<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
    <img src="assets/images/imageLoading.gif" alt="Alt Image" style="position: fixed;top: 35%;left: 40%;transform: translate(-50%, -50%);width: 7%;"
    />
</div>

<button id="btnThreadNotify" type="button" style="display: none;" (click)="GetThreadNotify()">NotifyTaskComplete</button>

<button id="btnInsertThread" type="button" style="display: none;">NotifyThread</button>
<div class="mdk-drawer-layout__content page ">

    <div class="row m-0">
        <div class="col-lg container-fluid page__container">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="app-messages__container d-flex flex-column">
                                <div class="navbar navbar-light bg-white navbar-expand-sm navbar-shadow z-1">
                                    <div class="container flex-wrap px-sm-0">
                                        <div class="nav py-2">
                                            <div class="nav-item align-items-center row ">
                                                <div class="col-2">
                                                    <div class="avatar avatar-online avatar-sm">
                                                        <img src="{{threadModel.ThreadCreatedByImagePath}}" alt="people" class="avatar-img rounded-circle">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column col-10" style="font-size: 15px">
                                                    <h3 class="text-body mb-0">{{threadModel.ThreadName}}</h3>
                                                    <span class="text-muted text-ellipsis">{{threadModel.ThreadDetails}}</span>
                                                    <ng-container *ngIf="threadModel.AttachmentPathList != null; then threadAttchment; else NotthreadAttchment"></ng-container>
                                                                <ng-template #threadAttchment>
                                                                    <div class="mb-0" style="min-width: 100px;"  *ngFor="let attachment of threadModel.AttachmentPathList">
                                                                        <!-- <span *ngIf="fileExtension.IMAGE.indexOf(attachment.substring(attachment.lastIndexOf('.') + 1)) > -1"> -->
                                                                            <a href="javascript: void(0);" (click)="ShowModal(attachment)" class="btn btn-outline-primary btn-sm" 
                                                                            data-toggle="tooltip" data-placement="top" title="{{attachment.substring(attachment.lastIndexOf('/') + 1)}}"
                                                                            style="font-size: .6203125rem !important;">
                                                                                <i class="fas fa-file"></i> &nbsp; {{attachment.substring(0, attachment.lastIndexOf('.')).substring(attachment.lastIndexOf('/') + 1).slice(0, 30)}}....{{attachment.substring(attachment.lastIndexOf('.') + 1)}}
                                                                            </a><br />
                                                                        <!-- </span> -->
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template #NotthreadAttchment>
                                                                </ng-template>
                                                </div>

                                                <ng-container *ngIf="threadModel.IsPublic; then Delete; else NotDelete"></ng-container>
                                                                <ng-template #Delete>
                                                                    <div class="media-right pl-3">
                                                                        <div class="text-right" style="min-width: 80px;">
                                                                        <a href="javascript: void(0)" class="btn btn-outline-warning btn-sm" 
                                                                                    (click)="CloseDiscussion(threadModel.Id)" style="cursor: pointer;" *ngIf="threadModel.UserId == currentUser.id && threadModel.IsClosed == false;">Close</a>
                                                                                    <a href="javascript: void(0)" class="btn btn-outline-danger btn-sm" 
                                                                                    (click)="RemoveDiscussion(threadModel.Id);" style="cursor: pointer;" *ngIf="threadModel.IsGroupWise == true ;">Remove</a>
                                                                                </div>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template #NotDelete>
                                                                </ng-template>
                                                
                                            </div>
                                        </div>
                                        <div class="media-right mt-2 mt-xs-plus-0">
                                            <a class="btn btn-primary" [routerLink]="['/discussion']">Back</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex pt-4" style="position: relative;" data-perfect-scrollbar>
                                    <div class="container page__container pl-0 pr-0">
                                        <ul class="d-flex flex-column list-unstyled" [style.height.px]="discussionheight" id="messages" style="overflow-y:scroll;">
                                            <li class="message d-inline-flex" *ngFor="let discussion of threadModel.DiscussionMessageList;">
                                                <ng-container *ngIf="matchUserId(discussion.UserId,this.CurrentLoginUser.UserId); then CurrentUserMessage; else OtherUserMessage"></ng-container>
                                                
                                                <ng-template #CurrentUserMessage>
                                                    <div class="order-right">
                                                        <a href="javascript:void(0)" class="avatar">
                                                            <img src="{{discussion.ThreadCreatedByImagePath}}" alt="people" class="avatar-img rounded-circle">
                                                        </a>
                                                    </div>
                                                    <div class="card margin-left-auto" style="border-radius: 15px;">
                                                        <div class="card-body bg-f2fff3">
                                                            <div class="media align-items-start">
                                                                <div class="media-body">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="flex mr-3">
                                                                            <a href="javascript:void(0)" class="text-body">
                                                                                <strong>{{discussion.ThreadCreatedBy}}</strong>
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                                <small class="text-muted">{{ReturnDateTime(discussion.CreatedDate)}}</small>
                                                                            </div>
                                                                    </div>
                                                                    <span class="text-black-70">{{discussion.Message}} </span>
                                                                    <ng-container *ngIf="discussion.AttachmentPathList != null; then Available; else NotAvailable"></ng-container>
                                                                        <ng-template #Available>
                                                                            <div class="text-right mb-0" style="min-width: 100px;"  *ngFor="let attachment of discussion.AttachmentPathList">
                                                                                <!-- <span *ngIf="fileExtension.IMAGE.indexOf(attachment.substring(attachment.lastIndexOf('.') + 1)) > -1"> -->
                                                                                    <a href="javascript: void(0);" (click)="ShowModal(attachment)" class="btn btn-outline-primary btn-sm" 
                                                                                    data-toggle="tooltip" data-placement="top" title="{{attachment.substring(attachment.lastIndexOf('/') + 1)}}"
                                                                                    style="font-size: .6203125rem !important;">
                                                                                        <i class="fas fa-file"></i> &nbsp; {{attachment.substring(0, attachment.lastIndexOf('.')).substring(attachment.lastIndexOf('/') + 1).slice(0, 30)}}....{{attachment.substring(attachment.lastIndexOf('.') + 1)}}
                                                                                    </a><br />
                                                                                <!-- </span> -->
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template #NotAvailable>
                                                                        </ng-template>
                                                                    
                                                                </div>

                                                                <ng-container *ngIf="discussion.Id == lastDiscussionMessageId; then LastDiscussionMessage; else NotLastDiscussionMessage"></ng-container>
                                                                        <ng-template #LastDiscussionMessage>
                                                                            <div class="media-right border-left border-dark pl-2">
                                                                                <a href="javascript:void(0)" (click)="RemoveFromDiscussionMessage(discussion.Id);">
                                                                                    <i class="fas fa-trash" style="color:red"></i>
                                                                                </a>
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template #NotLastDiscussionMessage>
                                                                        </ng-template>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </ng-template>

                                                <ng-template #OtherUserMessage>
                                                    <div class="order-left">
                                                        <a href="javascript:void(0)" class="avatar">
                                                            <img src="{{discussion.ThreadCreatedByImagePath}}" alt="people" class="avatar-img rounded-circle">
                                                        </a>
                                                    </div>
                                                    <div class="margin-left-0 card" style="border-radius: 15px;">
                                                        <div class="card-body bg-f1fbff">
                                                            <div class="d-flex align-items-center">
                                                                <div class="flex mr-3">
                                                                    <a href="javascript:void(0)" class="text-body">
                                                                        <strong>{{discussion.ThreadCreatedBy}}</strong>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                        <small class="text-muted">{{ReturnDateTime(discussion.CreatedDate)}}</small>
                                                                    </div>
                                                            </div>
                                                            <span class="text-black-70">{{discussion.Message}} </span>
                                                            <ng-container *ngIf="discussion.AttachmentPathList != null; then OtherAvailable; else NotOtherAvailable"></ng-container>
                                                                <ng-template #OtherAvailable>
                                                                    <div class="text-right mb-0" style="min-width: 100px;"  *ngFor="let attachment of discussion.AttachmentPathList">
                                                                        <!-- <span *ngIf="fileExtension.IMAGE.indexOf(attachment.substring(attachment.lastIndexOf('.') + 1)) > -1"> -->
                                                                            <a href="javascript: void(0);" (click)="ShowModal(attachment)" class="btn btn-outline-primary btn-sm" 
                                                                            data-toggle="tooltip" data-placement="top" title="{{attachment.substring(attachment.lastIndexOf('/') + 1)}}"
                                                                            style="font-size: .6203125rem !important;">
                                                                                <i class="fas fa-file"></i> &nbsp; {{attachment.substring(0, attachment.lastIndexOf('.')).substring(attachment.lastIndexOf('/') + 1).slice(0, 30)}}....{{attachment.substring(attachment.lastIndexOf('.') + 1)}}
                                                                            </a><br />
                                                                        <!-- </span> -->
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template #NotOtherAvailable>
                                                                </ng-template>
                                                        </div>
                                                    </div>
                                                </ng-template>


                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div>
                                    <hr />
                                    <form [formGroup]="discussionForm" (ngSubmit)="onSubmit()" *ngIf="threadModel.IsClosed === false">
                                        <div class="list-group list-group-fit">
                                            <div class="list-group-item pl-0 pr-0">
                                                <div role="group" aria-labelledby="label-question" class="m-0 form-group">
                                                    <div class="form-row m-0">
                                                        <div class="col-md-11">
                                                            <textarea name="comment" id="comment" formControlName="comment" placeholder="Type Message...." rows="2" class="form-control" required></textarea>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <span class="fileinput-button">
                                                                <i class="fa fa-paperclip"></i>
                                                                <input type="file" multiple name="attachments" formControlName="attachments" (change)="onFileChange($event)" accept="image/jpeg, image/png, image/gif,application/pdf">
                                                                <br />
                                                            </span>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <br>
                                                            <ul class="TaskFilesList">
                                                                <ng-container *ngIf="fileUploads != null; then fileAvailable; else fileNotAvailable"></ng-container>
                                                                <ng-template #fileAvailable>
                                                                    <li *ngFor="let file of fileUploads">
                                                                        <strong>{{file.fileName}}</strong> - {{file.fileSize}} bytes.
                                                                        &nbsp; &nbsp;
                                                                        <a href="javascript: void(0);" (click)="removeFile(file.fileId)" class="removeFile">Remove</a>
                                                                    </li>
                                                                </ng-template>
                                                                <ng-template #fileNotAvailable>
                                                                </ng-template>

                                                            </ul>
                                                        </div>
                                                        <div class="">
                                                            <button type="submit" class="btn btn-primary">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>