export class FileToUpload {
    fileId: string;
    fileName: string = "";
    fileSize: number = 0;
    fileType: string = "";
    lastModifiedTime: number = 0;
    lastModifiedDate: Date = null;
    fileAsBase64: string = "";
    file: File;
    FileExtension:string = "";
    FileByte?:any;
}
