<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>



<div class="mdk-drawer-layout__content page ">

    <div class="row m-0">
        <div class="container-fluid page__container">
    
    <h1 class="h2">Payment</h1>
    <div class="row">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">Total <span class="text-primary">$19,239</span></h5>
            </div>
            <div data-toggle="lists" class="table-responsive">
                <table class="table table-nowrap m-0">
                    <thead class="thead-light">
                        <tr class="text-uppercase small">
                            <th>
                                <a href="javascript:void(0)" class="sort" data-sort="js-lists-values-course">Course</a>
                            </th>
                            <th class="text-center" style="width:130px">
                                <a href="javascript:void(0)" class="sort" data-sort="js-lists-values-fees">Fees</a>
                            </th>
                            <th class="text-center" style="width:130px">
                                <a href="javascript:void(0)" class="sort" data-sort="js-lists-values-revenue">Revenue</a>
                            </th>
                        </tr>
                    </thead>

                    <tbody class="list">

                        <tr>
                            <td>
                                <div class="media align-items-center">
                                    <a href="fixed-instructor-course-edit.html" class="avatar avatar-4by3 avatar-sm mr-3">
                                        <img src="assets/images/vuejs.png" alt="course" class="avatar-img rounded">
                                    </a>
                                    <div class="media-body">
                                        <a class="text-body js-lists-values-course" href="fixed-instructor-course-edit.html"><strong>Angular Routing In-Depth</strong></a>
                                        <div class="text-muted small">34 Sales</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center text-black-70">

                                <span class="js-lists-values-fees">120</span> USD

                            </td>
                            <td class="text-center text-black-70">

                                <span class="js-lists-values-revenue">8,737</span> USD
                                
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="media align-items-center">
                                    <a href="fixed-instructor-course-edit.html" class="avatar avatar-4by3 avatar-sm mr-3">
                                        <img src="assets/images/vuejs.png" alt="course" class="avatar-img rounded">
                                    </a>
                                    <div class="media-body">
                                        <a class="text-body js-lists-values-course" href="fixed-instructor-course-edit.html"><strong>Angular Unit Testing</strong></a>
                                        <div class="text-muted small">38 Sales</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center text-black-70">

                                <span class="js-lists-values-fees sr-only">0</span>
                                <i class="material-icons text-muted-light">remove</i>

                            </td>
                            <td class="text-center text-black-70">
                                <span class="js-lists-values-revenue">2,521</span> USD
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="media align-items-center">
                                    <a href="fixed-instructor-course-edit.html" class="avatar avatar-4by3 avatar-sm mr-3">
                                        <img src="assets/images/github.png" alt="course" class="avatar-img rounded">
                                    </a>
                                    <div class="media-body">
                                        <a class="text-body js-lists-values-course" href="fixed-instructor-course-edit.html"><strong>Introduction to TypeScript</strong></a>
                                        <div class="text-muted small">8 Sales</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center text-black-70">

                                <span class="js-lists-values-fees sr-only">0</span>
                                <i class="material-icons text-muted-light">remove</i>

                            </td>
                            <td class="text-center text-black-70">
                                <span class="js-lists-values-revenue">1,413</span> USD
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="media align-items-center">
                                    <a href="fixed-instructor-course-edit.html" class="avatar avatar-4by3 avatar-sm mr-3">
                                        <img src="assets/images/gulp.png" alt="course" class="avatar-img rounded">
                                    </a>
                                    <div class="media-body">
                                        <a class="text-body js-lists-values-course" href="fixed-instructor-course-edit.html"><strong>Learn Angular Fundamentals</strong></a>
                                        <div class="text-muted small">31 Sales</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center text-black-70">

                                <span class="js-lists-values-fees sr-only">0</span>
                                <i class="material-icons text-muted-light">remove</i>

                            </td>
                            <td class="text-center text-black-70">
                                <span class="js-lists-values-revenue">1,234</span> USD
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        
</div>

</div> 
</div>
</div>
