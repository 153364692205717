import { Routes } from '@angular/router';
import { CourseComponent } from './components/course/course.component';
import { CourseDetailsComponent } from './components/course/details/details.component';
import { PaymentComponent } from './components/course/payment/payment.component';
import { AskQuestionComponent } from './components/discussion/ask-question/ask-question.component';
import { DiscussionComponent } from './components/discussion/discussion.component';
import { ExamComponent } from './components/exam/exam.component';
import { TakeExamComponent } from './components/exam/take-exam/take-exam.component';
import { HomeComponent } from './components/home/home.component';
import { PaymenthistoryComponent } from './components/paymenthistory/paymenthistory.component';
import { PlacementComponent } from './components/placement/placement.component';
import { ExamResultComponent } from './components/result/exam-result/exam-result.component';
import { ResultComponent } from './components/result/result.component';
import { MasterComponent } from './components/shared/master/master.component';
import { StationaryComponent } from './components/stationary/stationary.component';
import { StudentComponent } from './components/student/student.component';
import { TaskComponent } from './components/task/task.component';
import { ThreadComponent } from './components/thread/thread.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { NotificationComponent } from './components/notification/notification.component';

export const RoutesLayout: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'course', component: CourseComponent },
  { path: 'course/details/:CourseId', component: CourseDetailsComponent },
  { path: 'course/payment/:CourseId', component: PaymentComponent },
  { path: 'myprofile', component: StudentComponent },
  { path: 'exam', component: ExamComponent },
  { path: 'exam/take-exam/:ExamId/:ModuleId/:CourseId', component: TakeExamComponent },
  { path: 'result', component: ResultComponent },
  { path: 'result/exam-result/:ExamId/:ModuleId/:CourseId', component: ExamResultComponent },
  { path: 'discussion', component: DiscussionComponent },
  { path: 'askquestion', component: AskQuestionComponent },
  { path: 'thread/:DiscussionId', component: ThreadComponent },
  { path: 'placement', component: PlacementComponent },
  { path: 'stationary', component: StationaryComponent },
  { path: 'task/:TaskId/:ModuleId/:CourseId', component: TaskComponent },
  { path: 'payment', component: PaymenthistoryComponent },
  { path: 'certificate', component: CertificateComponent },
  { path: 'notification', component: NotificationComponent },
  
    // children: [

    //   {
    //     path: '',
    //     component: CourseComponent
    // },

    //   {
    //     path: 'details', 
    //     component: DetailsComponent,
    //   }
    // ],
  
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  
];
