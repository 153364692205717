import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) {

    this.activatedRoute.queryParams.subscribe(params => {
      
      let ExamId = params['ExamId'];
      });  }
      
  ngOnInit(): void {
  }

}
