import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { Modal } from 'src/app/models/modal';
import { AuthService } from 'src/app/services/auth.service';
import{DataService} from "../../services/data.service";

@Component({
  selector: 'app-stationary',
  templateUrl: './stationary.component.html',
  styleUrls: ['./stationary.component.css']
})
export class StationaryComponent implements OnInit {

  response : ApiResponse = null;
  stationaryList = [];
  loaderPlay = false;

  
  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private context:DataService, private authService: AuthService) { }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);
    
    //this.loaderPlay = true;
    
    this.authService.IsShowLoader(true);

    this.context.GET("GetAllStationary").subscribe(data => {
      this.response=data;

      this.authService.IsShowLoader(false);

      if(this.response.status_code == 1){
        this.stationaryList = this.response.data1;
      }
      else{
        this.stationaryList = null;
      }
    });
  }

}
