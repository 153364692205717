<!-- <div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div> -->






<div class="container page__container">
    <h3 class="h3 pt-3">Edit Account</h3>

    <div class="card">
        <ul class="nav nav-tabs nav-tabs-card">
            <li class="nav-item">
                <a class="nav-link active" href="#first" data-toggle="tab">Account</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#second" data-toggle="tab">My Course</a>
            </li>
        </ul>
        <div class="tab-content card-body">
            <div class="tab-pane active" id="first">
                <form name="formProfile" class="form-horizontal">
                    <div class="form-group row">
                        <label for="avatar" class="col-sm-3 col-form-label form-label">My Profile Image</label>
                        <div class="col-sm-9">
                            <div class="media align-items-center">
                                <div class="media-left">
                                    <div class="icon-block rounded">
                                        <img src='{{imageUrl}}' alt="avatar" class="avatar-img border-3">
                                    </div>
                                </div>
                                <div class="media-body">
                                    <span class="btn fileinput-button">
                                        <i class="fa fa-paperclip" aria-hidden="true"></i> Select Attachment
                                        <input type="file" name="attachment" id="attachment" (change)="onFileChange($event)" accept="image/jpeg, image/png, image/gif">
                                        <br />
                                    </span>
                                    <!-- <div class="col-md-12">
                                        <ul class="FilesList">
                                            <ng-container *ngIf="fileUpload != null; then Available; else NotAvailable">
                                            </ng-container>
                                            <ng-template #Available>
                                                <li>
                                                    <strong>{{fileUpload.fileName}}</strong>
                                                    - {{fileUpload.fileSize}} bytes. &nbsp; &nbsp;
                                                    <a href="javascript: void(0);" (click)="removeFile()" class="removeFile">Remove</a>
                                                </li>
                                            </ng-template>
                                            <ng-template #NotAvailable>
                                            </ng-template>

                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="studentId" class="col-sm-3 col-form-label form-label">Student Id</label>
                        <div class="col-sm-6 col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <i class="material-icons md-18 text-muted">live_help</i>
                                    </div>
                                </div>
                                <input type="text" id="studentId" class="form-control" name="studentId" [(ngModel)]="studentModel.StudentId" [value]="studentModel.StudentId" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-sm-3 col-form-label form-label">Full Name</label>
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <input id="name" type="text" class="form-control" placeholder="First Name" name="Firstname" [(ngModel)]="studentModel.Firstname" [value]="studentModel.Firstname">
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" placeholder="Last Name" name="Lastname" [(ngModel)]="studentModel.Lastname" [value]="studentModel.Lastname">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="col-sm-3 col-form-label form-label">Email</label>
                        <div class="col-sm-6 col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <i class="material-icons md-18 text-muted">mail</i>
                                    </div>
                                </div>
                                <input type="text" id="email" class="form-control" placeholder="Email Address" name="Email" [(ngModel)]="studentModel.Email" [value]="studentModel.Email" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="col-sm-3 col-form-label form-label">Contact No.</label>
                        <div class="col-sm-6 col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <input type="text" id="phoneno" class="form-control" placeholder="Contact no" name="MobileNo" [(ngModel)]="studentModel.MobileNo" [value]="studentModel.MobileNo">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email" class="col-sm-3 col-form-label form-label">Address</label>
                        <div class="col-sm-6 col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <textarea id="phoneno" class="form-control" placeholder="Address" name="Address" [(ngModel)]="studentModel.Address" [value]="studentModel.Address">

                                            </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="password" class="col-sm-3 col-form-label form-label">Change Password</label>
                        <div class="col-sm-6 col-md-4">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <i class="material-icons md-18 text-muted">lock</i>
                                    </div>
                                </div>
                                <input type="password" id="password" class="form-control" placeholder="Enter new password" name="Password" [(ngModel)]="studentModel.Password" [value]="studentModel.Password">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 offset-sm-3">
                            <div class="media align-items-center">
                                <div class="media-left">
                                    <button type="submit" class="btn btn-primary mb-3" (click)="onSubmit()">Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="tab-pane" id="second">
                <div class="card" *ngFor="let course of courseList;let i = index">
                    <div *ngIf="course.IsApproved" class="card-header">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="['/course/details/',course.Id]" class="mr-3">
                                <img src="{{course.CourseImagePath}}" width="100" alt="" class="rounded">
                            </a>
                            <div class="flex">
                                <h4 class="card-title mb-0">
                                    <a [routerLink]="['/course/details/',course.Id]">{{course.CourseName}}</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <ul *ngIf="course.IsApproved" class="list-group list-group-fit">
                        <li class="list-group-item" *ngFor="let module of course.CompleteModule;let i = index">
                            <a [routerLink]="['/course/details/',course.Id]" *ngIf="module.IsCompleted" class="text-body text-decoration-0 d-flex align-items-center">
                                <strong>{{module.ModuleName}}</strong>
                                <i class="material-icons text-muted ml-auto" style="font-size: inherit;">check</i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <div class="mdk-drawer-layout__content page p-0" style="padding-top: 0rem !important;">
    <div class="bg-primary mdk-box js-mdk-box mb-0" style="height: 192px;" data-effects="parallax-background blend-background">
        <div class="mdk-box__bg">
            <div class="mdk-box__bg-front" style="background-image: url('assets/images/1280_rsz_aman-dhakal-205796-unsplash.jpg'); background-position: center;"></div>
        </div>
    </div>
    <div class="container-fluid page__container d-flex align-items-end position-relative mb-4">
        <div class="avatar avatar-xxl position-absolute bottom-0 left-0 right-0">
            <img src='{{studentModel.AttachmentPath}}' alt="avatar" class="avatar-img rounded-circle border-3">
        </div>
        <ul class="nav nav-tabs-links flex" style="margin-left: 265px;">
            <li class="nav-item">
                <a href="#" class="nav-link active">My Courses</a>
            </li>
        </ul>
    </div>

    <div class="container-fluid page__container mb-3">
        <div class="row flex-sm-nowrap">
            <div class="col-sm-auto mb-3 mb-sm-0" style="width: 265px;">
                <h1 class="h2 mb-1">{{studentModel.Firstname}} {{studentModel.Lastname}} </h1>
                <div class="text-muted d-flex align-items-center mb-2">
                    <i class="material-icons mr-1">location_on</i>
                    <div class="flex">{{studentModel.Address.slice(0,10)}}</div>
                </div>
                <div class="text-muted d-flex align-items-center mb-2">
                    <i class="material-icons mr-1">phone</i>
                    <div class="flex">{{studentModel.MobileNo}}</div>
                </div>

                <h4>About me</h4>
                <p class="text-black-70 measure-paragraph">{{studentModel.Details}}</p>

            </div>
            <div class="col-sm">
                
                <div class="card" *ngFor="let course of courseList;let i = index">
                    <div class="card-header">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="['/course/details/',course.Id]" class="mr-3">
                                <img src="{{course.CourseImagePath}}" width="100" alt="" class="rounded">
                            </a>
                            <div class="flex">
                                <h4 class="card-title mb-0"><a [routerLink]="['/course/details/',course.Id]">{{course.CourseName.slice(0, 15)}}</a></h4>
                            </div>
                        </div>
                    </div>
                    <ul class="list-group list-group-fit">
                        <li class="list-group-item" *ngFor="let module of course.listModules;let i = index">
                            <a [routerLink]="['/course/details/',course.Id]" class="text-body text-decoration-0 d-flex align-items-center">
                                <strong>{{module.ModuleName}}</strong>
                                <i class="material-icons text-muted ml-auto" style="font-size: inherit;">check</i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


</div> -->