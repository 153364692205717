import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiResponse } from 'src/app/models/api-response';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FileToUpload } from 'src/app/models/file-to-upload';
import { Student } from 'src/app/models/student';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';
import{ MasterComponent} from 'src/app/components/shared/master/master.component';

// Maximum file size allowed to be uploaded = 1MB
const MAX_SIZE: number = 2097151;

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})


export class StudentComponent implements OnInit {

  
  dvTaskContentHeight;
  TaskListHeight;
  response : ApiResponse = null;
  courseList = [];
  currentUser = new User();
  //studentModel = new Student();
  
  studentModel = new Student();
  CurrentUser:any;
  FullMonth;
  FullYear;
  
  fileToUpload: File;

  fileUploads: FileToUpload[] = [];
  public fileUpload = new FileToUpload();
  loaderPlay = false;

  imageUrl: string | ArrayBuffer;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;
  masterComponent = MasterComponent;
  
  constructor(private context: DataService, private spinner: NgxSpinnerService, private authService: AuthService, private router: Router) {
    //this.studentModel = JSON.parse(localStorage.getItem("StudentModel"));
    // this.FullMonth = new Date(this.studentModel.CreatedDate).getMonth() + 1;
    // this.FullYear = new Date(this.studentModel.CreatedDate).getFullYear();
   }

  ngOnInit(): void {

    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    this.authService.ScrollOverflow();
    //this.loaderPlay = true;

    this.authService.IsShowLoader(true);
      
    (function ($) {
      $(document).ready(function(){
        window.scrollTo(0, 0);
        $('#mainBody').css('overflow','hidden');
      });
    })(jQuery);


    this.dvTaskContentHeight = window.innerHeight - 150;
    this.TaskListHeight = window.innerHeight - 130;

    this.imageUrl = this.authService.FillEmptyImage(true);

    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }
    this.fileUpload = null;
    this.GetAllStudentCourse();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionModal.unsubscribe();
  }

  GetAllStudentCourse(){
    //this.loaderPlay = true;
    $('#mainBody').css('overflow','scroll');
    this.authService.IsShowLoader(true);
      
    this.context.GET("GetAllStudentCourse?StudentUserId="+this.currentUser.id).subscribe(data => {      
      this.response=data;
      $('#mainBody').css('overflow','scroll');
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
          this.courseList = this.response.data1;
          this.studentModel = this.response.data2;

          this.imageUrl = this.studentModel.AttachmentPath;
      }
    });
    //this.loaderPlay = false;
  }

  
  onFileChange(event) {
    //this.loaderPlay = true;
    
    this.authService.IsShowLoader(true);
      
      if (event.target.files && event.target.files.length > 0) {
            // Don't allow file sizes over 1MB
            if (event.target.files[0].size < MAX_SIZE) {
              // Set theFile property
  
              this.fileUpload = new FileToUpload();
              this.fileUpload.fileId = "0";
              this.fileUpload.fileSize = event.target.files[0].size;
              this.fileUpload.fileName = event.target.files[0].name;
              this.fileUpload.file = event.target.files[0];

              const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);

                reader.onload = event => {
                  this.imageUrl = reader.result;
                };

              this.studentModel.AttachmentPath = event.target.files[0];
            }
            else {
                alert("File: " + event.target.files[0].name + " is too large to upload.(2MB)");
                return false;
            }
      }
      //this.loaderPlay = false;
      
    this.authService.IsShowLoader(false);
      
  }
  
removeFile(){
  this.fileUpload = null;
  }

  
  onSubmit() {
    this.authService.IsShowLoader(true);

    const formData =  new  FormData();
    if(this.fileUpload != null){
      formData.append("file0",  this.fileUpload.file);
    }
    

    this.studentModel.UserId = this.currentUser.id;

    this.studentModel.AttachmentPath = null;

    formData.append('objViewSTR', JSON.stringify(this.studentModel));
    
    this.context.POSTWITHATTACHMENTS("UpdateProfile", formData).subscribe(data => {        
      this.response=data;
      this.authService.IsShowLoader(false);
      if(this.response.status_code == 1){
        var userImg = localStorage.getItem('currentUser');
        window.location.reload();
       // this.authService.Logout();
       // this.router.navigate(['/login'], { queryParams: { returnUrl: "myprofile" } });
          // this.studentModel = this.response.data1;
          // this.studentModel.UserId = this.currentUser.id;

          // this.fileUpload = null;
         
      }

      this.authService.IsShowLoader(false);
        
    });
  }
}
