<div *ngIf="loaderPlay" style="position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;
height: 500vh;
background-color: rgba(51,51,51,0.8);">
  <img src="assets/images/imageLoading.gif" alt="Alt Image"
    style="position: fixed;top: 50%;left: 40%;transform: translate(-50%, -50%);width: 7%;" />
</div>


<div class="mdk-drawer-layout__content page ">

    <div class="container page__container">
    
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex align-items-center mb-4">
                <h1 class="h2 flex mr-3 mb-0">Discussion</h1>
                <a [routerLink]="['/askquestion']" class="btn btn-primary">Ask a Question</a>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <h4 class="card-title">General</h4>
                        </div>
                    </div>
                </div>

                <ul class="list-group list-group-fit">
                    <li class="list-group-item forum-thread" *ngFor="let discussion of discussionPublicList;let i = index">
                        <a style="cursor: pointer;" [routerLink]="['/thread/',discussion.Id]">
                            <div class="media align-items-center">
                                    <div class="media-left">
                                        <div class="forum-icon-wrapper">
                                            <a href="javascript: void(0);" class="forum-thread-icon">
                                                <img  [src]="ShowUserAttchment(discussion.User)" alt="" class="rounded-circle imageSize">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <div class="d-flex align-items-center">
                                            <a class="text-black-70 h4 mb-0" style="cursor: pointer;" [routerLink]="['/thread/',discussion.Id]"><strong>{{discussion.ThreadName}}</strong></a>
                                            
                                            <!-- <small class="ml-auto text-muted">5 min ago</small> -->
                                        </div>
                                        <span style="font-size: 12px;" class="text-body">{{SetUserName(discussion.User)}}</span>
                                        
                                    </div>
                                </div>
                        </a>
                       
                    </li>
                </ul>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <h4 class="card-title">Teacher</h4>
                        </div>
                        <!-- <div class="media-right">
                            <a href="#" class="btn btn-white btn-sm"><i class="material-icons">add</i></a>
                        </div> -->
                    </div>
                </div>

                <ul class="list-group list-group-fit">
                    <li class="list-group-item forum-thread" *ngFor="let discussion of discussionPrivateList;let i = index">
                        <div class="media align-items-center">
                            <div class="media-left">
                                <div class="forum-icon-wrapper">
                                    <a href="javascript: void(0);" class="forum-thread-icon">
                                        <img [src]="ShowUserAttchment(discussion.User)" alt="" width="20" class="rounded-circle imageSize">
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="media-body">
                                <div class="d-flex align-items-center">
                                    <a href="javascript: void(0);" class="text-body"><strong>{{discussion.User.Fullname}}</strong></a>
                                    <small class="ml-auto text-muted">5 min ago</small>
                                </div>
                                <a class="text-black-70" [routerLink]="['/thread/',discussion.Id]">{{discussion.ThreadName}}</a>
                            </div> -->
                            <div class="media-body">
                                <div class="d-flex align-items-center">
                                    <a class="text-black-70 h4 mb-0" style="cursor: pointer;" [routerLink]="['/thread/',discussion.Id]"><strong>{{discussion.ThreadName}}</strong></a>
                                    
                                    <!-- <small class="ml-auto text-muted">5 min ago</small> -->
                                </div>
                                <a href="javascript: void(0);" class="text-body">{{SetUserName(discussion.User)}}</a>
                                
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <h4 class="card-title">Group Wise</h4>
                        </div>
                        <!-- <div class="media-right">
                            <a href="#" class="btn btn-white btn-sm"><i class="material-icons">add</i></a>
                        </div> -->
                    </div>
                </div>

                <ul class="list-group list-group-fit">
                    <li class="list-group-item forum-thread" *ngFor="let discussion of discussionGroupList;let i = index">
                        <div class="media align-items-center">
                            <div class="media-left">
                                <div class="forum-icon-wrapper">
                                    <a href="javascript: void(0);" class="forum-thread-icon">
                                        <img src="{{discussion.User.AttachmentPath}}" alt="" width="20" class="rounded-circle imageSize">
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="media-body">
                                <div class="d-flex align-items-center">
                                    <a href="javascript: void(0);" class="text-body"><strong>{{discussion.User.Fullname}}</strong></a>
                                    <small class="ml-auto text-muted">5 min ago</small>
                                </div>
                                <a class="text-black-70" [routerLink]="['/thread/',discussion.Id]">{{discussion.ThreadName}}</a>
                            </div> -->
                            <div class="media-body">
                                <div class="d-flex align-items-center">
                                    <a class="text-black-70 h4 mb-0" style="cursor: pointer;" [routerLink]="['/thread/',discussion.Id]"><strong>{{discussion.ThreadName}}</strong></a>
                                    
                                    <!-- <small class="ml-auto text-muted">5 min ago</small> -->
                                </div>
                                <a href="javascript: void(0);" class="text-body">{{discussion.User.Fullname}}</a>
                                
                            </div>
                            <div class="media-right pr-3">
                                <div class="text-right" style="min-width: 80px;">
                                    <a href="javascript: void(0)" class="btn btn-outline-warning btn-sm" 
                                    (click)="CloseDiscussion(discussion.Id)" style="cursor: pointer;" *ngIf="discussion.UserId == currentUser.id && discussion.IsClosed == false;">Close</a>
                                    <a href="javascript: void(0)" class="btn btn-outline-danger btn-sm" 
                                    (click)="RemoveDiscussion(discussion.Id)" style="cursor: pointer;">Remove</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>