import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkWithHref, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api-response';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Modal } from 'src/app/models/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  response : ApiResponse = null;

  //StudentUserId: number;
  CourseId: number;
  currentUser: User = null;
  loaderPlay = false;

  IsLoaderShow:boolean;
  modal = new Modal();
  subscriptionModal: Subscription;
  subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,private context: DataService, private authService: AuthService) {
    this.CourseId = this.activatedRoute.snapshot.params.CourseId;
    //this.StudentUserId = this.activatedRoute.snapshot.params.StudentUserId;
  }

  ngOnInit(): void {
    this.subscription = this.authService.currentMessage.subscribe(IsLoaderShow => this.IsLoaderShow = IsLoaderShow);
    this.subscriptionModal = this.authService.modalViewObj.subscribe(modal => this.modal = modal);

    //this.loaderPlay = true;
    //this.authService.IsShowLoader(true);
    
    this.authService.ScrollOverflow();
    this.currentUser = this.authService.CurrentUser();
    if (this.currentUser == null) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: "home" } });
    }
  }

  StudentBuyCourse(){
    
    //this.loaderPlay = true;
    
    this.authService.IsShowLoader(true);

    this.context.StudentBuyCourse(this.CourseId, this.currentUser.id).subscribe(data => {
      this.response=data;
      if(this.response.status_code == 1){
        
        window.location.href = "/course";
      }
      //this.loaderPlay = false;
      
      this.authService.IsShowLoader(false);
      
      alert(this.response.message);
    });
}
}
