import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private connection: any;
  private proxy: any;
  private ulr: any;
  private hubConnection: signalR.HubConnection
  
  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl('http://localhost:43210/signalr/flagshipnotificationhub')
                            .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }
  public addTransferChartDataListener = () => {
    this.hubConnection.on('receive', (data) => {
    });
  }
}
